import React from 'react';

import { connect } from 'react-redux';
import { withErrorMonitor } from '@wix/yoshi-flow-editor';
import type { ErrorMonitor } from '@wix/error-monitor';

import { ensureVideoLoaded } from '../../../../../redux/actions/video/ensure-video-loaded';
import { selectVideo } from '../../../../../redux/actions/select-video';
import {
  playVideo,
  pauseVideo,
} from '../../../../../redux/actions/player/change-playback-status';
import { getCurrentSiteUser } from '../../../../../selectors/current-site-user';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { getVideosGroupedByIds } from '../../../../../selectors/videos';
import { canPlayFullOrPreview } from '@wix/wix-vod-shared/widget';
import { getVideoCardsList } from '../../../../../selectors/video-cards';
import VideoCardsWrapper from '../../../../../components/video-cards-wrapper/video-cards-wrapper';
import { RootState } from '../../../../../redux/root-state';
import { ChannelData } from '@wix/wix-vod-constants/api-types';
import { CardData } from '@wix/wix-vod-shared/components';
import { VideosState } from '../../../../../redux/reducers/videos';

const mapStateToProps = (state: RootState) => ({
  cardsData: getVideoCardsList(state),
  currentSiteUser: getCurrentSiteUser(state),
  videoByIds: getVideosGroupedByIds(state),
  channelData: getChannelForWidget(state),
});

const mapDispatchToProps = {
  selectVideo,
  playVideo,
  pauseVideo,
  ensureVideoLoaded,
};

interface Props {
  currentSiteUser: object;
  channelData: ChannelData;
  videoByIds: VideosState;
  cardsData: CardData[];

  children: (props: {
    cardsData: CardData[];
    onCardClick: (card?: CardData) => void;
  }) => React.ReactNode;

  selectVideo: (id: string) => void;
  playVideo: (id: string) => void;
  pauseVideo: (id: string) => void;
  ensureVideoLoaded: (id: string) => Promise<void>;

  thumbnailSize: number;

  errorMonitor: ErrorMonitor;
}

class VideoCardsWrapperDesktop extends React.Component<Props> {
  handlePlayRequest = ({ id }: { id: string }) => {
    const {
      channelData,
      currentSiteUser,
      selectVideo,
      playVideo,
      ensureVideoLoaded,
    } = this.props;

    ensureVideoLoaded(id).then(() => {
      const videoItem = this.props.videoByIds[id];
      if (videoItem) {
        const isPlayAllowed = canPlayFullOrPreview(
          channelData,
          videoItem,
          currentSiteUser,
        );

        if (isPlayAllowed) {
          playVideo(videoItem.id);
          return;
        }

        selectVideo(videoItem.id);
      } else {
        this.props.errorMonitor.captureMessage(
          `Card video not found. videoId=${id}`,
        );
      }
    });
  };

  render() {
    return (
      <VideoCardsWrapper
        {...this.props}
        handlePlayRequest={this.handlePlayRequest}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorMonitor(VideoCardsWrapperDesktop));
