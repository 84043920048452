import React from 'react';
import { Picture } from '@wix/wix-vod-shared/components';
import ResizedImage from '../resized-image/resized-image';
import {
  DEFAULT_VIDEO_HEIGHT,
  DEFAULT_VIDEO_WIDTH,
} from '../../constants/sizes';
import { Video } from '../../redux/types';

interface Props {
  width: number;
  height: number;
  className: string;
}

const SimpleThumbnail: React.FC<
  Props & { isResponsive?: boolean; thumbnailUrl: string }
> = (props) => {
  const { thumbnailUrl, className, width, height, isResponsive } = props;

  if (isResponsive) {
    return (
      <Picture
        src={thumbnailUrl}
        withFallbackColor
        breakpoints={[
          {
            min: 0,
            width,
            height,
          },
        ]}
      />
    );
  }

  return (
    <ResizedImage
      src={thumbnailUrl}
      className={className}
      withFallbackColor
      width={width}
      height={height}
    />
  );
};

export const SimpleVideoThumbnail: React.FC<Props & { video: Video }> = (
  props,
) => {
  const {
    video: { coverUrl, customCoverUrl },
    width = DEFAULT_VIDEO_WIDTH,
    height = DEFAULT_VIDEO_HEIGHT,
    className,
  } = props;

  return (
    <SimpleThumbnail
      thumbnailUrl={customCoverUrl || coverUrl}
      className={className}
      width={width}
      height={height}
    />
  );
};

export const ResponsiveSimpleVideoThumbnail: React.FC<
  Props & { video: Video }
> = (props) => {
  const {
    video: { coverUrl, customCoverUrl },
    width,
    height,
    className,
  } = props;

  return (
    <SimpleThumbnail
      thumbnailUrl={customCoverUrl || coverUrl}
      className={className}
      width={width}
      height={height}
      isResponsive
    />
  );
};
export default ResponsiveSimpleVideoThumbnail;
