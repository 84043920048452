import {
  getPricingPlanInfo,
  getSubscriptionPriceInfo,
  isPricingPlanEnabled,
} from '@wix/wix-vod-shared/common';

export default function getTranslationData(channel) {
  const isPricingPlan = isPricingPlanEnabled(channel);

  return {
    props: isPricingPlan
      ? 'widget.mobile.overlay-button.subscribe.short'
      : 'widget.overlay-buttons.get-subscription',
    priceModel: isPricingPlan
      ? getPricingPlanInfo(channel.dealInfo)
      : getSubscriptionPriceInfo(channel.dealInfo),
  };
}
