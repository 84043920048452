import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFedopsLogger } from '@wix/yoshi-flow-editor';
import {
  getTitleText,
  getSubscribeButtonText,
  getPublisherText,
  getPlayButtonText,
  getPurchaseButtonText,
  getPlayNextButtonText,
  getOpacityStyle,
} from '../selectors';
import { openSubscription } from '../../../redux/player-overlay/actions/open-subscription';
import { openPlayerOverlay } from '../../../redux/player-overlay/actions/open-player-overlay';
import { playSelectedVideo } from '../../../redux/player-overlay/actions/play-selected-video';
import ComingSoon from '../partials/coming-soon';
import Button from '../../button/button';
import LiveLabel from '../../../containers/live-label/live-label';
import Icon from '../../icon/icon';
import { hasVideos } from '../../../utils/channel-helpers';
import Title from '../partials/title';
import classnames from 'classnames';
import styles from './classic.scss';

const mapStateToProps = (state, ownProps) => ({
  titleText: getTitleText(state, ownProps),
  publisherText: getPublisherText(state, ownProps),
  subscribeButtonText: getSubscribeButtonText(state, ownProps),
  playButtonText: getPlayButtonText(state, ownProps),
  purchaseButtonText: getPurchaseButtonText(state, ownProps),
  playNextButtonText: getPlayNextButtonText(state, ownProps),
  opacityStyle: getOpacityStyle(state),
});

const mapDispatchToProps = {
  openSubscription,
  openPlayerOverlay,
  playSelectedVideo,
};

const ConnectedClassicPlayerOverlay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ClassicPlayerOverlay extends React.Component {
    static propTypes = {
      titleText: PropTypes.node,
      publisherText: PropTypes.node,
      subscribeButtonText: PropTypes.node,
      playButtonText: PropTypes.node,
      purchaseButtonText: PropTypes.node,
      playNextButtonText: PropTypes.node,
      opacityStyle: PropTypes.object.isRequired,
      videoItem: PropTypes.object,
      channelData: PropTypes.object,
      className: PropTypes.string,
      openSubscription: PropTypes.func.isRequired,
      openPlayerOverlay: PropTypes.func.isRequired,
      playSelectedVideo: PropTypes.func.isRequired,
      onPlaySelectedVideo: PropTypes.func,
      playNextVideo: PropTypes.func,
      saveRef: PropTypes.func,
      nextVideoId: PropTypes.string,
    };

    renderPublisher() {
      const { publisherText } = this.props;

      const className = classnames(
        styles.publisher,
        'qa-widget-overlay-publisher',
      );

      return (
        <div className={className} data-hook="overlay-publisher">
          <span>{publisherText}</span>
        </div>
      );
    }

    renderLiveLabel() {
      const { videoItem } = this.props;

      return (
        <LiveLabel
          itemType={videoItem.itemType}
          liveVideoStatus={videoItem.liveVideoStatus}
          videoTitle={videoItem.title}
          startTime={videoItem.dateStartLive}
          className={styles['live-label']}
        />
      );
    }

    renderPlayButton() {
      const { channelData, playButtonText } = this.props;

      if (!playButtonText) {
        return null;
      }

      if (!hasVideos(channelData)) {
        return <ComingSoon className={styles['soon-info']} />;
      }

      return (
        <Button
          dataHook="overlay-play-button"
          className={styles.button}
          onClick={this.handlePlaySelectedVideo}
        >
          <Icon className={styles['play-icon']} name="arrow-right-filled" />
          <span>{playButtonText}</span>
        </Button>
      );
    }

    handlePlayNextVideoClick = (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.props.playNextVideo();
    };

    renderPlayNextButton() {
      const { playNextButtonText } = this.props;

      if (!playNextButtonText) {
        return null;
      }

      return (
        <Button
          className={styles.button}
          data-hook="overlay-next-video-button"
          onClick={this.handlePlayNextVideoClick}
        >
          {playNextButtonText}
        </Button>
      );
    }

    handleSubscriptionButtonClick = (event) => {
      event.preventDefault();
      event.stopPropagation();

      this.props.openSubscription(this.props.fedops);
    };

    renderSubscribeButton() {
      const { subscribeButtonText } = this.props;

      if (!subscribeButtonText) {
        return null;
      }

      return (
        <Button
          className={styles.button}
          dataHook="subscribe-button"
          onClick={this.handleSubscriptionButtonClick}
        >
          {subscribeButtonText}
        </Button>
      );
    }

    handlePurchaseButtonClick = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { openPlayerOverlay, videoItem, fedops } = this.props;

      openPlayerOverlay(fedops, videoItem);
    };

    renderPurchaseButton() {
      const { purchaseButtonText } = this.props;

      if (!purchaseButtonText) {
        return null;
      }

      return (
        <Button
          className={styles.button}
          dataHook="paid-access-button"
          onClick={this.handlePurchaseButtonClick}
        >
          {purchaseButtonText}
        </Button>
      );
    }

    renderButtons() {
      if (!this.props.videoItem) {
        return null;
      }

      return (
        <div className={styles.buttons}>
          {this.renderPlayButton()}
          {this.renderSubscribeButton() || this.renderPurchaseButton()}
          {this.renderPlayNextButton()}
        </div>
      );
    }

    handlePlaySelectedVideo = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const { videoItem, playSelectedVideo, onPlaySelectedVideo } = this.props;
      playSelectedVideo({ videoItem, onPlaySelectedVideo });
    };

    render() {
      const { className, opacityStyle, publisherText, titleText, saveRef } =
        this.props;

      return (
        <section className={styles['player-overlay-container']} ref={saveRef}>
          <div
            style={opacityStyle}
            className={classnames(className, styles.overlay)}
            data-hook="player-overlay player-overlay-visible"
            onClick={this.handlePlaySelectedVideo}
          >
            {titleText && (
              <div className={styles.title}>
                <Title text={titleText} />
              </div>
            )}

            {publisherText && this.renderPublisher()}

            {this.renderLiveLabel()}
            {this.renderButtons()}
          </div>
        </section>
      );
    }
  },
);

export default withFedopsLogger(ConnectedClassicPlayerOverlay);
