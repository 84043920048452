import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import { connect } from 'react-redux';
import pubsub from '../../utils/pubsub';
import invertSide from '../../utils/popout/invert-side';
import { isPortableDevice } from '../../selectors/form-factor';

import PropTypes from 'prop-types';

import events from '../../constants/events';

import {
  sides,
  popoutPositions,
  trianglePositions,
} from '../../constants/popout';
import {
  sideShape,
  popoutPositionShape,
  trianglePositionShape,
} from '../../shapes/popout';

import styles from './popout.scss';

const _propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  innerClassName: PropTypes.string,
  children: PropTypes.any,
  popoutSide: sideShape,
  popoutPosition: popoutPositionShape,
  trianglePosition: trianglePositionShape,
  triangleClassName: PropTypes.string,
  getRef: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClickOutside: PropTypes.func,
  height: PropTypes.number,
  isActive: PropTypes.bool,
};

const _defaultProps = {
  popoutSide: sides.TOP,
  popoutPosition: popoutPositions.CENTER,
  trianglePosition: trianglePositions.CENTER,
  getRef: _.noop,
  onMouseEnter: _.noop,
  onMouseLeave: _.noop,
  onClickOutside: _.noop,
};

export function showPopout(popout) {
  pubsub.publish(events.POPOUT.SHOW, popout);
}

const mapStateToProps = (state) => ({
  isPortableDevice: isPortableDevice(state),
});

const PopoutBase = connect(mapStateToProps)(
  class PopoutBase extends React.Component {
    static propTypes = _propTypes;
    static defaultProps = _defaultProps;

    componentDidMount() {
      window.addEventListener('blur', this.handleClickOutside);

      if (this.props.isPortableDevice) {
        document.addEventListener('touchstart', this.handleClickOutside);
      } else {
        document.addEventListener('click', this.handleClickOutside);
      }

      this.unsubscribeHide = pubsub.subscribe(
        events.POPOUT.SHOW,
        this.hideAllButOnePopout,
      );
    }

    componentWillUnmount() {
      window.removeEventListener('blur', this.handleClickOutside);
      document.removeEventListener('click', this.handleClickOutside);
      document.removeEventListener('touchstart', this.handleClickOutside);

      if (this.unsubscribeHide) {
        this.unsubscribeHide();
      }
    }

    handleClickOutside = (event) => {
      const { target } = event;
      if (target !== window && this.popout && this.popout.contains(target)) {
        return;
      }

      this.props.onClickOutside({ event });
    };

    hideAllButOnePopout = (popoutToShow) => {
      if (popoutToShow && this.popout && !this.popout.contains(popoutToShow)) {
        this.props.onClickOutside();
      }
    };

    getRef = (node) => {
      this.popout = node;
      this.props.getRef(node);
    };

    render() {
      const {
        children,
        className,
        onMouseEnter,
        onMouseLeave,
        triangleClassName,
        height,
        isActive,
        popoutSide,
        trianglePosition,
      } = this.props;
      let { style } = this.props;

      if (height) {
        style = { ...style, height };
      }

      const classNames = classnames(
        className,
        styles.content,
        [styles[`triangle-${invertSide(popoutSide)}`]],
        [styles[`triangle-${trianglePosition}`]],
      );

      return (
        <div
          ref={this.getRef}
          className={classNames}
          style={style}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            className={classnames(
              triangleClassName,
              styles.triangle,
              styles.before,
            )}
          />
          <div
            className={styles['tooltip-content']}
            data-hook={isActive ? 'tooltip-content' : ''}
          >
            {children}
          </div>
          <div
            className={classnames(
              triangleClassName,
              styles.triangle,
              styles.after,
            )}
          />
        </div>
      );
    }
  },
);

export default class Popout extends React.Component {
  static propTypes = _propTypes;
  static defaultProps = _defaultProps;

  render() {
    const { innerClassName, children } = this.props;
    const props = _.omit(this.props, 'styles', 'innerClassName', 'children');

    return (
      <PopoutBase {...props}>
        <div className={classnames(styles.inner, innerClassName)}>
          {children}
        </div>
      </PopoutBase>
    );
  }
}
