import React, {
  ReactNode,
  SyntheticEvent,
  memo,
  useEffect,
  useRef,
} from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import styles from './animate-crossfade.scss';

interface Props {
  currentIdIndex: number;
  onAnimationComplete: () => void;
  activeClassName: string;
  fadeInOnly: boolean;
  children: ReactNode[];
}

export const AnimateCrossfade: React.FC<Props> = ({
  fadeInOnly,
  children,
  currentIdIndex,
  activeClassName,
  onAnimationComplete,
}) => {
  const prevIndex = useRef(-1);
  useEffect(() => {
    prevIndex.current = currentIdIndex;
  }, [currentIdIndex]);

  const isActive = (slideIndex: number) => {
    return slideIndex === currentIdIndex;
  };

  const isPrevActive = (slideIndex: number) => {
    return slideIndex === prevIndex.current;
  };
  const stopPropagation = (event: SyntheticEvent) => {
    event.stopPropagation();
  };

  const renderWrappedSlide = (slide: ReactNode, slideIndex: number) => {
    const active = isActive(slideIndex);
    const className = classnames(styles.slide, {
      [styles.active]: active,
      [activeClassName]: active,
      [styles.prevActive]: isPrevActive(slideIndex),
    });

    return (
      <div
        className={className}
        onTransitionEnd={active ? onAnimationComplete : _.noop}
        key={slideIndex}
      >
        <div className={styles.inner} onTransitionEnd={stopPropagation}>
          {slide}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {fadeInOnly
        ? renderWrappedSlide(children[currentIdIndex], currentIdIndex)
        : _.map(children, renderWrappedSlide)}
    </div>
  );
};
