import { createSelector } from 'reselect';
import {
  canShowVideoList,
  canShowVideoListStrip,
  getCanShowSignIn,
  isCompactLayout,
  isStripLayout,
} from '../../../../selectors/app-settings';
import { getCurrentSiteUser } from '../../../../selectors/current-site-user';
import { hasMoreThanOneVideo } from '../../../../selectors/videos';

export const showSignIn = createSelector(
  getCanShowSignIn,
  getCurrentSiteUser,
  (allowShowSignIn, currentSiteUser) =>
    Boolean(allowShowSignIn && !currentSiteUser),
);

export const showSignOut = createSelector(
  getCanShowSignIn,
  getCurrentSiteUser,
  (allowShowSignIn, currentSiteUser) =>
    Boolean(allowShowSignIn && currentSiteUser),
);

export const showVisitChannel = createSelector(
  [
    hasMoreThanOneVideo,
    isStripLayout,
    isCompactLayout,
    canShowVideoListStrip,
    canShowVideoList,
  ],
  (
    hasMoreThanOneVideo,
    isStripLayout,
    isCompactLayout,
    canShowVideoListStrip,
    canShowVideoList,
  ) =>
    hasMoreThanOneVideo &&
    !(isStripLayout && !canShowVideoListStrip) &&
    !(isCompactLayout && !canShowVideoList),
);
