import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  Picture,
  PictureMode,
  VideoAspectRatio,
} from '@wix/wix-vod-shared/components';
import {
  MAX_COVER_IMAGE_HEIGHT,
  MAX_COVER_IMAGE_WIDTH,
} from '../../constants/sizes';
import styles from './no-videos-layout.scss';

type NoVideosLayoutProps = {
  titleKey: string;
  coverUrl?: string;
};

export const NoVideosLayout: FC<NoVideosLayoutProps> = ({
  titleKey,
  coverUrl,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <VideoAspectRatio>
        {coverUrl && (
          <Picture
            src={coverUrl}
            className={styles.picture}
            mode={PictureMode.FIT_TO_WIDTH}
            breakpoints={[
              {
                width: MAX_COVER_IMAGE_WIDTH,
                height: MAX_COVER_IMAGE_HEIGHT,
                min: 0,
              },
            ]}
          />
        )}
        <div className={styles.text}>{t(titleKey)}</div>
      </VideoAspectRatio>
    </div>
  );
};
