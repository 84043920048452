import { createSelector } from 'reselect';
import { isInFullScreen } from './player';
import { MIN_INLINE_SHARE_THUMBNAIL_WIDTH } from '../constants/sizes';

export const isInlineShareVisible = createSelector(
  (state, props) => props,
  isInFullScreen,
  (props, isFullScreen) =>
    props.itemWidth > MIN_INLINE_SHARE_THUMBNAIL_WIDTH || isFullScreen,
);
