import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withFedopsLogger } from '@wix/yoshi-flow-editor';

import { notForPreview } from '../../../utils/not-for-preview';
import { handleLogOutPaymentModal } from '../../../utils/handle-logout-payment-modal';
import {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
} from '../../../utils/open-overlay';

const mapStateToProps = null;

const mapDispatchToProps = {
  openFullScreenPurchaseOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenRentOverlay,
};

const ConnectedPlayerActionCallbacks = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class PlayerActionCallbacks extends React.Component {
    static propTypes = {
      children: PropTypes.element.isRequired,
      channelId: PropTypes.string,
      videoId: PropTypes.string,
    };

    componentDidMount() {
      handleLogOutPaymentModal({
        onRent: this.showRentInfoAtOverlay,
        onPurchase: this.showPurchaseInfoAtOverlay,
        onSubscription: this.showChannelSubscriptionInfoAtOverlay,
      });
    }

    showPurchaseInfoAtOverlay = notForPreview(() => {
      const { channelId, videoId, fedops } = this.props;
      this.props.openFullScreenPurchaseOverlay(fedops, channelId, videoId);
    });

    showRentInfoAtOverlay = notForPreview(() => {
      const { channelId, videoId, fedops } = this.props;
      this.props.openFullScreenRentOverlay(fedops, channelId, videoId);
    });

    showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
      this.props.openFullScreenSubscribeOverlay(
        this.props.fedops,
        this.props.channelId,
      );
    });

    render() {
      return React.cloneElement(this.props.children, {
        // TODO: rename to onPurchaseRequest and onSubscriptionRequest
        onPurchaseClick: this.showPurchaseInfoAtOverlay,
        onSubscribeClick: this.showChannelSubscriptionInfoAtOverlay,
        onRentClick: this.showRentInfoAtOverlay,
      });
    }
  },
);

export default withFedopsLogger(ConnectedPlayerActionCallbacks);
