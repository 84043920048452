import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { VIDEO_EVENTS, LiveState } from 'playable/dist/src/constants';
import { logBi } from '../../../../../../worker/actions/bi';
import { updateLiveStatus } from '../../../../../../redux/actions/videos';
import { ItemType } from '../../../../../../redux/types';

const mapStateToProps = null;

const mapDispatchToProps = {
  updateLiveStatus,
  logBi,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class LiveVideoStatusUpdater extends React.Component {
    static propTypes = {
      videoItem: PropTypes.object,
      updateLiveStatus: PropTypes.func,
      eventEmitter: PropTypes.object,
      channelId: PropTypes.string,
    };

    componentDidMount() {
      const { eventEmitter } = this.props;
      eventEmitter.on(
        VIDEO_EVENTS.LIVE_STATE_CHANGED,
        this.onLiveStatusChanged,
      );
    }

    componentWillUnmount() {
      const { eventEmitter } = this.props;
      eventEmitter.off(
        VIDEO_EVENTS.LIVE_STATE_CHANGED,
        this.onLiveStatusChanged,
      );
    }

    onLiveStatusChanged = ({ nextState }) => {
      const { updateLiveStatus, videoItem, channelId } = this.props;

      if (nextState === LiveState.ENDED) {
        this.props.logBi('widget.live.video.ended', {
          channelID: channelId,
          videoID: videoItem.id,
        });
        updateLiveStatus({ ...videoItem, itemType: ItemType.ItemLiveFinalize });
      }
    };

    render() {
      return null;
    }
  },
);
