import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MenuIcon from '../../../../static/images/menu.svg';

import styles from './menu-button.scss';

export default class MenuButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
  };

  render() {
    const { onClick, className, ariaLabel } = this.props;

    return (
      <button
        className={classnames(styles.button, styles.icon, className)}
        onClick={onClick}
        aria-label={ariaLabel}
        data-hook="menu-button"
      >
        <MenuIcon />
      </button>
    );
  }
}
