import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TabletPlayer from '../components/players/tablet-player';
import DesktopPlayer from '../components/players/desktop-player';

import { isPortableDevice } from '../selectors/form-factor';

export const PlayerModuleContext = React.createContext({
  PlayerComponent: null,
  isPortableDevice: false,
});

const mapStateToProps = (state) => ({
  isPortableDevice: isPortableDevice(state),
});

const PlayerModuleLoader = connect(mapStateToProps)(
  class PlayerModuleLoader extends React.PureComponent {
    static propTypes = {
      children: PropTypes.object,
    };

    render() {
      const { children, isPortableDevice } = this.props;
      const PlayerComponent = isPortableDevice ? TabletPlayer : DesktopPlayer;

      return (
        <PlayerModuleContext.Provider
          value={{
            PlayerComponent,
            isPortableDevice,
          }}
        >
          {children}
        </PlayerModuleContext.Provider>
      );
    }
  },
);

export const withPlayerModuleLoader = (WrappedComponent) =>
  class extends React.Component {
    render() {
      return (
        <PlayerModuleContext.Consumer>
          {({ PlayerComponent, isPortableDevice }) => {
            return (
              <WrappedComponent
                {...this.props}
                PlayerComponent={PlayerComponent}
                isPortableDevice={isPortableDevice}
              />
            );
          }}
        </PlayerModuleContext.Consumer>
      );
    }
  };

export default PlayerModuleLoader;
