export class SessionStorage {
  private fallbackStorage = new Map<string, string>();

  setItem(key: string, value: string): void {
    try {
      this.fallbackStorage.set(key, value);
      sessionStorage.setItem(key, value);
    } catch {}
  }

  getItem(key: string): string | null {
    try {
      return sessionStorage.getItem(key);
    } catch {
      return this.fallbackStorage.get(key) || null;
    }
  }
}
