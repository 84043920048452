import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '@wix/yoshi-flow-editor';

const ComingSoon = ({ className, t }) => (
  <span className={className}>{t('widget.overlay-buttons.coming-soon')}</span>
);

ComingSoon.propTypes = {
  className: PropTypes.string,
};

export default withTranslation()(ComingSoon);
