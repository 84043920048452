import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './scrollbar-wrapper.scss';

class ScrollbarWrapper extends React.Component {
  static propTypes = {
    scrollX: PropTypes.bool,
    scrollY: PropTypes.bool,
    onlyOnHover: PropTypes.bool,
    getRef: PropTypes.func,
  };

  static defaultProps = {
    scrollX: false,
    scrollY: true,
    onlyOnHover: false,
    getRef: _.noop,
  };

  getRef = (node) => {
    if (!node) {
      return;
    }

    this.props.getRef(node);
  };

  render() {
    const { onlyOnHover, scrollX, scrollY, className } = this.props;
    const props = _.pick(this.props, 'children', 'style');

    const classNames = classnames(className, styles['scrollbar-wrapper'], {
      [styles['only-on-hover']]: onlyOnHover,
      [styles['scroll-x']]: scrollX,
      [styles['scroll-y']]: scrollY,
    });

    return <div {...props} ref={this.getRef} className={classNames} />;
  }
}

export class WidgetScrollbarWrapper extends React.Component {
  render() {
    const { className } = this.props;
    const props = _.omit(this.props, 'styles');

    return (
      <ScrollbarWrapper
        {...props}
        className={classnames(styles.widget, className)}
      />
    );
  }
}
