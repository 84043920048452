import React, { FC, lazy, Suspense } from 'react';
import { CustomDialog } from 'wix-ui-tpa';
import classNames from 'classnames';
import { useMobileOverlay } from './MobileOverlayProvider';
import MobileSpinnerOverlay from '../mobile/components/mobile-spinner-overlay/mobile-spinner-overlay';
import { Direction } from '../containers/direction';
import { isResponsiveEditor } from '../selectors/app-settings';
import { useSelector } from 'react-redux';
import styles from './mobile-overlay.scss';

const MobileOverlay = lazy(
  () => import(/* webpackChunkName: "mobile-overlay" */ './mobile-overlay'),
);

export const MobileOverlayModal: FC = () => {
  const { isMobileOverlayOpen } = useMobileOverlay();
  const isResponsive = useSelector(isResponsiveEditor);

  if (!isMobileOverlayOpen) {
    return null;
  }

  return (
    <CustomDialog
      data-hook="vod-mobile-overlay-modal"
      isOpen={isMobileOverlayOpen}
      appendToBody={isResponsive}
      className={classNames(styles.mobileOverlayDialog, {
        [styles.fixedWidth]: !isResponsive,
      })}
    >
      <Direction>
        <Suspense fallback={<MobileSpinnerOverlay />}>
          <MobileOverlay />
        </Suspense>
      </Direction>
    </CustomDialog>
  );
};
