import { MOBILE_WIDGET_WIDTH } from '../../constants/sizes';

const WIDGET_PADDING = 20;
const VIDEOS_LIST_SPACING = 10;
export const MIN_WIDGET_WIDTH = 680;
export const MAX_WIDGET_WIDTH = 980;
export const VIDEO_HEIGHT_RATIO = 9 / 16;
const MOBILE_OVERLAY_HEIGHT_RATIO = 250 / 320;

function roundAsBrowser(number) {
  return Math.floor(number * 100) / 100;
}

function playerHeightWithRatio(width) {
  return width * VIDEO_HEIGHT_RATIO;
}

export function playerSize(widgetWidth, widgetPadding = WIDGET_PADDING) {
  const width = widgetWidth - widgetPadding * 2;
  return {
    width: roundAsBrowser(width),
    height: roundAsBrowser(playerHeightWithRatio(width)),
  };
}

// TODO: move to mobile only selectors
export function playerSizeMobile(widgetWidth) {
  return playerSize(widgetWidth, 0);
}

// TODO: move to mobile only selectors
export function overlaySizeMobileMainItem(widgetWidth = MOBILE_WIDGET_WIDTH) {
  return {
    width: widgetWidth,
    height: roundAsBrowser(widgetWidth * MOBILE_OVERLAY_HEIGHT_RATIO),
  };
}

export function playerSizeMobileMainItem() {
  return {
    width: MOBILE_WIDGET_WIDTH,
    height: roundAsBrowser(MOBILE_WIDGET_WIDTH * VIDEO_HEIGHT_RATIO),
  };
}

// TODO: move to strip only selectors
export function stripLayoutThumbnailSize(widgetWidth, videosInRow) {
  const width = widgetWidth / videosInRow;

  return {
    width: roundAsBrowser(width),
    height: roundAsBrowser(playerHeightWithRatio(width)),
  };
}

export function listThumbnailSize(widgetWidth, videosInRow) {
  const listWidth = widgetWidth - WIDGET_PADDING * 2;
  const width =
    (listWidth - (videosInRow - 1) * VIDEOS_LIST_SPACING) / videosInRow;

  return {
    width: roundAsBrowser(width),
    height: roundAsBrowser(playerHeightWithRatio(width)),
  };
}
