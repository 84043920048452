import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../../icon/icon';
import { withTranslation } from '@wix/yoshi-flow-editor';

import styles from '../account-button.scss';

export default withTranslation()(
  class AccountInfoButton extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      onClick: PropTypes.func,
      isFocusable: PropTypes.bool,
    };

    static defaultProps = {
      isFocusable: true,
    };

    render() {
      const { className, onClick, isFocusable } = this.props;

      return (
        <button
          className={classnames(styles['account-info-button'], className)}
          onClick={onClick}
          data-hook="channel-account-info"
          tabIndex={isFocusable ? 0 : -1}
          aria-label={this.props.t('widget.accessibility.my-account')}
        >
          <Icon name="avatar" />
        </button>
      );
    }
  },
);
