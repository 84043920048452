import React, { FC } from 'react';
import { NoVideosLayout } from './no-videos-layout';

type ComingSoonProps = {
  coverUrl: string;
};

export const ComingSoon: FC<ComingSoonProps> = function ({ coverUrl }) {
  return (
    <NoVideosLayout
      coverUrl={coverUrl}
      titleKey="widget.this-channel-is-coming-soon"
    />
  );
};
