import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useEnvironment, useWixSdk } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';
import {
  MIN_WIDGET_WIDTH,
  MAX_WIDGET_WIDTH,
} from '../../utils/videos-sizes/videos-sizes';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/app-settings';
import { WIDTH_CONSTRAINTS } from '../../constants/thumbnail-sizes';
import { GRID_MARGINS } from '../../layouts/grid/constants';
import {
  COMPACT_MIN_WIDGET_WIDTH,
  COMPACT_MAX_WIDGET_WIDTH,
} from '../../layouts/compact/constants';
import { useIsWixStudio } from '../../utils/useIsWixStudio';
import { MOBILE_WIDGET_WIDTH } from '../../constants/sizes';
import stylesParams from '../../../stylesParams';

function useMinMax(): {
  min: number;
  max: number;
} | null {
  const styles = useStyles();
  const layout: CHANNEL_LAYOUT_VALUES = styles.get(stylesParams.channelLayout);
  const { isEditorX, isMobile } = useEnvironment();
  const isWixStudio = useIsWixStudio();

  if (isMobile) {
    return {
      min: MOBILE_WIDGET_WIDTH,
      max: Infinity,
    };
  }

  if (isEditorX || isWixStudio) {
    return null;
  }

  if (layout === CHANNEL_LAYOUT_VALUES.CLASSIC) {
    return {
      min: MIN_WIDGET_WIDTH,
      max: MAX_WIDGET_WIDTH,
    };
  }

  if (layout === CHANNEL_LAYOUT_VALUES.COMPACT) {
    return {
      min: COMPACT_MIN_WIDGET_WIDTH,
      max: COMPACT_MAX_WIDGET_WIDTH,
    };
  }

  if (layout === CHANNEL_LAYOUT_VALUES.GRID) {
    return {
      min: GRID_MARGINS * 2 + WIDTH_CONSTRAINTS[0],
      max: Infinity,
    };
  }

  return null;
}

const Resizer: FC<PropsWithChildren> = ({ children }) => {
  const { Wix } = useWixSdk();
  const { dimensions } = useEnvironment();
  const minMax = useMinMax();

  const getFullWidth = useCallback(
    async () =>
      new Promise<boolean>((resolve) => {
        if (Wix) {
          Wix.Settings.isFullWidth((isFullWidth: boolean) =>
            resolve(isFullWidth),
          );
        }
      }),
    [Wix],
  );

  useEffect(() => {
    getFullWidth().then((isFullWidth) => {
      if (isFullWidth || !Wix || !minMax) {
        return;
      }

      const { min, max } = minMax;
      const width = Math.max(Math.min(dimensions.width, max), min);

      if (width !== dimensions.width) {
        Wix.resizeComponent({
          width,
          height: 0, // height is automatically adjusted to the content (fitToContentHeight: true)
        });
      }
    });
  }, [Wix, dimensions, getFullWidth, minMax]);

  return <>{children}</>;
};

export const WidgetResizer: FC<PropsWithChildren> = ({ children }) => {
  const { isEditor } = useEnvironment();

  return (
    <>
      {children}
      {isEditor ? <Resizer /> : null}
    </>
  );
};
