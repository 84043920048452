import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFedopsLogger } from '@wix/yoshi-flow-editor';

import { notForPreview } from '../../../../utils/not-for-preview';
import { logBi } from '../../../../worker/actions/bi';
import { getCurrentSiteUser } from '../../../../selectors/current-site-user';
import {
  openFullScreenSubscribeOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
} from '../../../../utils/open-overlay';

const mapStateToProps = (state) => ({
  currentSiteUser: getCurrentSiteUser(state),
});

const mapDispatchToProps = {
  logBi,
  openFullScreenSubscribeOverlay,
  openFullScreenPurchaseOverlay,
  openFullScreenRentOverlay,
};

const ConnectedStripPlayerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class StripPlayerWrapper extends React.Component {
    static propTypes = {
      width: PropTypes.number,
      height: PropTypes.number,
      videoUrls: PropTypes.object,
      size: PropTypes.object,
      simple: PropTypes.bool,
      overlay: PropTypes.any,
      onPlayStart: PropTypes.func,
      onEnded: PropTypes.func,
      preload: PropTypes.string,
      fetchVideoUrls: PropTypes.func,
      appSettings: PropTypes.object,
      mainOrThumbnailLocation: PropTypes.string,
      loop: PropTypes.bool,

      onSubscribeClick: PropTypes.func,
      onPurchaseClick: PropTypes.func,
      isVideoCardsEnabled: PropTypes.bool,
      fetchVideoCards: PropTypes.func,
      onPlayRequest: PropTypes.func,
      shouldLoadVideo: PropTypes.bool,
      isOverQuota: PropTypes.bool,
      isLive: PropTypes.bool,
      cleanupVideoCards: PropTypes.func,
      isInLightbox: PropTypes.bool,
      isAutoplayed: PropTypes.bool,
      handleVideoEnd: PropTypes.func,
      canShowChannelCover: PropTypes.bool,
      onMemberSignUp: PropTypes.func,
      isUserLogged: PropTypes.bool,
      videoItem: PropTypes.object,
      channelData: PropTypes.object,
      currentSiteUser: PropTypes.object,

      PlayerComponent: PropTypes.func,
    };

    openPurchase = notForPreview(() => {
      const { videoItem, channelData, fedops } = this.props;
      this.props.openFullScreenPurchaseOverlay(
        fedops,
        channelData.id,
        videoItem.id,
      );
    });

    openRent = notForPreview(() => {
      const { videoItem, channelData, fedops } = this.props;
      this.props.openFullScreenRentOverlay(
        fedops,
        channelData.id,
        videoItem.id,
      );
    });

    openSubscription = notForPreview(() => {
      const { channelData, fedops } = this.props;

      this.props.openFullScreenSubscribeOverlay(fedops, channelData.id, () => {
        this.props.logBi('widget.subscriptionPopUp.Completed', {
          channelID: channelData.id,
          result: 'canceled',
          errorMsg: '',
        });
      });
    });

    render() {
      const props = _.omit(
        this.props,
        'mobileMode', // can't do this
        'playAtFullScreen', // can't do this
        'showInitialOverlay', // can't do this
        'showControls', // can't do this
        'showPoster', // can't do this
        'currentTime', // can't do this
        'paused', // can't do this
        'muted', // can't do this
        'onPurchaseClick',
        'onSubscribeClick',
        'onRentClick',
      );

      const { PlayerComponent } = this.props;

      return (
        <PlayerComponent
          {...props}
          playAtFullScreen={false}
          showPlayer={false}
          showInitialOverlay
          showControls
          showPoster
          paused
          shouldLoadVideo={false}
          onPurchaseClick={this.openPurchase}
          onSubscribeClick={this.openSubscription}
          onRentClick={this.openRent}
        />
      );
    }
  },
);

export default withFedopsLogger(ConnectedStripPlayerWrapper);
