import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type WidgetFocusContextType = {
  widgetFocused: boolean;
};

const WidgetFocusContext = createContext<WidgetFocusContextType | null>(null);

export const WidgetFocusProvider: FC<PropsWithChildren> = ({ children }) => {
  const [widgetFocused, setFocused] = useState(false);

  return (
    <WidgetFocusContext.Provider value={{ widgetFocused }}>
      <div
        data-hook="widget-focus-provider"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {children}
      </div>
    </WidgetFocusContext.Provider>
  );
};

export const useWidgetFocus = () => {
  const context = useContext(WidgetFocusContext);

  if (context === null) {
    throw new Error('Use useWidgetFocus hook within WidgetFocusProvider');
  }

  return context;
};
