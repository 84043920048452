import React from 'react';
import PropTypes from 'prop-types';
import styles from './title.scss';

const truncate = (str = '', maxLength) =>
  str.length <= maxLength ? str : `${str.slice(0, 40)}...`;

const Title = ({ children, dataHook, maxCharsCount }) => (
  <div data-hook={dataHook} className={styles.container}>
    {truncate(children, maxCharsCount)}
  </div>
);

Title.propTypes = {
  children: PropTypes.string.isRequired,
  maxCharsCount: PropTypes.number,
  dataHook: PropTypes.string,
};

Title.defaultProps = {
  maxCharsCount: 40,
};

export default Title;
