import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconButton from '../../buttons/icon/icon';

import styles from './header.scss';

export default class ModalHeader extends React.Component {
  static propTypes = {
    title: PropTypes.any,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    onButtonClick: PropTypes.func,
  };

  static defaultProps = {
    onButtonClick: _.noop,
  };

  get title() {
    const { title, titleClassName } = this.props;

    if (!title) {
      return null;
    }

    return (
      <h1
        data-hook="modal-title"
        className={classnames(styles.title, titleClassName)}
      >
        {title}
      </h1>
    );
  }

  render() {
    const { onButtonClick, className, buttonClassName } = this.props;
    const classNames = classnames(className, styles.header);

    return (
      <header className={classNames}>
        {this.title}
        <IconButton
          dataHook="header-close-button"
          className={classnames(
            styles.button,
            styles['close-button'],
            buttonClassName,
          )}
          onClick={onButtonClick}
          name="close"
        />
      </header>
    );
  }
}
