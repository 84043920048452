import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/widget';
import { DEFAULT_TRANSLATIONS as PURCHASE_TRANSLATIONS } from '../../../../../../../utils/buy-button-texts/buy-button-texts';
import {
  getSalePriceInfo,
  getRentPriceInfo,
  getMinPriceInfo,
  getSubscriptionPriceInfo,
  getPricingPlanInfo,
} from '@wix/wix-vod-shared/common';

export default function getTranslationData(fullAccessAction, channel, video) {
  switch (fullAccessAction) {
    case FULL_ACCESS_ACTION_NAMES.BUY:
      return {
        props: PURCHASE_TRANSLATIONS[fullAccessAction],
        priceModel: getSalePriceInfo(video.dealInfo),
      };
    case FULL_ACCESS_ACTION_NAMES.RENT:
      return {
        props: PURCHASE_TRANSLATIONS[fullAccessAction],
        priceModel: getRentPriceInfo(video.dealInfo),
      };
    case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
      return {
        props: PURCHASE_TRANSLATIONS[fullAccessAction],
        priceModel: getMinPriceInfo(video.dealInfo),
      };
    case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
      return {
        props: 'widget.overlay-buttons.get-subscription',
        priceModel: getSubscriptionPriceInfo(channel.dealInfo),
      };
    case FULL_ACCESS_ACTION_NAMES.PRICING_PLAN:
      return {
        props: PURCHASE_TRANSLATIONS[fullAccessAction],
        priceModel: getPricingPlanInfo(channel.dealInfo),
      };
    default:
      return null;
  }
}
