const MOBILE_MAX_WIDTH = 600;
const TABLET_MAX_WIDTH = 1366;

export function isMobile() {
  if (/mobile(-overlay)?.html/.test(window.location.href)) {
    return true;
  }

  const { width, height } = getScreenSizeByUserAgent();
  const screenWidth = isLandscape() ? height : width;

  const isMobileScreenSize = screenWidth < MOBILE_MAX_WIDTH;
  const isMobileDetectedByClient =
    isMobileScreenSize && (isTouchScreen() || isMSMobileDevice());

  return isMobileDetectedByClient;
}

export function isTablet() {
  const { width, height } = getScreenSizeByUserAgent();
  const screenWidth = isPortrait() ? height : width;

  return (
    !isMobile() &&
    screenWidth >= MOBILE_MAX_WIDTH &&
    screenWidth <= TABLET_MAX_WIDTH &&
    isTouchScreen()
  );
}

function isMSMobileDevice() {
  return /iemobile/i.test(getUserAgent());
}

function isTouchScreen() {
  return Boolean(
    'ontouchstart' in window ||
      (window.DocumentTouch && window.document instanceof window.DocumentTouch),
  );
}

function getUserAgent() {
  return window.navigator.userAgent.toLowerCase();
}

export function getScreenSizeByUserAgent() {
  const userAgent = getUserAgent();

  const specificAndroidParams = paramsForSpecificAndroidDevices(userAgent);

  let width;
  let height;

  const screenDimensions = getScreenDimensions();

  if (specificAndroidParams) {
    width = specificAndroidParams.width;
    height = specificAndroidParams.height;
  } else if (isPortrait()) {
    width = Math.min(screenDimensions.width, screenDimensions.height);
    height = Math.max(screenDimensions.width, screenDimensions.height);
  } else {
    width = Math.max(screenDimensions.width, screenDimensions.height);
    height = Math.min(screenDimensions.width, screenDimensions.height);
  }

  return { width, height };
}

function paramsForSpecificAndroidDevices(userAgent) {
  /* eslint-disable max-len */
  switch (true) {
    case /(GT-S5300B|GT-S5360|GT-S5367|GT-S5570I|GT-S6102B|LG-E400f|LG-E400g|LG-E405f|LG-L38C|LGL35G)/i.test(
      userAgent,
    ):
      return { width: 240, height: 320 };
    case /(Ls 670|GT-S5830|GT-S5839i|GT-S6500D|GT-S6802B|GT-S7500L|H866C|Huawei-U8665|LG-C800|LG-MS695|LG-VM696|LGL55C|M865|Prism|SCH-R720|SCH-R820|SCH-S720C|SPH-M820-BST|SPH-M930BST|U8667|X501_USA_Cricket|ZTE-Z990G)/i.test(
      userAgent,
    ):
      return { width: 320, height: 480 };
    case /(5860E|ADR6300|ADR6330VW|ADR8995|APA9292KT|C771|GT-I8160|GT-I9070|GT-I9100|HTC-A9192|myTouch4G|N860|PantechP9070|PC36100|pcdadr6350|SAMSUNG-SGH-I727|SAMSUNG-SGH-I777|SAMSUNG-SGH-I997|SC-03D|SCH-I405|SCH-I500|SCH-I510|SCH-R760|SGH-S959G|SGH-T679|SGH-T769|SGH-T959V|SGH-T989|SPH-D700)/i.test(
      userAgent,
    ):
      return { width: 480, height: 800 };
    case /(DROIDX|SonyEricssonSO-02C|SonyEricssonST25i)/i.test(userAgent):
      return { width: 480, height: 854 };
    case /(DROID3|MB855)/i.test(userAgent):
      return { width: 540, height: 960 };
    case /F-05D/i.test(userAgent):
      return { width: 720, height: 1280 };
    default:
      return null;
  }

  /* eslint-enable max-len */
}

function getScreenDimensions() {
  return { width: window.screen.width, height: window.screen.height };
}

export function isLandscape() {
  return !isPortrait();
}

export function isPortrait() {
  /*
   Correct solution would be 'window.innerHeight > window.innerWidth' but we don't have access
   to real innerHeight and innerWidth so set to null
   */
  const isPortraitByScreenSize = null;
  const { orientation } = window;

  return orientation === 0 || orientation === 180 || isPortraitByScreenSize;
}
