import React from 'react';
import _ from 'lodash';

import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { getAllSettings } from '../../../selectors/app-settings';
import { getCurrentUserEmail } from '../../../selectors/current-site-user';
import { isSubscriptionEnabled } from '@wix/wix-vod-shared/common';

import EVENTS from '../../../constants/events';

import { LinkButton } from '../../buttons/buttons';
import { sides } from '../../../constants/popout';

import AccountInfoButton from './account-info-button/account-info-button';
import AccountInfoButtonWithTooltip from './account-info-button-with-tooltip/account-button-with-tooltip';

import styles from './account-button.scss';

import {
  withPubSubEvents,
  consumePubSubEvent,
} from '../../../containers/pub-sub-events';

const mapStateToProps = (state, ownProps) => ({
  appSettings: getAllSettings(state),
  userEmail: getCurrentUserEmail(state),
  isSubscriptionEnabled: isSubscriptionEnabled(ownProps.channelData),
});

export default withTranslation()(
  connect(mapStateToProps)(
    withPubSubEvents(
      class AccountButton extends React.Component {
        static propTypes = {
          className: PropTypes.string,
          infoButtonClassName: PropTypes.string,

          appSettings: PropTypes.object,
          userEmail: PropTypes.string,

          onLoginClick: PropTypes.func,
          onLogoutClick: PropTypes.func,
          // click on a simple logout button
          onClick: PropTypes.func,
          onSubscribeClick: PropTypes.func,
          tooltipSide: PropTypes.oneOf(_.values(sides)),

          isSubscriptionEnabled: PropTypes.bool,
          isFocusable: PropTypes.bool,
        };

        componentDidUpdate(prevProps) {
          consumePubSubEvent(
            EVENTS.SESSION.LOGOUT_USER,
            this.handleLogoutEvent,
            {
              prevProps,
              nextProps: this.props,
            },
          );
        }

        handleLogoutEvent = () => {
          if (this.disableConsumeEvents) {
            return;
          }
          this.props.onLogoutClick();
          this.disableConsumeEvents = true;
        };

        handleAccountButtonClicked = () => {
          this.props.onClick();
        };

        renderLoginButton() {
          const { className, onLoginClick, isFocusable } = this.props;

          return (
            <div className={className}>
              <LinkButton
                className={styles['account-button']}
                isFocusable={isFocusable}
                onClick={onLoginClick}
                dataHook="login-button"
              >
                {this.props.t('widget.account.sign-in')}
              </LinkButton>
            </div>
          );
        }

        renderAccountButtonWithTooltip() {
          const { infoButtonClassName, userEmail, onLogoutClick } = this.props;

          return (
            <AccountInfoButtonWithTooltip
              className={infoButtonClassName}
              logoutLabel={this.props.t('widget.account.logout')}
              userEmail={userEmail}
              onLogoutClick={onLogoutClick}
              dataHook="info-button-with-tooltip"
            />
          );
        }

        renderAccountButtonSimple() {
          const { infoButtonClassName, isFocusable } = this.props;

          return (
            <AccountInfoButton
              className={infoButtonClassName}
              isFocusable={isFocusable}
              onClick={this.handleAccountButtonClicked}
            />
          );
        }

        renderAccountButton() {
          const { isSubscriptionEnabled } = this.props;

          return isSubscriptionEnabled
            ? this.renderAccountButtonSimple()
            : this.renderAccountButtonWithTooltip();
        }

        render() {
          const { className, userEmail } = this.props;

          if (!userEmail) {
            return this.renderLoginButton();
          }

          return (
            <div
              data-hook="member-actions"
              className={classnames(
                'qa-account',
                className,
                styles['account-info-button-wrapper'],
              )}
            >
              {this.renderAccountButton()}
            </div>
          );
        }
      },
    ),
  ),
);
