export function getCurrency(video, channel) {
  const dealInfo = video?.dealInfo?.[0] || channel?.dealInfo?.[0];
  return dealInfo?.currency;
}

function enabledDealInfo(dealInfo = []) {
  return dealInfo.find((d) => d.enabled) || null;
}

export function getEnabledCurrency(video, channel) {
  const dealInfo =
    enabledDealInfo(video?.dealInfo) || enabledDealInfo(channel?.dealInfo);
  return dealInfo?.currency;
}
