import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useIds } from './useIds';
import { RootState } from '../redux/root-state';
import { getCurrentVideoId } from '../selectors/video';
import { getCurrentChannelId } from '../selectors/channels';

export function useReloadOnIdsChange() {
  const { isEditor } = useEnvironment();
  const { channelId, videoId } = useIds();
  const stateChannelId = useSelector<RootState>(getCurrentChannelId);
  const stateVideoId = useSelector<RootState>(getCurrentVideoId);

  useLayoutEffect(() => {
    if (!isEditor) {
      return;
    }

    if (channelId !== stateChannelId || videoId !== stateVideoId) {
      window.location.reload();
    }
  }, [isEditor, channelId, videoId, stateChannelId, stateVideoId]);
}
