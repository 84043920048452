import React from 'react';
import PropTypes from 'prop-types';
import EVENTS from '../constants/events';
import { connect } from 'react-redux';
import { setHydratedData } from '../redux/hydrated-data/hydrated-data';
import { consumePubSubEvent, withPubSubEvents } from './pub-sub-events';

const mapStateToProps = null;
const mapDispatchToProps = { setHydratedData };

export const LoginWatcher = withPubSubEvents(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class LoginWatcher extends React.Component {
      static propTypes = {
        setHydratedData: PropTypes.func.isRequired,
      };

      componentDidUpdate(prevProps) {
        consumePubSubEvent(EVENTS.SESSION.LOGIN_USER, this.handleUserLogin, {
          prevProps,
          nextProps: this.props,
        });
      }

      handleUserLogin = async (event) => {
        const { id, email } = event.data;

        this.props.setHydratedData({
          currentSiteUser: {
            id,
            email,
          },
        });
      };

      render() {
        return null;
      }
    },
  ),
);
