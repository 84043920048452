import CACHE_ITEM_STATUS from '../constants/ulrs-cache-status';
import { createSelector } from 'reselect';

const getUrlsCache = (state) => state.videoUrlsCache;
const getVideoItem = (_, props) => props.videoItem;
const getVideoUrls = createSelector(
  [getUrlsCache, getVideoItem],
  (cache, videoItem) => {
    if (!videoItem) {
      return null;
    }

    const videoUrlsFromCache = cache[videoItem.id];

    if (
      videoUrlsFromCache &&
      videoUrlsFromCache.status === CACHE_ITEM_STATUS.RESOLVED
    ) {
      return videoUrlsFromCache.urls;
    }

    return null;
  },
);

export default getVideoUrls;
