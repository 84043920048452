import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './tag.scss';

const noop = () => {};

interface NotRoundedProps {
  rounded: false;
}

interface RoundedProps {
  rounded: true;
  roundedClassName: string;
}

interface BaseProps {
  text: string;
  className?: string;
  onFocus?: () => void;
  onClick?: (text: string) => void;
  onBlur?: () => void;
}

type Props = BaseProps & (NotRoundedProps | RoundedProps);

export const Tag: React.FC<Props> = (props) => {
  const {
    text,
    className,
    onBlur = noop,
    onClick = noop,
    onFocus = noop,
  } = props;
  const handleClick = () => {
    onClick(text);
  };
  const classNames = classnames(className, styles.tag, {
    [styles.rounded]: props.rounded,
    [props.rounded ? props.roundedClassName : '']:
      props.rounded && props.roundedClassName,
  });

  return (
    <button
      type="button"
      className={classNames}
      onClick={handleClick}
      onFocus={onFocus}
      onBlur={onBlur}
      data-hook="tag"
    >
      <span className={styles.inner}>{text}</span>
    </button>
  );
};

Tag.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Tag;
