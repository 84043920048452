import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { visibleInViewPort } from '../../../../utils/visible-on-screen';
import styles from './first-time-track-animation.scss';
import { withEnvironment } from '@wix/yoshi-flow-editor';

const firstTimeTrackAnimation = (WrappedComponent) => {
  const FirstTimeTrackAnimation = withEnvironment(
    visibleInViewPort(
      class FirstTimeTrackAnimation extends React.Component {
        static propTypes = {
          isVisibleInViewport: PropTypes.bool,
          trackClassName: PropTypes.string,
        };

        static defaultProps = {
          isVisibleInViewport: false,
        };

        constructor(props) {
          super(props);

          this.state = {
            isTrackStarted: false,
            isShownAlready: false,
            shouldApplyAnimationClassName: false,
          };
        }

        componentDidMount() {
          this.handleNextProps();
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
          this.handleNextProps(nextProps);
        }

        handleNextProps = (props = this.props) => {
          const { isVisibleInViewport } = props;
          const { isTrackStarted, isShownAlready } = this.state;

          if (props.environment.isSSR) {
            return;
          }

          if (isShownAlready) {
            return;
          }

          const isShown = !isTrackStarted && isVisibleInViewport;

          this.setState({
            shouldApplyAnimationClassName: isShown,
            isShownAlready: isShown,
          });
        };

        updateTrackStarted = _.once(() => {
          this.setState({
            isTrackStarted: true,
          });
        });

        render() {
          const { trackClassName } = this.props;
          const { shouldApplyAnimationClassName } = this.state;

          const mergedTrackClassName = classnames(trackClassName, {
            [styles['start-animation']]: shouldApplyAnimationClassName,
          });

          return (
            <WrappedComponent
              {...this.props}
              onUserStartedTracking={this.updateTrackStarted}
              trackClassName={mergedTrackClassName}
            />
          );
        }
      },
    ),
  );

  FirstTimeTrackAnimation.WixWrappedComponent = WrappedComponent;

  return FirstTimeTrackAnimation;
};

export default firstTimeTrackAnimation;
