import _ from 'lodash';
import {
  getAvailabilityStatus,
  FULL_ACCESS_ACTION_NAMES,
  OWNERSHIP_STATUSES,
} from '@wix/wix-vod-shared/widget';
import {
  getSubscriptionPriceInfo,
  getSalePriceInfo,
  getRentPriceInfo,
  getMinPriceInfo,
} from '@wix/wix-vod-shared/common';
import formatMessageWithPrice from '../format-message-with-price';

// FIXME: these translations looks redundant, it should be enough to have two separate
// key-map translations for cover and player
export const DEFAULT_TRANSLATIONS = {
  [FULL_ACCESS_ACTION_NAMES.BUY]: 'widget.overlay-buttons.buy',
  [FULL_ACCESS_ACTION_NAMES.RENT]: 'widget.overlay-buttons.rent',
  [FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT]: 'widget.overlay-buttons.buy-from',
  [FULL_ACCESS_ACTION_NAMES.PRICING_PLAN]:
    'widget.mobile.overlay-button.subscribe.short',
};

const COVER_TRANSLATIONS_MAP = {
  [FULL_ACCESS_ACTION_NAMES.SUBSCRIBE]: 'widget.channel-actions.subscribe',
  [FULL_ACCESS_ACTION_NAMES.BUY]: 'widget.overlay-buttons.buy',
  [FULL_ACCESS_ACTION_NAMES.RENT]: 'widget.overlay-buttons.rent',
  [FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT]: 'widget.overlay-buttons.buy-from',
  [FULL_ACCESS_ACTION_NAMES.PRICING_PLAN]:
    'widget.mobile.overlay-button.subscribe.short',
};

const PLAYER_TRANSLATIONS_MAP = {
  ...COVER_TRANSLATIONS_MAP,
  [OWNERSHIP_STATUSES.YOU_BOUGHT_THIS]: 'actions.purchased',
  [OWNERSHIP_STATUSES.YOU_RENTED_THIS]: 'actions.rented',
};

const getBuyRentText = (
  { channelData, videoItem, currentSiteUser, translations },
  t,
  language,
) => {
  const status = getAvailabilityStatus({
    channelData,
    videoItem,
    currentSiteUser,
  });
  const translationString = translations[status];

  if (!translationString) {
    return null;
  }

  let priceModel;
  let rentModel;

  switch (status) {
    case FULL_ACCESS_ACTION_NAMES.PRICING_PLAN:
      return t(translationString);
    case FULL_ACCESS_ACTION_NAMES.SUBSCRIBE:
      priceModel = getSubscriptionPriceInfo(channelData.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case FULL_ACCESS_ACTION_NAMES.BUY:
      priceModel = getSalePriceInfo(videoItem.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case FULL_ACCESS_ACTION_NAMES.RENT:
      priceModel = getRentPriceInfo(videoItem.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT:
      priceModel = getMinPriceInfo(videoItem.dealInfo);
      return formatMessageWithPrice(translationString, priceModel);

    case OWNERSHIP_STATUSES.YOU_RENTED_THIS:
      rentModel = _.get(videoItem.dgsInfo, 'rent');
      const date = rentModel.expirationTs
        ? new Intl.DateTimeFormat(language, {
            month: 'short',
            day: 'numeric',
          }).format(new Date(rentModel.expirationTs * 1000))
        : undefined;

      return t(translationString, { date });
    default:
      return t(translationString);
  }
};

export const getBuyRentTextForPlayer = (
  { channelData, videoItem, currentSiteUser },
  t,
  language,
) => {
  const params = {
    channelData,
    videoItem,
    currentSiteUser,
    translations: PLAYER_TRANSLATIONS_MAP,
  };
  return getBuyRentText(params, t, language);
};
