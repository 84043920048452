import React from 'react';
import PropTypes from 'prop-types';
import { getUrlToChannel, getUrlToVideo } from '@wix/wix-vod-shared/common';
import { SOCIAL_NETWORKS } from '@wix/wix-vod-shared/widget';
import { connect } from 'react-redux';
import {
  getCompId,
  getFullSiteUrl,
} from '../../../redux/hydrated-data/hydrated-data';
import { openModal } from '../../../redux/actions/modal';
import styles from './share-mobile.scss';
import { withTranslation } from '@wix/yoshi-flow-editor';

// https://w3c.github.io/web-share/
const ABORT_ERR = 20;

const mapStateToProps = (state) => ({
  compId: getCompId(state),
  siteUrl: getFullSiteUrl(state),
});

const mapDispatchToProps = { openModal };

const ShareMobile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ShareMobile extends React.Component {
    static displayName = 'ShareMobile';

    static propTypes = {
      videoItem: PropTypes.object,
      channelData: PropTypes.object,
      siteUrl: PropTypes.string,
      openModal: PropTypes.func,
      getUrlToChannel: PropTypes.func,
      getUrlToVideo: PropTypes.func,
      children: PropTypes.node,
      compId: PropTypes.string,
      isOpen: PropTypes.bool,
    };

    static defaultProps = {
      dataHook: 'share-mobile',
    };

    constructor(props) {
      super(props);

      this.state = {
        ShareMobileContent: null,
      };
    }

    hasNativeShare = () => Boolean(navigator.share);

    loadSharePopup = async () => {
      const ShareMobileContent = (
        await import(
          /* webpackChunkName: "mobile/share-popup-content" */ './popup-content/popup-content'
        )
      ).default;

      this.setState({
        ShareMobileContent,
      });
    };

    async componentDidMount() {
      if (!this.hasNativeShare()) {
        await this.loadSharePopup();
      }

      if (this.props.isOpen) {
        this.openPopup();
      }
    }

    sendStatsReport = (name) => {
      const { channelData: channel, videoItem } = this.props;

      const stats = {
        network: {
          name,
        },
        channel,
      };

      if (videoItem) {
        stats.videoItem = videoItem;
      }
    };

    openPopup = () => {
      if (this.hasNativeShare()) {
        this.openNativeShare();
      } else {
        this.openSharePopup();
      }
    };

    openNativeShare = () => {
      const { siteUrl, compId, channelData, videoItem } = this.props;
      let url, title;

      if (videoItem) {
        title = videoItem.title;
        url = getUrlToVideo(videoItem, { siteUrl, compId });
      } else {
        title = channelData.title;
        url = getUrlToChannel({ siteUrl, compId });
      }

      navigator
        .share({
          url,
          title,
        })
        .then(() => this.sendStatsReport(SOCIAL_NETWORKS.LINK))
        .catch(async (error) => {
          if (error.code !== ABORT_ERR) {
            await this.loadSharePopup();
            this.openSharePopup();
          }
        });
    };

    openSharePopup = () => {
      const { videoItem, compId, channelData, top, siteUrl, openModal } =
        this.props;
      const { ShareMobileContent } = this.state;
      let title, titleLabel, inputLabel, url, description, coverUrl;

      if (videoItem) {
        titleLabel = 'widget.share-video.share';
        inputLabel = 'widget.share-video.share-link';
        url = getUrlToVideo(videoItem, { siteUrl, compId });
        description = videoItem.description;
        coverUrl = videoItem.customCoverUrl || videoItem.coverUrl;
        title = videoItem.title;
      } else {
        titleLabel = 'widget.share-channel.share';
        inputLabel = 'widget.share-channel.share-link';
        url = getUrlToChannel({ siteUrl, compId });
        description = channelData.description;
        coverUrl = channelData.customCoverUrl || channelData.coverUrl;
        title = channelData.title;
      }

      const shareParams = {
        siteUrl: url,
        title,
        description,
        coverUrl,
      };

      openModal({
        title: (
          <span className={styles.titleWrap}>{this.props.t(titleLabel)}</span>
        ),
        top,
        closeButtonLabel: this.props.t('widget.accessibility.close-share'),
        content: (
          <ShareMobileContent
            shareParams={shareParams}
            inputLabel={this.props.t(inputLabel)}
            channelId={channelData.id}
            videoId={videoItem && videoItem.id}
            onShare={this.sendStatsReport}
          />
        ),
      });
    };

    render() {
      const { children } = this.props;

      return children
        ? React.cloneElement(children, {
            onClick: this.openPopup,
          })
        : null;
    }
  },
);

export default withTranslation()(ShareMobile);
