import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { Info } from '@wix/wix-vod-shared/components';
import { PRODUCTION_HOSTS } from '@wix/wix-vod-constants/env';

class VideoInfo extends React.Component {
  static propTypes = {
    posterUrl: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    publisher: PropTypes.string,
    durationStr: PropTypes.string,
    durationAriaLabel: PropTypes.string,
    genre: PropTypes.string,
    videoType: PropTypes.string,
    cast: PropTypes.array,
    crew: PropTypes.array,
    isPlayerInFullScreen: PropTypes.bool,
    isRTL: PropTypes.bool,
  };

  translateCrew(crew) {
    // eslint-disable-next-line arrow-parens
    return _.map(crew, (crewMember) => {
      const { role } = crewMember;

      if (!role) {
        return crewMember;
      }

      return {
        ...crewMember,
        role: this.props.t(`shared.crew.${role}`),
      };
    });
  }

  translateGenre(genre) {
    if (!genre) {
      return null;
    }

    return this.props.t(`shared.genres.${genre}`);
  }

  translateVideoType(videoType) {
    if (!videoType) {
      return null;
    }

    return this.props.t(`shared.video-types.${videoType}`);
  }

  translatePublisher(publisher) {
    if (!publisher) {
      return null;
    }

    return this.props.t('player.info-overlay.created-by', {
      author: publisher,
    });
  }

  render() {
    const {
      posterUrl,
      title,
      description,
      publisher,
      durationStr,
      durationAriaLabel,
      genre,
      videoType,
      cast,
      crew,
      isPlayerInFullScreen,
      isRTL,
    } = this.props;

    return (
      <Info
        title={title}
        description={description}
        posterUrl={posterUrl}
        durationStr={durationStr}
        durationAriaLabel={durationAriaLabel}
        publisher={this.translatePublisher(publisher)}
        genre={this.translateGenre(genre)}
        videoType={this.translateVideoType(videoType)}
        cast={cast}
        crew={this.translateCrew(crew)}
        crewTitle={this.props.t('player.info-overlay.crew')}
        castTitle={this.props.t('player.info-overlay.cast')}
        isInFullScreen={isPlayerInFullScreen}
        imageHost={PRODUCTION_HOSTS.image}
        isRTL={isRTL}
      />
    );
  }
}

export default withTranslation()(VideoInfo);
