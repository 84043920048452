import React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';

import {
  Picture,
  Breakpoint,
  ItemLoading,
} from '@wix/wix-vod-shared/components';
import { DollarIcon } from '@wix/wix-vod-shared/icons';
import { ContentProps } from './content/content';
import { LiveLabelProps } from './live-label/live-label';
import { ButtonsProps } from './buttons/buttons';

import styles from './thumbnail.scss';

const THUMBNAIL_RATIO = 9 / 16;

interface Props {
  overlayClassName: string;
  width: number;
  backgroundSrc?: string;
  backgroundUrl?: string;
  showLiveLabel?: boolean;
  showButtonsOnHover?: boolean;
  liveLabel: React.ReactElement<LiveLabelProps>;
  buttons: React.ReactElement<ButtonsProps>;
  content: React.ReactElement<ContentProps>;
  duration: string | React.ReactElement;
  onClick: () => void;
  isFocusable?: boolean;
  ariaLabel?: string;
  dataHook?: string;
  videoTitle?: string;
  isLoading?: boolean;
  isRTL?: boolean;
  isPaid?: boolean;
  useResponsiveThumbnail?: boolean;
  breakpoints?: Breakpoint[];
}

export const WIDTH_BREAKPOINTS = [275, 400, 500, 608, 700, 980];

export const getThumbnailMinWidthAttribute = (itemWidth: number) =>
  WIDTH_BREAKPOINTS.filter((breakpoint) => breakpoint <= itemWidth)
    .map((value) => `${value}px`)
    .join(' ');

export class Thumbnail extends React.Component<Props> {
  static displayName = 'Thumbnail';

  static defaultProps = {
    onClick: noop,
    dataHook: 'video-list-thumb-wrapper',
  };

  getCoverStyle() {
    return {
      ...this.getSize(),
      backgroundImage: `url(${this.props.backgroundUrl})`,
    };
  }

  getSize() {
    const { width } = this.props;

    if (width) {
      const height = width * THUMBNAIL_RATIO;
      return { width, height };
    }

    return {};
  }

  getMinWidthAttributte() {
    const { width } = this.props;

    if (width) {
      return {
        'data-thumbnail-min-width': getThumbnailMinWidthAttribute(width),
      };
    }

    return {};
  }

  renderLiveLabel() {
    const { width, showLiveLabel, liveLabel, isRTL } = this.props;

    if (!showLiveLabel) {
      return null;
    }

    const liveLabelStyles = classnames(styles['live-label'], {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return (
      <div className={liveLabelStyles}>
        {React.cloneElement(liveLabel, { thumbnailWidth: width })}
      </div>
    );
  }

  stopPropagation = (event: React.SyntheticEvent) => {
    event.stopPropagation();
  };

  renderButtons() {
    const { buttons, isRTL } = this.props;

    if (!buttons) {
      return null;
    }

    const buttonsStyles = classnames(styles.buttons, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return (
      <div onClick={this.stopPropagation} className={buttonsStyles}>
        {buttons}
      </div>
    );
  }

  getCoverClassName() {
    const { showLiveLabel, showButtonsOnHover, isRTL } = this.props;
    return classnames(styles.thumbnail, {
      [styles['show-live-label']]: showLiveLabel,
      [styles['buttons-on-hover']]: showButtonsOnHover,
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });
  }

  renderContent() {
    const { content, isRTL } = this.props;

    if (!content) {
      return null;
    }

    const contentStyle = classnames(styles.content, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return <div className={contentStyle}>{content}</div>;
  }

  renderDuration() {
    const { duration, isRTL } = this.props;

    if (!duration) {
      return null;
    }

    const durationStyles = classnames(styles.duration, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return <div className={durationStyles}>{duration}</div>;
  }

  render() {
    const {
      width,
      onClick,
      overlayClassName,
      isFocusable,
      isLoading,
      ariaLabel,
      dataHook,
      isRTL,
      isPaid,
      useResponsiveThumbnail,
      backgroundSrc,
      breakpoints,
      videoTitle,
    } = this.props;

    const containerStyle = classnames(styles.container, {
      [styles.loading]: isLoading,
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
      [styles.stretch]: Boolean(breakpoints),
    });

    const wrapperStyle = classnames(styles.wrapper, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const loaderStyle = classnames(styles.loader, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const overlayStyle = classnames(styles.overlay, overlayClassName, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
      [styles.absolute]: useResponsiveThumbnail,
    });

    const overlay = (
      <div className={overlayStyle}>
        {this.renderLiveLabel()}
        {this.renderButtons()}
        {this.renderDuration()}
        {isPaid && <DollarIcon className={styles.paidIcon} />}
      </div>
    );

    const pictureWidth = width || 320;

    const pictureBreakpoints = breakpoints || [
      {
        min: 0,
        width: pictureWidth,
        height: (pictureWidth / 16) * 9,
      },
    ];

    return (
      <div
        {...this.getMinWidthAttributte()}
        style={{ width }}
        className={containerStyle}
        onClick={onClick}
        data-hook={dataHook}
        aria-hidden={!isFocusable}
      >
        <div
          className={wrapperStyle}
          tabIndex={isFocusable ? 0 : -1}
          aria-label={ariaLabel}
        >
          {isLoading ? (
            <div className={loaderStyle} style={this.getSize()}>
              <ItemLoading active={isLoading} />
            </div>
          ) : (
            <div
              className={this.getCoverClassName()}
              data-hook="thumbnail-cover"
              style={useResponsiveThumbnail ? {} : this.getCoverStyle()}
            >
              {useResponsiveThumbnail ? (
                <Picture
                  alt={videoTitle}
                  src={backgroundSrc!}
                  breakpoints={pictureBreakpoints}
                >
                  {overlay}
                </Picture>
              ) : (
                overlay
              )}
            </div>
          )}

          {this.renderContent()}
        </div>
      </div>
    );
  }
}
