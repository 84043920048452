import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from '@wix/yoshi-flow-editor';
import classnames from 'classnames';
import { LinkButton } from '../buttons/buttons';
import Icon from '../icon/icon';
import { getCanShowChannelInfo } from '../../selectors/app-settings';
import { sendBiEvent } from '../../bi/send-bi-event';

import styles from './channel-actions.scss';

const mapStateToProps = (state) => ({
  isVisible: getCanShowChannelInfo(state),
});

const mapDispatchToProps = { sendBiEvent };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class ChannelInfoButton extends React.Component {
      static propTypes = {
        isVisible: PropTypes.bool,
        isFocusable: PropTypes.bool,
        channelData: PropTypes.object,
        className: PropTypes.string,
        onClick: PropTypes.func,
      };

      componentDidMount() {
        this.sendAboutInfoDisplayedBI();
      }

      componentDidUpdate() {
        this.sendAboutInfoDisplayedBI();
      }

      sendAboutInfoDisplayedBI() {
        if (this.props.isVisible) {
          this.doSendAboutInfoDisplayedBI();
        }
      }

      doSendAboutInfoDisplayedBI = _.once(() => {
        this.props.sendBiEvent('widget.aboutCh.displayed');
      });

      handleClick = (event) => {
        this.props.sendBiEvent('widget.aboutCh.clicked');
        this.props.onClick(event);
      };

      render() {
        const { className, isVisible, isFocusable } = this.props;

        if (!isVisible) {
          return null;
        }

        return (
          <LinkButton
            className={classnames(className, styles.action, styles.info)}
            dataHook="channel-info-button"
            isFocusable={isFocusable}
            ariaLabel={this.props.t('widget.accessibility.show-channel-info')}
            onClick={this.handleClick}
          >
            <Icon name="info-1" />
          </LinkButton>
        );
      }
    },
  ),
);
