import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from '@wix/yoshi-flow-editor';

import { isShareOverlayOpen } from '../../selectors/share-overlay';
import {
  toggleShareChannelOverlay,
  toggleShareVideoOverlay,
} from '../../redux/actions/share-overlay';
import { canShowChannelShare } from '../../selectors/app-settings';
import { canShareVideo } from '../../selectors/video-share';

const mapStateToProps = (state) => ({
  isOpen: isShareOverlayOpen(state),
  canShowVideoShare: canShareVideo(state),
  canShowChannelShare: canShowChannelShare(state),
});

const mapDispatchToProps = {
  toggleShareChannelOverlay,
  toggleShareVideoOverlay,
};

const OpenShareOverlay = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class OpenShareOverlay extends React.Component {
    static propTypes = {
      isOpen: PropTypes.bool,
      isFocusable: PropTypes.bool,
      canShowVideoShare: PropTypes.bool,
      canShowChannelShare: PropTypes.bool,
      toggleShareChannelOverlay: PropTypes.func,
      toggleShareVideoOverlay: PropTypes.func,
      children: PropTypes.func,
    };

    static defaultProps = {
      isFocusable: true,
    };

    toggleShare = () => {
      const { canShowChannelShare } = this.props;
      if (canShowChannelShare) {
        this.props.toggleShareChannelOverlay();
      } else {
        this.props.toggleShareVideoOverlay();
      }
    };

    render() {
      const { isFocusable, isOpen, children, canShowChannelShare } = this.props;

      // TODO: ask Yuriy, do we really need to hide tabindex

      if (!canShowChannelShare) {
        return null;
      }

      return (
        children &&
        children({
          toggleShare: this.toggleShare,
          tabIndex: !isFocusable || isOpen ? -1 : 0,
          ariaLabel: this.props.t('widget.accessibility.share'),
        })
      );
    }
  },
);

export default withTranslation()(OpenShareOverlay);
