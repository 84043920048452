import { ItemType } from '../../redux/types';
import {
  BI_VIDEO_TYPE,
  isLiveVideo,
  isVideoOverQuota,
} from '@wix/wix-vod-shared/common';
import { convertSToMS } from '@wix/wix-vod-shared/widget';
import { logBi } from '../../worker/actions/bi';
import { isAutoplayed } from '../../selectors/player';

const getVideoBISource = (videoItem) => {
  if (isLiveVideo(videoItem)) {
    switch (videoItem.itemType) {
      case ItemType.ItemScheduled:
        return BI_VIDEO_TYPE.SCHEDULED;
      case ItemType.ItemLive:
        return BI_VIDEO_TYPE.LIVE;
      default:
        break;
    }
  }

  return videoItem.videoSource;
};

export const logWidgetVidClick =
  ({ videoItem, channelData, buttonName = 'play_video' }) =>
  (dispatch) => {
    const { id, durationSec } = videoItem;
    const videoBiSource = getVideoBISource(videoItem);

    dispatch(
      logBi('widget.vid.clicked', {
        duration: convertSToMS(durationSec),
        channelID: channelData.id,
        videoType: videoBiSource,
        videoID: id,
        overQuoted: isVideoOverQuota(videoItem),
        buttonName,
      }),
    );
  };

export const logWidgetVidPlayStart =
  ({ channelData, videoItem, button }) =>
  (dispatch, getState) => {
    const isAutoplay = isAutoplayed(getState());
    const { id, durationSec } = videoItem;
    const videoBiSource = getVideoBISource(videoItem);

    dispatch(
      logBi('widget.vid.start.play', {
        duration: convertSToMS(durationSec),
        channelID: channelData.id,
        videoType: videoBiSource,
        videoID: id,
        isAutoplay,
        button,
      }),
    );
  };
