import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import styles from './not-available-overlay.scss';

const NotAvailableOverlay: FC<{ width?: number; height?: number }> = ({
  width,
  height,
}) => {
  const style = { width, height };
  const { t } = useTranslation();

  return (
    <div
      data-hook="video-not-avalilable-overlay"
      className={styles.overlay}
      style={style}
    >
      <div className={styles.title}>{t('widget.video-is-not-available')}</div>
      <div className={styles.text}>{t('widget.check-again-later')}</div>
    </div>
  );
};

NotAvailableOverlay.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default NotAvailableOverlay;
