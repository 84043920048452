import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { logBi } from '../../worker/actions/bi';
import {
  areElementsRounded,
  getCanShowChannelTags,
} from '../../selectors/app-settings';
import TagsCloud from '../../components/tags-cloud/tags-cloud';
import styles from './tags.scss';
import { isMobile } from '../../selectors/form-factor';

const mapStateToProps = (state) => ({
  isMobile: isMobile(state),
  isVisible: getCanShowChannelTags(state),
  isRounded: areElementsRounded(state),
});

const mapDispatchToProps = { logBi };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class Tags extends React.Component {
    static propTypes = {
      tags: PropTypes.array,
      className: PropTypes.string,
      tagClassName: PropTypes.string,
      roundedTagClassName: PropTypes.string,
      onTagFocus: PropTypes.func,
      onTagClick: PropTypes.func,
      onTagBlur: PropTypes.func,
      isVisible: PropTypes.bool,
      isRounded: PropTypes.bool,
      isMobile: PropTypes.bool.isRequired,
    };

    static defaultProps = {
      onTagClick: _.noop,
    };

    getTagList() {
      return _.map(this.props.tags || [], 'value');
    }

    sendTagSelectedBI() {
      this.props.logBi('widget.tags.selected');
    }

    onTagClick = (tag) => {
      this.sendTagSelectedBI();
      this.props.onTagClick(tag);
    };

    render() {
      const {
        className,
        tagClassName,
        roundedTagClassName,
        isVisible,
        isRounded,
        onTagFocus,
        onTagBlur,
        isMobile,
      } = this.props;

      if (!isVisible) {
        return null;
      }

      const tagClassNames = classnames(styles.tag, tagClassName, {
        [styles.mobile]: isMobile,
      });

      return (
        <TagsCloud
          className={className}
          tagClassName={tagClassNames}
          isRounded={isRounded}
          roundedTagClassName={roundedTagClassName}
          tags={this.getTagList()}
          onTagBlur={onTagBlur}
          onTagFocus={onTagFocus}
          onTagClick={this.onTagClick}
        />
      );
    }
  },
);
