import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import pubsub from '../../utils/pubsub';
import { byPopoutSide } from '../../utils/popout/calculate-position';

import events from '../../constants/events';
import {
  sides,
  popoutPositions,
  trianglePositions,
} from '../../constants/popout';
import Popout from '../popout/popout';

import styles from './tooltip.scss';

const HIDE_TIMEOUT = 200;
let hideTimeoutId;

export function showTooltip(config) {
  pubsub.publish(events.TOOLTIP.SHOW, config);
}

export function hideTooltip() {
  pubsub.publish(events.TOOLTIP.HIDE);
}

export default class TooltipPopout extends React.Component {
  state = {
    popoutComponent: Popout,
    visible: false,
  };

  componentDidMount() {
    this.showTooltipUnsubscribe = pubsub.subscribe(
      events.TOOLTIP.SHOW,
      this.showTooltip,
    );
    this.hideTooltipUnsubscribe = pubsub.subscribe(
      events.TOOLTIP.HIDE,
      this.hideTooltip,
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { visible, tooltipId } = this.state;

    if (visible && !_.isEqual(this.state, prevState)) {
      // update position if popout is visible
      this.updatePosition();
    }

    if (visible !== prevState.visible) {
      const eventId = visible
        ? events.TOOLTIP.DID_SHOW
        : events.TOOLTIP.DID_HIDE;

      pubsub.publish(eventId, tooltipId);
    }
  }

  componentWillUnmount() {
    this.showTooltipUnsubscribe();
    this.hideTooltipUnsubscribe();
  }

  get Popout() {
    return this.state.popoutComponent;
  }

  showTooltip = (config) => {
    if (config.compId !== this.props.compId) {
      return;
    }

    this.preventHide();

    this.setState({
      visible: true,

      preventHideOnHover: false,
      popoutComponent: Popout,
      className: '',
      innerClassName: '',
      triangleClassName: '',
      content: null,
      popoutSide: sides.RIGHT,
      popoutPosition: popoutPositions.CENTER,
      trianglePosition: trianglePositions.CENTER,
      referenceElement: null,

      ...config,
    });
  };

  hideTooltip = () => {
    const timeout = this.state.preventHideOnHover ? HIDE_TIMEOUT : 0;

    hideTimeoutId = setTimeout(() => {
      this.setState({
        visible: false,
      });
    }, timeout);
  };

  preventHide() {
    clearTimeout(hideTimeoutId);
  }

  handleMouseEnter = () => {
    if (this.state.preventHideOnHover) {
      this.preventHide();
    }
  };

  handleMouseLeave = () => {
    this.hideTooltip();
  };

  calculatePosition() {
    const { referenceElement, popoutSide, popoutPosition, trianglePosition } =
      this.state;

    return byPopoutSide(referenceElement, this.popout, {
      popoutSide,
      popoutPosition,
      trianglePosition,
    });
  }

  updatePosition() {
    const { top, left, offsetX } = this.calculatePosition();

    this.popout.style.top = `${top}px`;
    this.popout.style.left = `${left}px`;

    const transformStyle = `translateX(${offsetX}px) rotate(45deg)`;
    const before = this.popout.firstElementChild;
    const after = this.popout.lastElementChild;

    before.style.transform = transformStyle;
    after.style.transform = transformStyle;
  }

  getPopoutRef = (node) => {
    this.popout = node;
  };

  render() {
    const {
      visible,
      content,
      left,
      top,
      className,
      innerClassName,
      triangleClassName,
      popoutSide,
      popoutPosition,
      trianglePosition,
      onClickOutside,
    } = this.state;

    const classNames = classnames(className, styles.popout, {
      [styles.positioned]: visible,
    });
    const innerClassNames = classnames(styles['popout-inner'], innerClassName);

    return (
      <this.Popout
        getRef={this.getPopoutRef}
        onClickOutside={onClickOutside}
        popoutSide={popoutSide}
        popoutPosition={popoutPosition}
        trianglePosition={trianglePosition}
        className={classNames}
        innerClassName={innerClassNames}
        triangleClassName={triangleClassName}
        style={{ left, top }}
        isActive={visible}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {content}
      </this.Popout>
    );
  }
}
