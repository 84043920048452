import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { canShowActionBar } from '../../selectors/layout';
import { canShowChannelCategories } from '../../selectors/categories';
import CategoriesDropdown from './categories-dropdown/categories-dropdown';
import ChannelActionsContainer from '../../containers/channel-actions/channel-actions';
import Menu from './menu/menu';
import styles from './action-bar.scss';

const mapStateToProps = (state) => ({
  canShowActionBar: canShowActionBar(state),
  canShowChannelCategories: canShowChannelCategories(state),
});

export default connect(mapStateToProps)(
  class ActionBar extends React.Component {
    static propTypes = {
      canShowChannelCategories: PropTypes.bool,
      isResponsive: PropTypes.bool,
      className: PropTypes.string,
      channelData: PropTypes.object.isRequired,
      onPageRefresh: PropTypes.func.isRequired,
      canShowActionBar: PropTypes.bool.isRequired,
      style: PropTypes.object,
    };

    static defaultProps = {
      onPageRefresh: _.noop,
    };

    renderChannelMenu() {
      const { channelData, onPageRefresh } = this.props;

      return (
        <ChannelActionsContainer
          channelId={channelData.id}
          onPageRefresh={onPageRefresh}
        >
          {(channelActions) => (
            <Menu
              channel={channelData}
              onLogInRequest={channelActions.logIn}
              onShowAccountInfoRequest={channelActions.showAccountInfo}
              onSubscriptionRequest={channelActions.subscribe}
              onCancelSubscriptionRequest={channelActions.cancelSubscription}
              onInfoRequest={channelActions.showInfo}
              onShareRequest={channelActions.showShare}
            />
          )}
        </ChannelActionsContainer>
      );
    }

    render() {
      const {
        style,
        className,
        canShowChannelCategories,
        canShowActionBar,
        isResponsive,
      } = this.props;

      if (!canShowActionBar) {
        return null;
      }

      return (
        <section
          data-hook="action-bar"
          className={classnames(styles.container, className, {
            [styles.responsive]: isResponsive,
          })}
          style={style}
        >
          <div className={styles.menu}>{this.renderChannelMenu()}</div>

          {canShowChannelCategories && (
            <CategoriesDropdown className={styles.categories} />
          )}
        </section>
      );
    }
  },
);
