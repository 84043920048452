import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { ContainerQuery } from 'react-container-query';

import {
  memoizedPartial,
  isLiveVideo,
  getChannelForWidget,
} from '@wix/wix-vod-shared/common';
import { CurrencySign } from '@wix/wix-vod-shared/components';
import { callOnEnterPressExactOnElement } from '../../utils/call-on-keyboard-press';

import { createGetPlayButtonConfig } from '../../layouts/compact/components/player-overlay/ui-selectors/video-overlay/play/button-config';
import { createGetPaidAccessButtonConfig } from '../../layouts/compact/components/player-overlay/ui-selectors/video-overlay/paid-access/button-config';
import { isVideoPlayingOptimistic } from '../../selectors/video-playback-status';
import {
  canShowVideoListItemTitle,
  canShowVideoListItemPublisher,
  isClassicLayout,
} from '../../selectors/app-settings';

import LiveLabel from '../../containers/live-label/live-label';
import Title from '../../layouts/compact/components/player-overlay/partials/title';
import Publisher from '../../layouts/compact/components/player-overlay/partials/publisher';
import PlayButton from '../../layouts/compact/components/player-overlay/partials/play-button';
import VideoCover from '../video-cover/video-cover';

import styles from './video-thumbnail-overlay.scss';
import { getCurrency } from '../../selectors/currency';

// TODO: replace `getPlayButtonConfig` and `getPaidAccessButtonConfig` with `getPlayButtonText` approach
// TODO: add `currentSiteUser` selector to `@connect` and remove it from parent components props
// see https://github.com/wix-private/wix-vod/pull/2003#discussion_r148538134 for details

const containerQuery = {
  [styles['element-max-199']]: {
    maxWidth: 199,
  },
  [styles['element-max-249']]: {
    minWidth: 200,
    maxWidth: 249,
  },
};

const mapStateToProps = () => {
  const getPlayButtonConfig = createGetPlayButtonConfig();
  const getPaidAccessButtonConfig = createGetPaidAccessButtonConfig();
  return (state, ownProps) => ({
    playButtonConfig: getPlayButtonConfig(state, ownProps),
    paidAccessButtonConfig: getPaidAccessButtonConfig(state, ownProps),
    isLive: isLiveVideo(ownProps.videoItem),
    isPlaying: ownProps.isSelected && isVideoPlayingOptimistic(state),
    isShowVideoListItemTitle: canShowVideoListItemTitle(state),
    isShowVideoListItemPublisher: canShowVideoListItemPublisher(state),
    isClassicLayout: isClassicLayout(state),
    channel: getChannelForWidget(state),
  });
};

export default withTranslation()(
  connect(mapStateToProps)(
    class VideoThumbnailOverlay extends React.Component {
      static propTypes = {
        channel: PropTypes.object.isRequired,
        videoItem: PropTypes.object.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,

        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isInfoAlwaysShown: PropTypes.bool.isRequired,
        isInfoShownOnHover: PropTypes.bool.isRequired,
        isSelected: PropTypes.bool.isRequired,
        isPlaying: PropTypes.bool.isRequired,
        isLive: PropTypes.bool,

        onClick: PropTypes.func.isRequired,
        onPlayRequest: PropTypes.func.isRequired,
        onPlayMemberOnlyRequest: PropTypes.func.isRequired,
        onPlayWithoutPreviewRequest: PropTypes.func.isRequired,
        onPurchaseRequest: PropTypes.func.isRequired,
        onRentRequest: PropTypes.func.isRequired,
        onSubscriptionRequest: PropTypes.func.isRequired,

        className: PropTypes.string,
        currentSiteUser: PropTypes.object,
        playButtonConfig: PropTypes.object,
        paidAccessButtonConfig: PropTypes.object,

        withFallbackColor: PropTypes.bool,
        isContentFocusable: PropTypes.bool,
        isDescriptionBelow: PropTypes.bool,
        isShowVideoListItemTitle: PropTypes.bool,
        isShowVideoListItemPublisher: PropTypes.bool,
        isClassicLayout: PropTypes.bool,
      };

      static defaultProps = {
        isContentFocusable: true,
        isDescriptionBelow: false,
        isShowVideoListItemTitle: true,
        isShowVideoListItemPublisher: true,
      };

      renderTitle() {
        const {
          videoItem,
          isPlaying,
          isDescriptionBelow,
          isShowVideoListItemTitle,
          isClassicLayout,
        } = this.props;

        return isClassicLayout && !isShowVideoListItemTitle ? null : (
          <Title
            className={styles.title}
            maxLinesCount={isDescriptionBelow ? 1 : 2}
            ariaHidden={isPlaying}
          >
            {videoItem.title}
          </Title>
        );
      }

      renderPublisher() {
        const {
          videoItem,
          isPlaying,
          isClassicLayout,
          isShowVideoListItemPublisher,
        } = this.props;

        return isClassicLayout && !isShowVideoListItemPublisher ? null : (
          <Publisher className={styles.publisher} ariaHidden={isPlaying}>
            {videoItem.publisher}
          </Publisher>
        );
      }

      renderDescription() {
        const title = this.renderTitle();
        const publisher = this.renderPublisher();
        if (!title && !publisher) {
          return null;
        }

        return (
          <div className={styles.description}>
            {title}
            {publisher}
          </div>
        );
      }

      renderPlayButton() {
        const { playButtonConfig, isContentFocusable, isPlaying } = this.props;

        if (!playButtonConfig) {
          return null;
        }

        return (
          <PlayButton
            className={styles['play-button']}
            iconClassName={styles['play-icon']}
            onClick={playButtonConfig.callback}
            isFocusable={isContentFocusable}
            ariaHidden={isPlaying}
            isIconOnly
          >
            {/* for accessibility only*/}
            {this.props.t(playButtonConfig.translationData.props)}
          </PlayButton>
        );
      }

      renderPaidAccessIcon() {
        const { paidAccessButtonConfig, isPlaying, videoItem, channel } =
          this.props;

        if (!paidAccessButtonConfig) {
          return null;
        }

        const currency = getCurrency(videoItem, channel);

        return (
          <div
            className={styles['paid-access-icon']}
            aria-hidden={isPlaying}
            data-hook="paid-icon"
          >
            <CurrencySign currency={currency} />
          </div>
        );
      }

      renderPlayingIndication() {
        const { isPlaying } = this.props;

        return (
          <div
            className={styles['now-playing']}
            tabIndex={-1}
            aria-hidden={!isPlaying}
          >
            {this.props.t('thumbnail.now-playing')}
          </div>
        );
      }

      renderDuration() {
        const {
          isLive,
          videoItem: { durationStr, isPlaying },
        } = this.props;

        if (!durationStr || isLive) {
          return null;
        }

        return (
          <div
            className={styles.duration}
            role="timer"
            aria-hidden={isPlaying}
            aria-label={this.props.t('widget.accessibility.duration', {
              durationStr,
            })}
          >
            {durationStr}
          </div>
        );
      }

      renderLiveLabel() {
        const { videoItem } = this.props;

        return (
          <LiveLabel
            itemType={videoItem.itemType}
            liveVideoStatus={videoItem.liveVideoStatus}
            startTime={videoItem.dateStartLive}
            isSmall
            className={styles['live-label']}
            smallClassName={styles['live-scheduled-label']}
          />
        );
      }

      renderOverlay = (queryClasses) => {
        const {
          videoItem,
          className,
          isInfoAlwaysShown,
          isDescriptionBelow,
          isInfoShownOnHover,
          isThumbnailsPreviewHover,
          isSelected,
          isPlaying,
          width,
          height,
          isContentFocusable,
          onClick,
          isLive,
        } = this.props;

        if (!videoItem) {
          return null;
        }

        const classNames = classnames(styles.container, className, {
          [styles['info-visible']]: isInfoAlwaysShown,
          [styles['info-when-hover']]: isInfoShownOnHover,
          [styles['description-below']]: isDescriptionBelow,
          [styles['preview-hover']]: isThumbnailsPreviewHover,
          [styles.selected]: isSelected,
          [styles.playing]: isPlaying,
          ...queryClasses,
        });

        const thumbClassNames = classnames(styles.thumbnail, {
          [styles.live]: isLive,
        });

        const dataHook = classnames('video-list-thumb-wrapper', {
          'video-list-thumb-wrapper-selected': isSelected,
        });

        return (
          <section
            className={classNames}
            onClick={onClick}
            data-hook={dataHook}
            style={{ width }}
          >
            <div
              className={thumbClassNames}
              style={{ height, width }}
              onKeyDown={memoizedPartial(
                callOnEnterPressExactOnElement,
                onClick,
              )}
              tabIndex={isContentFocusable ? 0 : -1}
              aria-hidden={!isContentFocusable}
            >
              <VideoCover
                className={styles.cover}
                videoItem={videoItem}
                breakpoints={[{ min: 0, width, height }]}
                withFallbackColor
              />
              <div className={styles.content}>
                {this.renderPlayingIndication()}
                {/* without publisher - is by design */}
                {!isDescriptionBelow && this.renderTitle()}
                {this.renderPaidAccessIcon()}
                {this.renderDuration()}
                {this.renderLiveLabel()}
              </div>
            </div>
            {this.renderPlayButton()}

            {isDescriptionBelow && this.renderDescription()}
          </section>
        );
      };

      render() {
        return (
          <ContainerQuery query={containerQuery}>
            {this.renderOverlay}
          </ContainerQuery>
        );
      }
    },
  ),
);
