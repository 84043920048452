export function getSlideWidth(
  width,
  slidesToShow,
  { slidesPreviewWidth = 0 } = {},
) {
  return (width - 2 * slidesPreviewWidth) / slidesToShow;
}

export function getTrackLeft(
  slidesCount,
  slidesPerPage,
  slideIndex,
  slideWidth,
  slidesPreviewWidth,
) {
  if (slideIndex === 0) {
    return 0;
  }

  if (slideIndex >= slidesCount - slidesPerPage) {
    return slideIndex * slideWidth * -1 + 2 * slidesPreviewWidth;
  }

  return slideIndex * slideWidth * -1 + slidesPreviewWidth;
}
