import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';

import BackIcon from './back.svg';

import styles from './header.scss';

const Header = ({ dataHook, onBackButtonClick, t }) => (
  <div data-hook={dataHook} className={styles.title} dir="ltr">
    <a
      href="#"
      data-hook="back-button"
      className={styles['back-button']}
      onClick={onBackButtonClick}
      aria-label={t('widget.accessibility.close-embed-form')}
    >
      <BackIcon />
    </a>

    <span className={styles['title-text']}>
      {t('share-overlay.embed.video.title')}
    </span>
  </div>
);

Header.propTypes = {
  dataHook: PropTypes.string,
  onBackButtonClick: PropTypes.func,
};

export default withTranslation()(Header);
