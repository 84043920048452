import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { isResponsiveEditor } from '../selectors/app-settings';
import { isEditorMode } from '../selectors/view-mode';
import { DesktopView } from '../views/desktop/desktop';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/app-settings';
import strip from './strip/strip';
import classic from './classic/classic';
import compact from './compact/compact';
import slider from './slider/slider';
import grid from './grid/grid';

const layoutLoaderMap = {
  [CHANNEL_LAYOUT_VALUES.STRIP]: strip,
  [CHANNEL_LAYOUT_VALUES.CLASSIC]: classic,
  [CHANNEL_LAYOUT_VALUES.COMPACT]: compact,
  [CHANNEL_LAYOUT_VALUES.SLIDER_SMALL]: slider,
  [CHANNEL_LAYOUT_VALUES.SLIDER_BIG]: slider,
  [CHANNEL_LAYOUT_VALUES.GRID]: grid,
};

const getLayoutComponent = (id, isResponsive) =>
  isResponsive ? slider : layoutLoaderMap[id];

function getLayoutId(props) {
  return _.get(props.appSettings, 'numbers.channelLayout');
}

const mapStateToProps = (state) => ({
  appSettings: state.appSettings,
  isResponsiveEditor: isResponsiveEditor(state),
  isEditorMode: isEditorMode(state),
});

export default connect(mapStateToProps)((props) => {
  const layoutId = getLayoutId(props);
  const Layout = getLayoutComponent(layoutId, props.isResponsiveEditor);

  return (
    <DesktopView>
      <Layout />
    </DesktopView>
  );
});
