import { t } from '../../../utils/translation';

import {
  getFullAccessActionName,
  FULL_ACCESS_ACTION_NAMES,
} from '@wix/wix-vod-shared/widget';

/**
 * Is channel sign in to play button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @return {Boolean}
 */
export function isVisible({ channel, video, currentSiteUser }) {
  return (
    getFullAccessActionName(channel, video, currentSiteUser) ===
    FULL_ACCESS_ACTION_NAMES.SIGN_IN
  );
}

/**
 * Channel sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.video {Object}
 * @param props.translation {Object} default is sharedTranslations.playVideo
 * @return {React.Component}
 */
export default function renderSignInToWatchButtonText(props) {
  if (!isVisible(props)) {
    return null;
  }

  const translation = props.translation || 'widget.overlay-buttons.play-video';
  return t(translation);
}
