import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import styles from './navigation-dots.scss';

const DOT_CONTAINER_SIZE = 12;
const MAIN_DOTS_COUNT = 3;
const isStart = (selectedIndex) => selectedIndex < MAIN_DOTS_COUNT;
const isEnd = (selectedIndex, count) =>
  count - selectedIndex < MAIN_DOTS_COUNT + 1;

const mapStateToProps = (state, props) => ({
  hasMainDotsOnly: props.count <= MAIN_DOTS_COUNT,
});

export default connect(mapStateToProps)(
  class NavigationDots extends React.Component {
    static propTypes = {
      count: PropTypes.number,
      selectedIndex: PropTypes.number,

      hasMainDotsOnly: PropTypes.bool,
      isRTL: PropTypes.bool,
    };

    static defaultProps = {
      count: 0,
      selectedIndex: 0,
    };

    constructor(props) {
      super(props);

      const isDirectedRight = props.selectedIndex < MAIN_DOTS_COUNT;
      this.state = {
        cssLeft: this.getInitialCssLeft(props, isDirectedRight),
        needDotsRecalc: true,
        isDirectedRight,
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { selectedIndex, hasMainDotsOnly } = nextProps;
      const isIndexChanged =
        nextProps.selectedIndex !== this.props.selectedIndex;
      const isCountChanged = nextProps.count !== this.props.count;
      if (hasMainDotsOnly) {
        return;
      }

      if (isIndexChanged || isCountChanged) {
        const isDirectedRight = isCountChanged
          ? true
          : nextProps.selectedIndex > this.props.selectedIndex;
        const needDotsRecalc = isCountChanged
          ? true
          : !this.dots || this.isSmallDotBecomingActive(selectedIndex);
        const cssLeft = needDotsRecalc
          ? this.getMove(nextProps.selectedIndex)
          : this.state.cssLeft;

        this.setState({
          needDotsRecalc,
          isDirectedRight,
          cssLeft,
        });
      }
    }

    componentDidUpdate() {
      const { cssLeft } = this.state;
      const { hasMainDotsOnly, isRTL } = this.props;
      if (!hasMainDotsOnly) {
        const direction = isRTL ? 'right' : 'left';
        this.dotsNode.style[direction] = `${cssLeft}px`;
      }
    }

    getInitialCssLeft(
      props = this.props,
      isDirectedRight = this.state.isDirectedRight,
    ) {
      const { selectedIndex, count, hasMainDotsOnly } = props;
      const initialStartCssLeft = 2 * DOT_CONTAINER_SIZE;
      const initialEndCssLeft = DOT_CONTAINER_SIZE * (5 - count);
      const startDotIndex = 0;
      const endDotIndex = count - 3;

      if (hasMainDotsOnly) {
        return -0.5 * count * DOT_CONTAINER_SIZE;
      }
      if (isStart(selectedIndex)) {
        return initialStartCssLeft;
      }
      if (isEnd(selectedIndex, count)) {
        return initialEndCssLeft;
      }

      return isDirectedRight
        ? initialStartCssLeft +
            DOT_CONTAINER_SIZE * (startDotIndex - selectedIndex)
        : initialEndCssLeft +
            DOT_CONTAINER_SIZE * (endDotIndex - selectedIndex);
    }

    renderDot(dot, index) {
      const className = classnames(styles.dot, {
        [styles.large]: dot.large,
        [styles.near]: dot.near,
        [styles.active]: dot.active,
      });

      return (
        <div className={styles['dot-wrapper']} key={index}>
          <div className={className} />
        </div>
      );
    }

    isSmallDotBecomingActive(selectedIndex) {
      return !_.get(this.dots[selectedIndex], 'large');
    }

    renderSimplifiedDots() {
      const { count, selectedIndex, isRTL } = this.props;
      const dots = _.times(count, () => ({ near: true, large: true }));
      _.nth(dots, selectedIndex).active = true;
      const className = classnames(styles.dots, styles.simplified);
      const direction = isRTL ? 'right' : 'left';
      const style = { [direction]: this.getInitialCssLeft() };

      return (
        <div data-hook="navigation-dots" className={className} style={style}>
          {_.map(dots, this.renderDot)}
        </div>
      );
    }

    getAllDots() {
      const { selectedIndex, count } = this.props;
      const { isDirectedRight } = this.state;
      let dots = _.times(count, () => ({}));

      if (isDirectedRight) {
        if (isStart(selectedIndex)) {
          for (let i = 0; i < MAIN_DOTS_COUNT; i += 1) {
            dots[i].large = true;
          }
        } else {
          for (let i = 0; i < MAIN_DOTS_COUNT; i += 1) {
            dots[selectedIndex - i].large = true;
          }
        }
      } else if (isEnd(selectedIndex, count)) {
        for (let i = 1; i < MAIN_DOTS_COUNT + 1; i += 1) {
          dots[count - i].large = true;
        }
      } else {
        for (let i = 0; i < MAIN_DOTS_COUNT; i += 1) {
          dots[selectedIndex + i].large = true;
        }
      }

      const firstLargeIndex = _.findIndex(dots, 'large');
      const nearDotsIndexes = _.times(
        MAIN_DOTS_COUNT + 2,
        (index) => firstLargeIndex + index - 1,
      );
      dots = _.map(dots, (dot, index) =>
        _.includes(nearDotsIndexes, index) ? { ...dot, near: true } : dot,
      );
      return dots;
    }

    getMove = (nextSelectedIndex) => {
      return (
        this.state.cssLeft +
        DOT_CONTAINER_SIZE * (this.props.selectedIndex - nextSelectedIndex)
      );
    };

    withActiveDotUpdated(dots) {
      const { selectedIndex } = this.props;

      return _.map(dots, (dot, index) => {
        dot.active = index === selectedIndex;
        return dot;
      });
    }

    getDots() {
      const { needDotsRecalc } = this.state;

      let dots = needDotsRecalc ? this.getAllDots() : this.dots;
      dots = this.withActiveDotUpdated(dots);

      this.dots = dots;
      return dots;
    }

    saveRef = (node) => {
      const { isRTL } = this.props;
      if (node) {
        this.dotsNode = node;
        const direction = isRTL ? 'right' : 'left';
        this.dotsNode.style[direction] = `${this.state.cssLeft}px`;
      }
    };

    render() {
      const { hasMainDotsOnly, count } = this.props;

      if (count === 0) {
        return null;
      }

      return hasMainDotsOnly ? (
        this.renderSimplifiedDots()
      ) : (
        <div className={styles['dots-visible-window']}>
          <div
            ref={this.saveRef}
            className={styles.dots}
            data-hook="navigation-dots"
          >
            {_.map(this.getDots(), this.renderDot)}
          </div>
        </div>
      );
    }
  },
);
