import React from 'react';
import { Spinner } from '@wix/wix-vod-shared/components';
import styles from './mobile-spinner-overlay.scss';

const MobileSpinnerOverlay = () => (
  <div className={styles['spinner-container']}>
    <Spinner className={styles.spinner} />
  </div>
);

export default MobileSpinnerOverlay;
