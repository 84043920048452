import React, { useContext } from 'react';
import { WidgetProps } from '../index';

const WidgetPropsContext = React.createContext<WidgetProps | undefined>(
  undefined,
);

export const WidgetPropsProvider = WidgetPropsContext.Provider;

export function withWidgetProps(
  selector: (props: WidgetProps) => { [key: string]: any },
) {
  return (Component: React.JSXElementConstructor<any>) => (props: any) =>
    (
      <WidgetPropsContext.Consumer>
        {(widgetProps) =>
          widgetProps ? (
            <Component {...props} {...selector(widgetProps)} />
          ) : null
        }
      </WidgetPropsContext.Consumer>
    );
}

export const useWidgetProps = () => {
  const widgetProps = useContext(WidgetPropsContext);

  if (!widgetProps) {
    throw new Error('useWidgetProps must be used within WidgetPropsProvider');
  }

  return widgetProps;
};
