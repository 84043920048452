import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import classnames from 'classnames';

import { memoizedPartial } from '@wix/wix-vod-shared/common';
import { onEscPress } from '../../utils/call-on-keyboard-press';
import { connect } from 'react-redux';

import { logBi } from '../../worker/actions/bi';

import Icon from '../icon/icon';
import { LinkButton } from '../buttons/buttons';

import styles from './search-bar.scss';

const mapStateToProps = null;
const mapDispatchToProps = { logBi };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class SearchBar extends React.Component {
      static propTypes = {
        onChange: PropTypes.func,
        onSearch: PropTypes.func,
        onClose: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        className: PropTypes.string,
        value: PropTypes.string,
        layout: PropTypes.string,
      };

      static defaultProps = {
        layout: 'classic',
        value: '',
        onChange: _.noop,
        onSearch: _.noop,
        onClose: _.noop,
        onFocus: _.noop,
        onBlur: _.noop,
        onKeyPress: _.noop,
      };

      constructor(props) {
        super(props);

        this.state = {
          currentValue: props.value,
          focused: false,
        };
      }

      componentDidMount() {
        document.addEventListener(
          'keyup',
          memoizedPartial(onEscPress, this.closeSearch),
        );
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
          this.setState({ currentValue: nextProps.value });
        }
      }

      componentWillUnmount() {
        document.removeEventListener(
          'keyup',
          memoizedPartial(onEscPress, this.closeSearch),
        );
      }

      updateValue = () => {
        const { value } = this.input;
        this.props.onChange(value);
        this.setState({ currentValue: value });
      };

      searchOnEnter = (event) => {
        const { keyCode } = event.nativeEvent;
        const { value } = this.input;

        // check if Enter
        if (keyCode === 13) {
          this.sendSearchBI(value);
          this.permitSearch();
        }
      };

      sendSearchBI(searchQuery) {
        if (searchQuery !== '') {
          this.props.logBi('widget.search.clicked');
        }
      }

      setFocused(focused) {
        // Don`t hide if click outside with new value passed to props
        setTimeout(() => this.setState({ focused }), 0);
      }

      permitSearch = () => {
        const { onSearch } = this.props;
        const { value } = this.input;
        this.setFocused(true);

        onSearch(value);
      };

      closeSearch = () => {
        this.input.value = '';
        this.input.blur();
        this.setState({
          currentValue: '',
          focused: false,
        });

        this.props.onClose('');
      };

      handleFocus = (event) => {
        this.props.onFocus(event);
        this.setFocused(true);
      };

      handleBlur = (event) => {
        this.props.onBlur(event);
        this.setFocused(false);
      };

      render() {
        const { className, value, layout } = this.props;
        const { currentValue, focused } = this.state;
        const placeholder = this.props.t('widget.search-bar.search-video');

        const containerClassName = classnames(styles.container, {
          [styles.strip]: layout === 'strip',
          [styles['has-value']]: currentValue,
          [styles['has-submitted-value']]: value,
          [styles.changed]: value !== currentValue,
          [styles.focused]: focused,
          className,
        });

        return (
          <div className={classnames(className, containerClassName)}>
            <div className={styles['background-text']}>{placeholder}</div>
            <input
              type="text"
              className={styles.input}
              data-hook="search-bar-input"
              value={currentValue}
              onKeyPress={this.searchOnEnter}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              onChange={this.updateValue}
              ref={(node) => (this.input = node)}
              placeholder={placeholder}
            />

            <Icon className={styles['search-icon']} name="search-bar" />
            <LinkButton
              className={styles['close-search']}
              onClick={this.closeSearch}
              aria-hidden={!currentValue}
              isFocusable={Boolean(currentValue || value)}
              ariaLabel={this.props.t(
                'widget.accessibility.close-videos-search',
              )}
            >
              <Icon name="clear-search" />
            </LinkButton>
            <LinkButton
              className={styles['permit-search']}
              onClick={this.permitSearch}
              isFocusable={Boolean(currentValue || value)}
              ariaLabel={this.props.t('widget.accessibility.search-videos')}
            >
              <Icon name="permit-search" />
            </LinkButton>
          </div>
        );
      }
    },
  ),
);
