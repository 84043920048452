import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/widget';

export default {
  [FULL_ACCESS_ACTION_NAMES.BUY]: 'widget.overlay-buttons.buy',
  [FULL_ACCESS_ACTION_NAMES.RENT]: 'widget.overlay-buttons.rent',
  [FULL_ACCESS_ACTION_NAMES.BUY_OR_RENT]:
    'widget.overlay-buttons.buy-from-small',
  [FULL_ACCESS_ACTION_NAMES.SUBSCRIBE]:
    'widget.overlay-buttons.get-subscription-small',
  [FULL_ACCESS_ACTION_NAMES.PRICING_PLAN]:
    'widget.mobile.overlay-button.subscribe.short',
};
