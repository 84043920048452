import { BI_EVENT } from './constants';
import { getCompId } from '../redux/hydrated-data/hydrated-data';
import { pubSubPublish } from '../worker/actions/pub-sub/publish';
import { createProxy } from '../worker/lib';

export const sendBiEvent = createProxy(
  'sendBiEvent',
  (eventName, params) => (dispatch, getState) => {
    dispatch(
      pubSubPublish(BI_EVENT, {
        eventName,
        params,
        targetCompId: getCompId(getState()),
      }),
    );
  },
);
