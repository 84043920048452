import React, {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type MobileOverlayContextType = {
  isMobileOverlayOpen: boolean;
  initialRoute: string;
  openMobileOverlay: (route?: string) => void;
  closeMobileOverlay: () => void;
};

export const MobileOverlayContext =
  createContext<MobileOverlayContextType | null>(null);

const defaultInitialRoute = '/';

export const MobileOverlayProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [initialRoute, setInitialRoute] = useState<string>(defaultInitialRoute);

  const openMobileOverlay = (route: string = defaultInitialRoute) => {
    setInitialRoute(route);
    setIsOpen(true);
  };

  const closeMobileOverlay = () => {
    setInitialRoute(defaultInitialRoute);
    setIsOpen(false);
  };

  return (
    <MobileOverlayContext.Provider
      value={{
        isMobileOverlayOpen: isOpen,
        initialRoute,
        openMobileOverlay,
        closeMobileOverlay,
      }}
    >
      {children}
    </MobileOverlayContext.Provider>
  );
};

export const useMobileOverlay = () => {
  const context = useContext(MobileOverlayContext);

  if (!context) {
    throw new Error(
      'useMobileOverlay must be used within MobileOverlayProvider',
    );
  }

  return context;
};

export const withMobileOverlay = <P extends MobileOverlayContextType>(
  Component: ComponentType<P>,
) => {
  return (props: P) => {
    const mobileOverlay = useMobileOverlay();

    return <Component {...props} {...mobileOverlay} />;
  };
};
