import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { areElementsRounded } from '../../selectors/app-settings';

import { GenericButton } from '../buttons/buttons';

import styles from './button.scss';

const mapStateToProps = (state) => ({
  isRounded: areElementsRounded(state),
});

export default connect(mapStateToProps)(
  class Button extends React.Component {
    static propTypes = {
      isRounded: PropTypes.bool,
      disableRoundBorders: PropTypes.bool,
      disableTheme: PropTypes.bool,
      className: PropTypes.string,
      dataHook: PropTypes.string,
    };

    static defaultProps = {
      disableRoundBorders: false,
      disableTheme: false,
    };

    render() {
      const { isRounded, disableRoundBorders, disableTheme, className } =
        this.props;

      const props = _.omit(
        this.props,
        'isRounded',
        'styles',
        'dispatch',
        'disableRoundBorders',
        'disableTheme',
      );

      const styleNames = classnames(className, styles.button, {
        [styles['disable-theme']]: disableTheme,
        [styles.rounded]: !disableRoundBorders && isRounded,
      });

      return <GenericButton {...props} className={styleNames} />;
    }
  },
);
