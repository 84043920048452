import { privateApi } from './index';

export const getVodSettings = async () => {
  try {
    return await privateApi.settings.get();
  } catch (error) {
    // NOTE: new site-based API responds with 404 error code if settings are missing (new user scenario)
    // temporary we are fixing this on the client and simulating empty object response, like it was done in old VOD
    if (error.status === 404) {
      return {};
    }

    return Promise.reject(error);
  }
};
