import React, { FC } from 'react';
import {
  WidgetProps as YoshiEditorWidgetProps,
  ReportError,
} from '@wix/yoshi-flow-editor';
import { DesktopWidgetComponent } from './desktopWidgetComponent';
import { MobileWidgetComponent } from './mobile/mobileWidgetComponent';
import { HandlersWithState } from './worker/controller-handlers';

export type WidgetProps = YoshiEditorWidgetProps<
  {
    debug: boolean;
    isSSR: boolean;
    instance: string;
    isResponsive: boolean;
    captureException: ReportError;
  } & HandlersWithState
>;

export const Widget: FC<WidgetProps> = (props) => {
  const isDesktop = ['Desktop', 'Tablet'].includes(props.host.formFactor);
  const Component = isDesktop ? DesktopWidgetComponent : MobileWidgetComponent;

  return <Component {...props} />;
};

export default Widget;
