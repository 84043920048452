import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withErrorMonitor, withTranslation } from '@wix/yoshi-flow-editor';

import { isButtonsTextShown } from '../../../../../selectors/app-settings';
import { playButtonText, playButtonAction } from '../../ui-selectors/buttons';

const mapStateToProps = (state, props) => ({
  text: playButtonText(state, props),
  action: playButtonAction(state, props),
  isTextShown: isButtonsTextShown(state),
  isPlaybackModuleLoaded: state.modules.playbackModuleLoaded,
});

export default withTranslation()(
  connect(mapStateToProps)(
    withErrorMonitor(
      class PlayButton extends React.Component {
        static propTypes = {
          videoItem: PropTypes.object.isRequired,
          text: PropTypes.string,

          icon: PropTypes.node,

          action: PropTypes.func,
          onMemberSignUp: PropTypes.func.isRequired,
          onPlayRequestedAllowed: PropTypes.func.isRequired,
          onPlayRequestedDisallowed: PropTypes.func.isRequired,

          isChannelCoverView: PropTypes.bool,
          isTextShown: PropTypes.bool,

          className: PropTypes.string,
        };

        handleClick = () => {
          const { action, isPlaybackModuleLoaded, errorMonitor } = this.props;
          if (!isPlaybackModuleLoaded) {
            errorMonitor.captureMessage(
              'Play button clicked: The playback module has not been loaded yet.',
            );
          } else {
            action();
          }
        };

        render() {
          const { text, className, icon, isTextShown } = this.props;

          if (!text) {
            return null;
          }

          return (
            <div
              data-hook="overlay-play-button"
              role="button"
              aria-label={this.props.t(text)}
              onClick={this.handleClick}
              className={className}
            >
              {icon}
              <span>{isTextShown && this.props.t(text)}</span>
            </div>
          );
        }
      },
    ),
  ),
);
