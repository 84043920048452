import React from 'react';
import PropTypes from 'prop-types';
import ResizedImage from '../../../../components/resized-image/resized-image';
import styles from './no-videos-content.scss';

const NoVideosContent = ({
  children,
  width,
  height,
  isMainItemChannelCover,
  channelCoverUrl,
}) => {
  if (isMainItemChannelCover) {
    return (
      <ResizedImage
        className={styles['overlay-only']}
        src={channelCoverUrl}
        width={width}
        height={height}
      >
        {children}
      </ResizedImage>
    );
  }

  return (
    <div
      className={styles['no-videos']}
      style={{ width, height }}
      data-hook="noPlayer"
    >
      {children}
    </div>
  );
};

NoVideosContent.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  isMainItemChannelCover: PropTypes.bool,
  channelCoverUrl: PropTypes.string,
};

NoVideosContent.defaultProps = {
  children: <div />,
  width: '100%',
  height: '100%',
};

export default NoVideosContent;
