import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { memoizedPartial } from '@wix/wix-vod-shared/common';
import pubsub from '../../../utils/pubsub';

import HeaderMobile from './header/header-mobile';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/buttons/buttons';

import PropTypes from 'prop-types';
import FixedInIFrame from '../../../mobile-overlay/components/fixed-in-iframe/fixed-in-iframe';

import events from '../../../constants/events';

import styles from './modal.scss';
import stylesMobile from './modal-mobile.scss';
import { DISMISSED } from '../../../utils/modal';
import { getCompId } from '../../../redux/hydrated-data/hydrated-data';
import { previewPrompt } from '../../../components/preview-prompt/preview-prompt.content';

const modalContentTypeToContentMap = {
  ...previewPrompt,
};

const closeOnOverlayClick = true;

function injectButtonComponentsIntoConfig(config, buttonComponents) {
  let { buttons } = config;

  buttons = _.mapValues(buttons, function (value, key) {
    if (value.Button) {
      return value;
    }

    return { ...value, Button: buttonComponents[key] };
  });

  return { ...config, buttons };
}

const mapStateToProps = (state) => ({ compId: getCompId(state) });

export const MobileModal = connect(mapStateToProps)(
  class MobileModal extends React.Component {
    static displayName = 'Modal';

    static propTypes = {
      title: PropTypes.node,
      closeButtonLabel: PropTypes.string,
      content: PropTypes.element,
      className: PropTypes.string,
      windowClassName: PropTypes.string,
      contentClassName: PropTypes.string,
      buttonsClassName: PropTypes.string,
      closeOnOverlayClick: PropTypes.bool,
    };

    static defaultProps = {
      closeOnOverlayClick,
    };

    constructor(props) {
      super(props);
      this.containerRef = null;
    }

    state = {
      open: false,
      closeOnOverlayClick,
      title: null,
      closeButtonLabel: null,
      content: null,
    };

    componentDidMount() {
      this.openModalUnsubscribe = pubsub.subscribe(
        events.MODAL.OPEN,
        this.openModal,
      );
      this.closeModalUnsubscribe = pubsub.subscribe(
        events.MODAL.CLOSE,
        this.closeModal,
      );
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.open && !prevState.open) {
        // useSafeFocus
        const containerRefHeight = this.containerRef.style.height;
        this.containerRef.style.height = 0;
        this.containerRef.focus();
        this.containerRef.style.height = containerRefHeight;
      }
    }

    componentWillUnmount() {
      this.openModalUnsubscribe();
      this.closeModalUnsubscribe();
    }

    saveRef = (ref) => {
      this.containerRef = ref;
    };

    openModal = (config) => {
      if (config.compId !== this.props.compId) {
        return;
      }

      config = injectButtonComponentsIntoConfig(config, {
        primary: PrimaryButton,
        secondary: SecondaryButton,
      });

      if (config.contentType) {
        config.content = modalContentTypeToContentMap[config.contentType];
      }

      this.setState({
        Header: HeaderMobile,
        title: null,
        closeButtonLabel: null,
        content: null,
        closeOnOverlayClick,
        className: stylesMobile['modal-mobile'],
        windowClassName: stylesMobile['modal-mobile-window'],
        contentClassName: stylesMobile['modal-mobile-content'],
        buttonsClassName: '',
        buttons: {}, // {primary: {label, onClick, Button}, secondary: {...}}

        resolve: _.noop,
        reject: _.noop,

        noCloseButton: false,
        ...config,
      });

      setTimeout(() => {
        this.setState({
          open: true,
        });
      }, 0);
    };

    closeModal = () =>
      new Promise((resolve) => {
        this.setState({ open: false }, () => {
          setTimeout(() => {
            this.setState({ content: null }, resolve);
          }, 200);
        });
      });

    handleBackdropClick = (event) => {
      const { closeOnOverlayClick } = this.state;
      if (!event.isDefaultPrevented() && closeOnOverlayClick) {
        this.$modal.dismiss();
      }
    };

    handleCloseButtonClick = () => {
      this.$modal.dismiss();
    };

    stopEventPropagation(event) {
      event.preventDefault();
    }

    get $modal() {
      const { resolve, reject } = this.state;

      return {
        resolve: (data) => {
          resolve({ data, $modal: this.$modal });
        },
        reject(reason = DISMISSED) {
          reject(reason);
        },
        close: (data) => {
          this.closeModal().then(() => resolve(data));
        },
        dismiss: (reason = DISMISSED) => {
          this.closeModal().then(() => reject(reason));
        },
      };
    }

    get buttons() {
      const { buttons, buttonsClassName } = this.state;

      if (!_.keys(buttons).length) {
        return null;
      }

      const keyToDataHookMap = {
        primary: 'confirmation-button',
        secondary: 'cancellation-button',
      };

      return (
        <footer className={classnames(buttonsClassName, styles.buttons)}>
          {_.map(buttons, ({ Button, label, onClick }, key) => (
            <Button
              key={key}
              dataHook={keyToDataHookMap[key]}
              label={label}
              type="button"
              onClick={memoizedPartial(onClick, this.$modal)}
              className={styles.button}
            />
          ))}
        </footer>
      );
    }

    render() {
      const {
        open,
        content,
        Header,
        title,
        closeButtonLabel,
        className,
        windowClassName,
        contentClassName,
        top,
      } = this.state;

      if (!open && !content) {
        return null;
      }

      const rootClassName = classnames(className, styles.modal, {
        [styles.open]: open && content,
      });

      return (
        <FixedInIFrame portal={false} className={stylesMobile['fixed-iframe']}>
          <div
            className={rootClassName}
            onClick={this.handleBackdropClick}
            role="presentation"
          >
            <section
              data-hook="modal-window"
              className={classnames(windowClassName, styles.window, {
                [styles['window-positioned']]: _.isNumber(top),
              })}
              style={{ top }}
              role="dialog"
              aria-modal="true"
              aria-label={title}
              tabIndex={-1}
              ref={this.saveRef}
              onClick={this.stopEventPropagation}
            >
              {Header && (
                <Header
                  title={title}
                  closeButtonLabel={closeButtonLabel}
                  onButtonClick={this.handleCloseButtonClick}
                  role="presentation"
                />
              )}
              <div
                data-hook="modal-content"
                className={classnames(contentClassName, styles.content)}
                role="presentation"
              >
                {_.isString(content)
                  ? content
                  : React.cloneElement(content, { $modal: this.$modal })}
              </div>
              {this.buttons}
            </section>
          </div>
        </FixedInIFrame>
      );
    }
  },
);
