import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Popover } from 'wix-ui-tpa';
import { withTranslation } from '@wix/yoshi-flow-editor';

import AccountInfo from '../account-info/account-info';
import Icon from '../../../icon/icon';

import styles from './account-button-with-tooltip.scss';
import { st, classes } from './popover.st.css';

export default withTranslation()(
  class AccountButtonWithTooltip extends React.Component {
    static propTypes = {
      className: PropTypes.string,
      logoutLabel: PropTypes.string,
      userEmail: PropTypes.string,
      onLogoutClick: PropTypes.func,
    };

    render() {
      const { logoutLabel, userEmail, onLogoutClick, className } = this.props;

      return (
        <Popover
          hideDelay={300}
          placement="bottom"
          wiredToSiteColors={false}
          className={st(classes.root)}
          contentClassName={styles.popout}
          data-hook="channel-account-info"
        >
          <Popover.Element>
            <button
              className={classnames(styles['account-info-button'], className)}
              aria-label={this.props.t('widget.accessibility.my-account')}
            >
              <Icon name="avatar" />
            </button>
          </Popover.Element>
          <Popover.Content>
            <AccountInfo
              logoutLabel={logoutLabel}
              userEmail={userEmail}
              onLogoutClick={onLogoutClick}
            />
          </Popover.Content>
        </Popover>
      );
    }
  },
);
