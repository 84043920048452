import React, {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useEnvironment } from '@wix/yoshi-flow-editor';

type WindowSizeContextType = {
  width: number;
  height: number;
};

export const WindowSizeContext = createContext<WindowSizeContextType>({
  width: 0,
  height: 0,
});

const IframeSize: FC<PropsWithChildren> = ({ children }) => {
  const [size, setSize] = useState<{
    height: number;
    width: number;
  }>({ width: window.innerWidth, height: window.innerHeight });

  const handleResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <WindowSizeContext.Provider value={size}>
      {children}
    </WindowSizeContext.Provider>
  );
};

const ComponentSize: FC<PropsWithChildren> = ({ children }) => {
  const { dimensions, isSSR } = useEnvironment();
  const detector = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 300,
  });

  /*
    Need fix: widget double rendering due to initial zero width leading to image replacement
    https://jira.wixpress.com/browse/VOD-6275
  */
  // @ts-expect-error
  const isFullWidth = dimensions.width === '';

  const width = dimensions.width || detector.width || 0;
  const height = dimensions.height || detector.height || 0;

  return (
    <div
      ref={detector.ref}
      data-hook="window-size"
      style={{
        width: '100%',
      }}
    >
      <WindowSizeContext.Provider
        value={{
          width,
          height,
        }}
      >
        {isSSR || width || isFullWidth ? children : null}
      </WindowSizeContext.Provider>
    </div>
  );
};

export const WindowSize: FC<
  PropsWithChildren<{
    isIframe?: boolean;
  }>
> = ({ children, isIframe }) => {
  if (isIframe) {
    return <IframeSize>{children}</IframeSize>;
  }

  return <ComponentSize>{children}</ComponentSize>;
};

export function withWindowSize<TProps>(
  Component: ComponentType<TProps>,
): ComponentType<TProps> {
  return (props) => {
    const windowSize = useContext(WindowSizeContext);
    return <Component {...props} windowSize={windowSize} />;
  };
}
