import { noop } from 'lodash';
import { createSDK } from '@wix/wix-vod-shared/common';
import { VIEW_MODES } from '@wix/wix-vod-constants/common';

let WixSDK;

export function initWixSDK(WixSDKOriginal) {
  if (WixSDKOriginal) {
    const _BaseSDK = createSDK(WixSDKOriginal);
    const BaseSDK = _BaseSDK || {
      addEventListener: noop,
      removeEventListener: noop,
      Events: {},
      Utils: {},
      PubSub: { subscribe: noop, unsubscribe: noop },
      Performance: {
        applicationLoadingStep: noop,
        applicationLoaded: noop,
      },
    };

    WixSDK = {
      ...BaseSDK,
      Utils: {
        ...BaseSDK.Utils,

        getViewMode() {
          const viewMode = WixSDKOriginal.Utils.getViewMode();
          return viewMode === VIEW_MODES.STANDALONE
            ? VIEW_MODES.SITE
            : viewMode;
        },
      },
    };
  }
}
export const getWixSDK = () => WixSDK;
