import { isCommentsEnabled, isCommentsEnabledForLive } from './app-settings';
import { canAccessFullVideo } from '@wix/wix-vod-shared/widget';
import { isDemoChannel } from '../utils/channel-helpers';
import {
  isExternalVideo,
  isStreamingLive,
  isScheduledLive,
} from '@wix/wix-vod-shared/common';

export const canShowComments = (
  state,
  videoItem,
  channelData,
  currentSiteUser,
) =>
  ((!isStreamingLive(videoItem) && isCommentsEnabled(state)) ||
    (isStreamingLive(videoItem) && isCommentsEnabledForLive(state))) &&
  !isDemoChannel(channelData.id) &&
  !isExternalVideo(videoItem) &&
  !isScheduledLive(videoItem) &&
  canAccessFullVideo(channelData, videoItem, currentSiteUser);

export const isCommentsOpen = (state) => state.comments.isOpen;
export const shouldOpenCommentsOnLoad = (state) => state.comments.openOnLoad;
export const hasNewComments = (state) => state.comments.hasNewComments;
