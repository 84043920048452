import React from 'react';
import { connect } from 'react-redux';
import { logBi } from '../worker/actions/bi';

import { getCompId } from '../redux/hydrated-data/hydrated-data';
import {
  withPubSubEvents,
  consumePubSubEvent,
} from '../containers/pub-sub-events';
import { BI_EVENT } from './constants';

const mapStateToProps = (state) => ({
  currentCompId: getCompId(state),
});

const mapDispatchToProps = { logBi };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withPubSubEvents(
    class BiHandler extends React.Component {
      componentDidUpdate(prevProps) {
        consumePubSubEvent(BI_EVENT, this.sendBiEvent, {
          prevProps,
          nextProps: this.props,
        });
      }

      sendBiEvent = ({ data }) => {
        const { eventName, params, targetCompId } = data;
        if (targetCompId === this.props.currentCompId) {
          this.props.logBi(eventName, params);
        }
      };

      render() {
        return null;
      }
    },
  ),
);
