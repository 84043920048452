import React from 'react';
import classnames from 'classnames';
import styles from './content.scss';

export enum ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

interface WixFont {
  value: string;
  size: number;
}

interface Props {
  width: number;
  title?: string;
  description?: string;
  publisher?: string;

  className?: string;
  titleClassName?: string;
  textClassName?: string;
  publisherClassName?: string;

  onClick: () => void;
  isFocusable?: boolean;
  alignment: ALIGNMENT;
  isRTL?: boolean;

  titleFont: WixFont;
  textFont: WixFont;

  isOptimalFontSizes?: boolean;
}
export { Props as ContentProps };

export class Content extends React.Component<Props> {
  static defaultProps = {
    alignment: ALIGNMENT.LEFT,
    isOptimalFontSizes: true,
  };

  state = { isClient: false };

  componentDidMount(): void {
    if (typeof window !== 'undefined') {
      this.setState({ isClient: true });
    }
  }

  renderPublisher() {
    const { publisher, isRTL, publisherClassName } = this.props;

    if (!publisher) {
      return null;
    }

    const publisherClassNames = classnames(
      styles.publisher,
      publisherClassName,
      {
        [styles.rtl]: isRTL,
        [styles.ltr]: !isRTL,
      },
    );

    return (
      <div className={publisherClassNames} data-hook="publisher">
        {publisher}
      </div>
    );
  }

  renderTitle() {
    const { title, isRTL, titleClassName, isOptimalFontSizes } = this.props;

    if (!title) {
      return null;
    }

    const titleClassNames = classnames(styles.title, titleClassName, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
      [styles.optimalFontSize]: isOptimalFontSizes,
    });

    return (
      <h3
        key={this.state.isClient ? 'title-client' : 'title-ssr'}
        className={titleClassNames}
        title={title}
        data-hook="title"
      >
        {title}
      </h3>
    );
  }

  renderDescription() {
    const { description, isRTL, textClassName, isOptimalFontSizes } =
      this.props;

    if (!description) {
      return null;
    }

    const descriptionClassNames = classnames(
      styles.description,
      textClassName,
      {
        [styles.rtl]: isRTL,
        [styles.ltr]: !isRTL,
        [styles.optimalFontSize]: isOptimalFontSizes,
      },
    );

    return (
      <div
        key={this.state.isClient ? 'description-client' : 'description-ssr'}
        className={descriptionClassNames}
        data-hook="description"
      >
        {description}
      </div>
    );
  }

  handleContentClick = (event: React.MouseEvent) => {
    const { onClick } = this.props;

    event.preventDefault();
    event.stopPropagation();
    onClick();
  };

  render() {
    const { className, isFocusable, alignment, isRTL } = this.props;

    const containerClassNames = classnames(styles.container, {
      [styles['align-left']]: alignment === ALIGNMENT.LEFT,
      [styles['align-center']]: alignment === ALIGNMENT.CENTER,
      [styles['align-right']]: alignment === ALIGNMENT.RIGHT,
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const contentClassNames = classnames(styles.content, className, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return (
      <div className={containerClassNames}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className={contentClassNames}
          onClick={this.handleContentClick}
          tabIndex={isFocusable ? 0 : -1}
          aria-hidden={!isFocusable}
        >
          {this.renderPublisher()}
          {this.renderTitle()}
          {this.renderDescription()}
        </a>
      </div>
    );
  }
}
