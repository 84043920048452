import {
  getSubscriptionPriceInfo,
  isPricingPlanEnabled,
} from '@wix/wix-vod-shared/common';
import formatMessageWithPrice from '../../../utils/format-message-with-price';
import { isSubscriptionButtonVisible } from './subscribe-button-text-utils';
import { t } from '../../../utils/translation';

/**
 * Is video subscribe button visible
 * @param props {Object}
 * @param props.channel {Object}
 * @return {Boolean}
 */

/**
 * Video sign in to play button text
 * @param props {Object}
 * @param props.channel {Object}
 * @param props.translation {Object} default is 'widget.overlay-buttons.get-subscription'
 * @return {React.Component}
 */
export default function renderSubscribeButtonText(props) {
  if (!isSubscriptionButtonVisible(props)) {
    return null;
  }

  const isPricingPlan = isPricingPlanEnabled(props.channel);

  return isPricingPlan
    ? t('widget.mobile.overlay-button.subscribe.short')
    : formatMessageWithPrice(
        props.translation || 'widget.overlay-buttons.get-subscription',
        getSubscriptionPriceInfo(props.channel.dealInfo),
      );
}
