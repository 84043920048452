import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './home.wrapper.scss';
import coreStyles from '../../../../styles/core.scss';

const HomeLayoutWrapper = ({ children, viewMode }) => (
  <div
    className={classNames(coreStyles.root, styles.container)}
    data-view-mode={viewMode}
    role="presentation"
  >
    {children}
  </div>
);

HomeLayoutWrapper.propTypes = {
  children: PropTypes.node,
  viewMode: PropTypes.string,
};

export default HomeLayoutWrapper;
