import _ from 'lodash';
import { createSelector } from 'reselect';
import { showVisitChannel, showSignIn, showSignOut } from './show-actions';
import { subscribeButtonText } from './buttons';
import { canShowChannelShare } from '../../../../selectors/app-settings';
import { canShareVideo } from '../../../../selectors/video-share';
import { isChannelCoverView } from './cover-with-overlay';

export const ALL_MENU_ITEMS = {
  VISIT_CHANNEL: 'VISIT_CHANNEL',
  SUBSCRIBE: 'SUBSCRIBE',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_IN: 'SIGN_IN',
  SHARE_CHANNEL: 'SHARE_CHANNEL',
  SHARE_VIDEO: 'SHARE_VIDEO',
};

const showSubscribe = createSelector(subscribeButtonText, (text) =>
  Boolean(text),
);

const showShareChannel = createSelector(
  [isChannelCoverView, canShowChannelShare],
  (isChannelCoverView, canShowChannelShare) =>
    isChannelCoverView && canShowChannelShare,
);

const showShareVideo = createSelector(
  [isChannelCoverView, canShareVideo],
  (isChannelCoverView, canShareVideo) => !isChannelCoverView && canShareVideo,
);

const ITEMS_VISIBILITY = createSelector(
  [
    showVisitChannel,
    showSubscribe,
    showSignOut,
    showSignIn,
    showShareChannel,
    showShareVideo,
  ],
  (
    showVisitChannel,
    showSubscribe,
    showSignOut,
    showSignIn,
    showShareChannel,
    showShareVideo,
  ) => ({
    [ALL_MENU_ITEMS.VISIT_CHANNEL]: showVisitChannel,
    [ALL_MENU_ITEMS.SUBSCRIBE]: showSubscribe,
    [ALL_MENU_ITEMS.SIGN_OUT]: showSignOut,
    [ALL_MENU_ITEMS.SIGN_IN]: showSignIn,
    [ALL_MENU_ITEMS.SHARE_CHANNEL]: showShareChannel,
    [ALL_MENU_ITEMS.SHARE_VIDEO]: showShareVideo,
  }),
);

export const getVisibleMenuItems = createSelector(ITEMS_VISIBILITY, (items) =>
  _(items).pickBy(Boolean).keys().value(),
);

export const shouldShowMenuButton = createSelector(
  getVisibleMenuItems,
  (visibleItems) => !_.isEmpty(visibleItems),
);
