import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@wix/wix-vod-shared/components';
import styles from './embed-styles.scss';

class EmbedSelect extends React.Component {
  static propTypes = {
    dataHook: PropTypes.string,
    ariaLabel: PropTypes.string,
    selected: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    isRTL: PropTypes.bool,
  };

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { options, selected, dataHook, ariaLabel, isRTL } = this.props;

    return (
      <Select
        dataHook={dataHook}
        ariaLabel={ariaLabel}
        className={styles.select}
        selected={selected}
        onChange={this.handleChange}
        options={options}
        isRTL={isRTL}
      />
    );
  }
}

export default EmbedSelect;
