import { isVideoInInitialState } from '../../selectors/video-playback-status';
import { requestPlayVideo } from './request-play-video';
import { requestPlay } from '../reducers/playback';
import { createProxy } from '../../worker/lib';
import { setAutoPlayed } from './player/set-autoplayed';

export const requestPlayVideoOnload = createProxy(
  'requestPlayVideoOnload',
  (id, isMobile) => (dispatch, getState) => {
    const state = getState();

    if (isMobile) {
      dispatch(setAutoPlayed(true));
      dispatch(requestPlay(id, true));
    } else if (isVideoInInitialState(state)) {
      dispatch(requestPlayVideo(id, true, true));
    }
  },
);
