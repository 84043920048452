import React, { ReactNode, memo } from 'react';
import _ from 'lodash';

import classnames from 'classnames';

import { AnimateCrossfade } from '../animate-crossfade/animate-crossfade';

import styles from './main-item-slider.scss';

interface Props {
  loadedVideoIds: string[];
  mainVideoId: string;
  renderItem: (videoId: string, isVisible: boolean) => ReactNode;
  onAnimationComplete: () => void;

  isMainVideoAtChannelCover: boolean;
  isPlayerVisible: boolean;
  className: boolean;
}

export const MainItemSliderBase: React.FC<Props> = ({
  loadedVideoIds,
  mainVideoId,
  className,
  onAnimationComplete,
  renderItem,
  isPlayerVisible,
  isMainVideoAtChannelCover = false,
}) => {
  const videoIdsToRender = loadedVideoIds.length
    ? loadedVideoIds
    : [mainVideoId];
  const mainVideoIdIndex = loadedVideoIds.length
    ? loadedVideoIds.indexOf(mainVideoId)
    : 0;

  return (
    <div className={classnames(styles.container, className)}>
      <AnimateCrossfade
        activeClassName={isPlayerVisible ? styles.active : ''}
        fadeInOnly={isMainVideoAtChannelCover}
        onAnimationComplete={onAnimationComplete}
        currentIdIndex={mainVideoIdIndex}
      >
        {_.map(videoIdsToRender, (videoId, index) => {
          const isCurrentOrPrevOrNext = Math.abs(mainVideoIdIndex - index) <= 1;

          if (!isCurrentOrPrevOrNext) {
            return <div key={videoId} />;
          }

          const isVisible = index === mainVideoIdIndex;
          return (
            <section
              className={styles.item}
              data-hook="main-item-slider-item"
              data-is-visible-overlay={isVisible ? 'visible' : null}
              aria-hidden={!isVisible}
              key={videoId}
            >
              {renderItem(videoId, isVisible)}
            </section>
          );
        })}
      </AnimateCrossfade>
    </div>
  );
};

export const MainItemSlider = memo(MainItemSliderBase);
