import _ from 'lodash';
import { createSelector } from 'reselect';
import { getFeaturedVideoId } from '@wix/wix-vod-shared/widget';
import { canShowCancelSubscription, canShowSubscription } from './subscription';
import { getMainVideo } from './get-video';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
} from './video-playback-status';
import {
  canShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
  canShowChannelTitle,
} from './app-settings';
import { canShowChannelCategories } from './categories';
import { getOverlayContent } from './player';
import { VideoSources } from '../redux/types';
import INFO_OVERLAY_CONTENT from '../player-widget/components/new-player/containers/animated-overlay-content/info-overlay-content-enums';
import {
  VERTICAL_LAYOUT_HEADER_HEIGHT,
  VERTICAL_LAYOUT_VIDEO_HEIGHT,
  VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM,
} from '../constants/sizes';
import {
  getChannelVideos,
  getCurrentPageIndex,
  getVideosPerPageCount,
} from '../redux/lazy-channel-videos/selectors';
import { isPortableDevice } from './form-factor';

export const canShowActionBar = createSelector(
  canShowChannelTitle,
  getCanShowSignIn,
  getCanShowChannelInfo,
  canShowChannelShare,
  canShowCancelSubscription,
  canShowSubscription,
  canShowChannelCategories,
  (...args) => _.some(args),
);

export const showAutoPlay = createSelector(
  getMainVideo,
  isPortableDevice,
  (mainVideo, isPortableDevice) =>
    !!mainVideo &&
    !(mainVideo.videoSource === VideoSources.FACEBOOK && isPortableDevice) &&
    !(mainVideo.videoSource === VideoSources.VIMEO && isPortableDevice),
);

export const isAllCardsOverlayVisible = createSelector(
  getOverlayContent,
  (overlayContent) => overlayContent === INFO_OVERLAY_CONTENT.ALL_CARDS,
);

export const isPlayerActive = createSelector(
  isAllCardsOverlayVisible,
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
  (isAllCardsOverlayVisible, isVideoPlaying, isVideoPaused) =>
    isAllCardsOverlayVisible || isVideoPlaying || isVideoPaused,
);

export const getVerticalVisibleVideos = createSelector(
  getCurrentPageIndex,
  getChannelVideos,
  getFeaturedVideoId,
  getVideosPerPageCount,
  (currentPageIndex, channelVideos, featuredVideoId, videosPerPage) => {
    if (featuredVideoId) {
      const featuredVideoIndex = _.findIndex(
        channelVideos,
        ({ id }) => id === featuredVideoId,
      );
      const featuredVideo = channelVideos[featuredVideoIndex];
      channelVideos.splice(featuredVideoIndex, 1);
      channelVideos.unshift(featuredVideo);
    }

    return _.slice(channelVideos, 0, (currentPageIndex + 1) * videosPerPage);
  },
);

export const getShareModalTopPosition = (videoIndex) =>
  VERTICAL_LAYOUT_HEADER_HEIGHT +
  videoIndex *
    (VERTICAL_LAYOUT_VIDEO_HEIGHT + VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM) +
  VERTICAL_LAYOUT_VIDEO_HEIGHT / 2;
