import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PaymentEvents from '../components/payment-events/payment-events';
import {
  clearVideoUrlsCacheForAll,
  clearVideoUrlsCacheForItem,
} from '../redux/actions/video-urls/clear';

const mapStateToProps = null;

const mapDispatchToProps = {
  clearVideoUrlsCacheForAll,
  clearVideoUrlsCacheForItem,
};

const ClearVideoUrlsCacheOnPayment = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ClearVideoUrlsCacheOnPayment extends React.Component {
    static propTypes = {
      clearVideoUrlsCacheForAll: PropTypes.func,
      clearVideoUrlsCacheForItem: PropTypes.func,
    };

    clearVideoUrlsCacheForAll = _.debounce(() => {
      this.props.clearVideoUrlsCacheForAll();
    }, 300);

    clearVideoUrlsCacheForItem = (data) => {
      this.props.clearVideoUrlsCacheForItem(data.itemId);
    };

    render() {
      return (
        <PaymentEvents
          onSubscription={this.clearVideoUrlsCacheForAll}
          onSubscriptionCancel={this.clearVideoUrlsCacheForAll}
          onLogin={this.clearVideoUrlsCacheForAll}
          onRent={this.clearVideoUrlsCacheForItem}
          onSale={this.clearVideoUrlsCacheForItem}
        />
      );
    }
  },
);

export default ClearVideoUrlsCacheOnPayment;
