import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import { Direction } from '../../../containers/direction';
import { StyleParamsCSSVariables } from '../../../components/style-params-css-variables/StyleParamsCSSVariables';

const wrapWithPortal = (portal, children) =>
  portal ? (
    <Portal role="presentation">
      <Direction>
        <StyleParamsCSSVariables>{children}</StyleParamsCSSVariables>
      </Direction>
    </Portal>
  ) : (
    <div role="presentation">{children}</div>
  );

export default function FixedInIFrame({
  children,
  className,
  style,
  dataHook,
  portal = true,
}) {
  return wrapWithPortal(
    portal,
    <div
      style={{ ...style, position: 'fixed' }}
      data-hook={dataHook}
      className={className}
      role="presentation"
    >
      {children}
    </div>,
  );
}

FixedInIFrame.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  dataHook: PropTypes.string,
  style: PropTypes.object,
  portal: PropTypes.bool,
};
