import { connect } from 'react-redux';
import { logWidgetSystem } from '../worker/actions/bi';
import React from 'react';
import { Direction } from '../containers/direction';
import HomeLayout from './layouts/home/home';
import { WidgetResizer } from '../components/widget-resizer/widget-resizer';
import { WindowSize } from '../containers/window-size';
import { MobileOverlayModal } from '../mobile-overlay/MobileOverlayModal';
import { MobileOverlayProvider } from '../mobile-overlay/MobileOverlayProvider';

const mapStateToProps = null;
const mapDispatchToProps = { logWidgetSystem };

export const Component = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class Component extends React.Component {
    componentDidMount() {
      this.props.logWidgetSystem('mobile.app.initialized');
    }

    render() {
      return (
        <MobileOverlayProvider>
          <WindowSize>
            <WidgetResizer>
              <Direction>
                <HomeLayout />
                <MobileOverlayModal />
              </Direction>
            </WidgetResizer>
          </WindowSize>
        </MobileOverlayProvider>
      );
    }
  },
);
