import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './with-tooltip.scss';

export default class WithTooltip extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    tooltip: PropTypes.any,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
  };

  renderTooltip() {
    const { tooltip, innerClassName } = this.props;

    return (
      <span className={styles.tooltip}>
        <span className={classnames(innerClassName, styles['tooltip-inner'])}>
          {tooltip}
        </span>
      </span>
    );
  }

  render() {
    const { tooltip, children, className } = this.props;

    if (!children) {
      return null;
    }

    return (
      <span className={classnames(className, styles['with-tooltip'])}>
        {tooltip && this.renderTooltip()}
        {children}
      </span>
    );
  }
}
