import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styles from './load-more-button.scss';
import { withTranslation } from '@wix/yoshi-flow-editor';

export default withTranslation()(
  class LoadMoreButton extends React.Component {
    static propTypes = {
      height: PropTypes.number,
      onClick: PropTypes.func,
    };

    static defaultProps = {
      height: 66,
      onClick: _.noop,
    };

    render() {
      const { height, onClick } = this.props;

      return (
        <button
          data-hook="mobile-load-more-button"
          onClick={onClick}
          className={styles.button}
          style={{
            height,
            lineHeight: `${height}px`,
          }}
        >
          {this.props.t('widget.load-more')}
        </button>
      );
    }
  },
);
