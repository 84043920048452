import { noop } from 'lodash';
import { FULL_ACCESS_ACTION_NAMES } from '@wix/wix-vod-shared/widget';

export function getBuyButtonHandler(
  onSubscriptionRequest,
  onPurchaseRequest,
  onRentRequest,
  fullAccessAction,
) {
  if (fullAccessAction === FULL_ACCESS_ACTION_NAMES.RENT) {
    return onRentRequest;
  }

  if (
    fullAccessAction === FULL_ACCESS_ACTION_NAMES.SUBSCRIBE ||
    fullAccessAction === FULL_ACCESS_ACTION_NAMES.PRICING_PLAN
  ) {
    return onSubscriptionRequest;
  }

  if (fullAccessAction) {
    return onPurchaseRequest;
  }

  return noop;
}
