import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from '../overlay.scss';

export default class OverlayPublisher extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.string,
    ariaHidden: PropTypes.bool,
  };

  static defaultProps = {
    ariaHidden: false,
  };

  render() {
    const { children, className, ariaHidden } = this.props;

    if (!children) {
      return null;
    }

    return (
      <div
        className={classnames(styles.title, className)}
        data-hook="publisher"
        aria-hidden={ariaHidden}
      >
        {children}
      </div>
    );
  }
}
