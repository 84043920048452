export const sides = {
  TOP: 'top',
  LEFT: 'left',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  NONE: 'none',
};

export const trianglePositions = {
  START: 'start',
  CENTER: 'center',
  END: 'end',
};

export const popoutPositions = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  TOP: 'top',
};

export const openOn = {
  CLICK: 'click',
  HOVER: 'hover',
};
