import React from 'react';
import PropTypes from 'prop-types';
import LiveVideoCounter from './components/live-video-counter';
import LiveVideoStatusUpdater from './components/live-video-status-updater';
import { isStreamingLive } from '@wix/wix-vod-shared/common';

export const LiveVideoUpdaters = ({
  videoItem,
  eventEmitter,
  channelId,
  canAccessFullVideo,
  isSiteMode,
}) =>
  isStreamingLive(videoItem) && isSiteMode ? (
    <div>
      <LiveVideoCounter
        eventEmitter={eventEmitter}
        videoItem={videoItem}
        canAccessFullVideo={canAccessFullVideo}
      />
      <LiveVideoStatusUpdater
        eventEmitter={eventEmitter}
        videoItem={videoItem}
        channelId={channelId}
      />
    </div>
  ) : null;

LiveVideoUpdaters.propTypes = {
  videoItem: PropTypes.object,
  eventEmitter: PropTypes.object,
  canAccessFullVideo: PropTypes.bool,
  channelId: PropTypes.string,
  isSiteMode: PropTypes.bool.isRequired,
};
