import React from 'react';
import { logWidgetSystem } from '../../worker/actions/bi';
import { connect } from 'react-redux';

import PaymentEvents from '../payment-events/payment-events';

const mapStateToProps = null;
const mapDispatchToProps = { logWidgetSystem };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class PaymentsBiHandler extends React.Component {
    logPaymentSucceeded = () => {
      this.props.logWidgetSystem('video.purchase.succeeded');
    };

    logSubscribeSucceeded = () => {
      this.props.logWidgetSystem('channel.subscribe.succeeded');
    };

    logUnsubscribeSucceeded = () => {
      this.props.logWidgetSystem('channel.unsubscribe.succeeded');
    };

    render() {
      return (
        <PaymentEvents
          onSale={this.logPaymentSucceeded}
          onRent={this.logPaymentSucceeded}
          onSubscription={this.logSubscribeSucceeded}
          onSubscriptionCancel={this.logUnsubscribeSucceeded}
        />
      );
    }
  },
);
