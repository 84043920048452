import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PAYMENT_MESSAGE_PROXY from '../../constants/payment-message-proxy';
import EVENTS from '../../constants/events';

import { getCompId } from '../../redux/hydrated-data/hydrated-data';
import {
  consumePubSubEvent,
  withPubSubEvents,
} from '../../containers/pub-sub-events';

const mapStateToProps = (state) => ({ compId: getCompId(state) });

export default connect(mapStateToProps)(
  withPubSubEvents(
    class PaymentEvents extends React.Component {
      static propTypes = {
        onSale: PropTypes.func,
        onRent: PropTypes.func,
        onSubscription: PropTypes.func,
        onSubscriptionCancel: PropTypes.func,
        onAnyEvent: PropTypes.func,
        onLogin: PropTypes.func,
      };

      static defaultProps = {
        onSale: _.noop,
        onRent: _.noop,
        onSubscription: _.noop,
        onSubscriptionCancel: _.noop,
        onAnyEvent: _.noop,
        onLogin: _.noop,
      };

      componentDidUpdate(prevProps) {
        consumePubSubEvent(
          EVENTS.PAYMENT.PAYMENT_EVENT,
          this.handlePaymentMessage,
          {
            prevProps,
            nextProps: this.props,
          },
        );

        consumePubSubEvent(EVENTS.SESSION.LOGIN_USER, this.handleUserLogin, {
          prevProps,
          nextProps: this.props,
        });
      }

      handlePaymentMessage = ({ data }) => {
        const type = _.get(data, 'message.type');
        const isSubscriptionCancel =
          type === PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION_CANCEL;

        if (
          _.get(data, 'compId') !== this.props.compId &&
          !isSubscriptionCancel
        ) {
          return;
        }

        const paymentMessage = data.message;

        const handler = {
          [PAYMENT_MESSAGE_PROXY.TYPES.SALE]: this.handleSale,
          [PAYMENT_MESSAGE_PROXY.TYPES.RENT]: this.handleRent,
          [PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION]: this.handleSubscription,
          [PAYMENT_MESSAGE_PROXY.TYPES.SUBSCRIPTION_CANCEL]:
            this.handleSubscriptionCancel,
        }[paymentMessage.type];

        if (handler) {
          handler(paymentMessage);
        }
      };

      handleSale = (message) => {
        const { onSale, onAnyEvent } = this.props;
        if (message.completed) {
          onAnyEvent(message);
          onSale(message);
        }
      };

      handleRent = (message) => {
        const { onRent, onAnyEvent } = this.props;
        if (message.completed) {
          onAnyEvent(message);
          onRent(message);
        }
      };

      handleSubscription = (message) => {
        const { onSubscription, onAnyEvent } = this.props;
        if (message.completed) {
          onAnyEvent(message);
          onSubscription(message);
        }
      };

      handleSubscriptionCancel = (message) => {
        const { onSubscriptionCancel, onAnyEvent } = this.props;
        if (message.completed) {
          onAnyEvent(message);
          onSubscriptionCancel(message);
        }
      };

      handleUserLogin = () => {
        const { onAnyEvent, onLogin } = this.props;
        onAnyEvent();
        onLogin();
      };

      render() {
        return null;
      }
    },
  ),
);
