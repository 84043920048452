import React from 'react';
import styles from './balls-loader.scss';

export const BallsLoader: React.FC = () => (
  <div className={styles.container} data-hook="balls-loader">
    <div className={styles.ballOne} />
    <div className={styles.ballTwo} />
    <div className={styles.ballThree} />
  </div>
);
