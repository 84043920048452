import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logBi, logWidgetSystem } from '../../worker/actions/bi';
import { requestLogin, logOutCurrentMember } from '../../utils/auth';
import {
  openFullScreenOverlay,
  openFullScreenChannelOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenShareOverlay,
} from '../../utils/open-overlay-base';
import { notForPreview } from '../../utils/not-for-preview';
import {
  getSiteUrl,
  getInstance,
} from '../../redux/hydrated-data/hydrated-data';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/common';
import { cancelOrder } from '../../redux/actions/payment';

const mapStateToProps = (state) => ({
  siteUrl: getSiteUrl(state),
  instance: getInstance(state),
  isPricingPlan: isPricingPlanEnabled(state.channelInfo.info),
});

const mapDispatchToProps = {
  logBi,
  logWidgetSystem,
  requestLogin,
  logOutCurrentMember,
  openFullScreenShareOverlay,
  openFullScreenChannelOverlay,
  openFullScreenSubscribeOverlay,
  openFullScreenOverlay,
  cancelOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class ChannelActionsContainer extends React.Component {
    static propTypes = {
      children: PropTypes.func.isRequired,
      channelId: PropTypes.string,
      videoId: PropTypes.string,
      siteUrl: PropTypes.string,
      instance: PropTypes.string,

      onPageRefresh: PropTypes.func.isRequired,
    };

    static defaultProps = {
      onPageRefresh: _.noop,
    };

    showChannelInfoAtOverlay = () => {
      this.props.openFullScreenChannelOverlay(this.props.channelId);
    };

    showChannelShareAtOverlay = notForPreview(() => {
      const { videoId, channelId } = this.props;
      this.props.openFullScreenShareOverlay(channelId, videoId);
    });

    openAccountInfoAtOverlay = notForPreview(() => {
      this.props.openFullScreenOverlay(
        `#/account-info/${this.props.channelId}`,
      );
    });

    showChannelSubscriptionInfoAtOverlay = notForPreview(() => {
      const { channelId, isPricingPlan } = this.props;
      this.props.logBi('widget.subscription.clicked', { channelID: channelId });
      this.props.openFullScreenSubscribeOverlay(
        channelId,
        () => {
          this.props.logBi('widget.subscriptionPopUp.Completed', {
            result: 'canceled',
            errorMsg: '',
          });
        },
        isPricingPlan,
      );
    });

    cancelChannelSubscription = notForPreview(async () => {
      const { siteUrl, instance, channelId } = this.props;
      this.props.logWidgetSystem('channel.unsubscribe.requested');
      this.props.cancelOrder(channelId, siteUrl, instance);
    });

    showLoginForm = notForPreview(() => {
      this.props.logBi('widget.signIn.clicked', {
        channelID: this.props.channelId,
      });
      this.props.requestLogin().then(this.props.onPageRefresh);
    });

    logOut = () => {
      this.props.logOutCurrentMember();
      this.props.onPageRefresh();
    };

    render() {
      return this.props.children({
        showInfo: this.showChannelInfoAtOverlay,
        showAccountInfo: this.openAccountInfoAtOverlay,
        subscribe: this.showChannelSubscriptionInfoAtOverlay,
        cancelSubscription: this.cancelChannelSubscription,
        logOut: this.logOut,
        logIn: this.showLoginForm,
        showShare: this.showChannelShareAtOverlay,
      });
    }
  },
);
