import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';

import SearchImage from './images/search.svg';

import styles from './empty-search-results.scss';

export default withTranslation()(
  class EmptySearchResults extends React.Component {
    static propTypes = {
      className: PropTypes.string,
    };

    render() {
      const { className } = this.props;

      return (
        <section className={classnames(styles['no-search-wrapper'], className)}>
          <SearchImage className={styles['no-search-icon']} />
          <span className={styles['no-search-text']}>
            {this.props.t('widget.home-view.no-search-results')}
          </span>
        </section>
      );
    }
  },
);
