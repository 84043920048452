import { browserLocalStorage } from '@wix/wix-vod-shared/common';
import { VIDEO_PAYMENT_LOGOUT_KEY } from '../constants/payments';
import { getWixSDK } from './wix-sdk';
import { DealInfoType } from '../redux/types';

export const openPaymentModalAfterReload = (paymentType) => {
  browserLocalStorage.setItem(
    VIDEO_PAYMENT_LOGOUT_KEY,
    JSON.stringify({
      paymentType,
      openerCompId: getWixSDK().Utils.getOrigCompId(),
    }),
  );
};

export const handleLogOutPaymentModal = ({
  onPurchase,
  onSubscription,
  onRent,
}) => {
  const stored = browserLocalStorage.getItem(VIDEO_PAYMENT_LOGOUT_KEY);

  const openers = {
    [DealInfoType.RENT]: onRent,
    [DealInfoType.SALE]: onPurchase,
    [DealInfoType.SUBSCRIPTION]: onSubscription,
  };

  if (stored) {
    const { paymentType, openerCompId } = JSON.parse(stored);

    if (openerCompId === getWixSDK().Utils.getCompId()) {
      openers[paymentType]();
      browserLocalStorage.removeItem(VIDEO_PAYMENT_LOGOUT_KEY);
    }
  }
};
