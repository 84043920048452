const SLIDER_PADDINGS = {
  275: 31,
  400: 36,
  700: 52,
};

export const getSliderHorizontalPadding = (itemWidth, isArrowsOutside) => {
  /* eslint-disable curly */
  if (!isArrowsOutside) {
    return 0;
  }

  if (itemWidth >= 700) return SLIDER_PADDINGS[700];
  if (itemWidth >= 400) return SLIDER_PADDINGS[400];

  return SLIDER_PADDINGS[275];
};
