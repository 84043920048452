import React, { FC, useContext } from 'react';
import { ControllerState } from '../worker/controller-handlers';
import { Handlers } from '../worker/controller-handlers/handlers';

export type ControllerHandlersWithState = {
  controllerState: ControllerState;
  handlers: Handlers;
};

export const ControllerStateContext =
  React.createContext<ControllerHandlersWithState | null>(null);

export const useHandlersAndState = () => {
  const context = useContext(ControllerStateContext);

  if (!context) {
    throw new Error(
      'useHandlersAndState must be used within ControllerStateContext',
    );
  }

  return context;
};

export const withHandlersAndState = (
  Component: FC<ControllerHandlersWithState>,
) => {
  return ((props) => {
    const { controllerState, handlers } = useHandlersAndState();

    return (
      <Component
        {...props}
        controllerState={controllerState}
        handlers={handlers}
      />
    );
  }) as FC;
};
