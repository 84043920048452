import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';

import { logBi } from '../../worker/actions/bi';
import { connect } from 'react-redux';

import { WidgetDropdown } from '../dropdown/dropdown';

import styles from './categories.scss';

const mapStateToProps = null;
const mapDispatchToProps = { logBi };

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class Categories extends React.Component {
      static propTypes = {
        className: PropTypes.string,
        list: PropTypes.arrayOf(PropTypes.string),
        onCategorySelect: PropTypes.func,
        selectedCategory: PropTypes.string,
        height: PropTypes.number,
        isFocusable: PropTypes.bool,
        DropdownClass: PropTypes.func,
        maxOptionsVisible: PropTypes.number,
      };

      static defaultProps = {
        selectedCategory: null,
        DropdownClass: WidgetDropdown,
      };

      getOptions = _.memoize((list) => {
        const options = _.map(list, (value) => ({ label: value, value }));
        return [
          {
            label: this.props.t('widget.categories.all-videos'),
            value: '',
          },

          ...options,
        ];
      });

      sendCategorySelectedBI() {
        this.props.logBi('widget.categories.selected');
      }

      sendCategoriesClickedBI = () => {
        this.props.logBi('widget.categories.clicked');
      };

      handleChange = (value) => {
        this.sendCategorySelectedBI();
        this.props.onCategorySelect(value);
      };

      render() {
        const {
          className,
          selectedCategory,
          DropdownClass,
          maxOptionsVisible,
          height,
          list,
          isFocusable,
        } = this.props;

        return (
          <DropdownClass
            className={`${className} qa-widget-categories`}
            dataHook="categories-dropdown"
            optionsClassName={styles.options}
            optionClassName={styles.option}
            height={height}
            maxOptionsVisible={maxOptionsVisible}
            isFocusable={isFocusable}
            onChange={this.handleChange}
            onOpen={this.sendCategoriesClickedBI}
            value={selectedCategory || ''}
            options={this.getOptions(list)}
            ariaLabel={this.props.t('widget.accessibility.select-category')}
          />
        );
      }
    },
  ),
);
