import React, {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  useContext,
} from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useResizeDetector } from 'react-resize-detector';

type WidgetWidthContextType = {
  widgetWidth: number;
};

type Query = { className: string } & (
  | {
      minWidth: number;
      maxWidth?: number;
    }
  | {
      minWidth?: number;
      maxWidth: number;
    }
);

const WidgetWidthContext = createContext<WidgetWidthContextType | null>(null);

export const WidgetWidthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { ref, width = 0 } = useResizeDetector();

  return (
    <div
      ref={ref}
      style={{
        // @ts-expect-error
        containerType: 'inline-size',
        width: '100%',
      }}
    >
      <WidgetWidthContext.Provider value={{ widgetWidth: width }}>
        {children}
      </WidgetWidthContext.Provider>
    </div>
  );
};

function useWidgetWidth() {
  const context = useContext(WidgetWidthContext);

  if (context === null) {
    throw new Error('WidgetWidthContext is null');
  }

  return context;
}

function getClassNames(queries: Query[], widgetWidth: number, isSSR: boolean) {
  if (isSSR || CSS.supports('container-type', 'inline-size')) {
    // use @container queries (@include widget-min)
    return queries.map(({ className }) => className);
  }

  return queries
    .filter(
      ({ maxWidth = Infinity, minWidth = 0, className }) =>
        widgetWidth <= maxWidth && widgetWidth >= minWidth,
    )
    .map(({ className }) => className)
    .join(' ');
}

export function withQueryWidgetWidth(
  Component: ComponentType<{ queryWidgetWidth: (queries: Query[]) => string }>,
) {
  return (props: any) => {
    const { widgetWidth } = useWidgetWidth();
    const { isSSR } = useEnvironment();

    const queryWidgetWidth = (queries: Query[]) =>
      getClassNames(queries, widgetWidth, isSSR);

    return <Component queryWidgetWidth={queryWidgetWidth} {...props} />;
  };
}
