import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/common';
import { RootState } from '../redux/root-state';

const {
  INITIAL,
  PLAY_REQUESTED,
  PAUSE_REQUESTED,
  PLAYING,
  PAUSED,
  ENDED,
  PLAY_ABORTED,
} = VIDEO_PLAYBACK_STATUSES;

const isInStatus =
  (status: VIDEO_PLAYBACK_STATUSES) =>
  (state: Pick<RootState, 'playback'>, id: string) => {
    const playbackState = state.playback.videos[id];

    if (!playbackState) {
      return status === INITIAL;
    }

    return playbackState === status;
  };

export const isInitial = isInStatus(INITIAL);
export const isPlayRequested = isInStatus(PLAY_REQUESTED);
export const isPauseRequested = isInStatus(PAUSE_REQUESTED);
export const isPlaying = isInStatus(PLAYING);
export const isPlayAborted = isInStatus(PLAY_ABORTED);
export const isPaused = isInStatus(PAUSED);
export const isEnded = isInStatus(ENDED);
export const isMuted = (state: Pick<RootState, 'playback'>) =>
  state.playback.muted;

export const isPlayingOptimistic = (
  state: Pick<RootState, 'playback'>,
  id: string,
) => isInStatus(PLAY_REQUESTED)(state, id) || isInStatus(PLAYING)(state, id);

export const isPausedOptimistic = (
  state: Pick<RootState, 'playback'>,
  id: string,
) => isInStatus(PAUSE_REQUESTED)(state, id) || isInStatus(PAUSED)(state, id);
