import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  EngineState as ENGINE_STATES,
  VIDEO_EVENTS,
} from 'playable/dist/src/constants';
import { withHandlersAndState } from '../../../../../../containers/withHandlersAndState';

const PING_INTERVAL = 5000;

class LiveVideoCounter extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object,
    eventEmitter: PropTypes.object,
    canAccessFullVideo: PropTypes.bool,
    handlers: PropTypes.object,
    controllerState: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.streamPingUrl = get(props, 'videoItem.streamPingUrl');
  }

  componentDidMount() {
    const { eventEmitter, canAccessFullVideo } = this.props;
    if (!this.streamPingUrl || !canAccessFullVideo) {
      return;
    }
    eventEmitter.on(VIDEO_EVENTS.STATE_CHANGED, this.onStateChanged);
  }

  componentWillUnmount() {
    const { eventEmitter } = this.props;
    this.stopPollCounterService();
    eventEmitter.off(VIDEO_EVENTS.STATE_CHANGED, this.onStateChanged);
  }

  onStateChanged = ({ nextState, prevState }) => {
    if (nextState === ENGINE_STATES.PLAYING && nextState !== prevState) {
      this.startPollCounterService();
    } else if (nextState === ENGINE_STATES.PAUSED) {
      this.stopPollCounterService();
    }
  };

  startPollCounterService = () => {
    if (this.counterIntervalId) {
      return;
    }
    this.counterIntervalId = setInterval(
      this.pingCounterService,
      PING_INTERVAL,
    );
  };

  stopPollCounterService = () => {
    clearInterval(this.counterIntervalId);
    this.counterIntervalId = null;
  };

  pingCounterService = () => {
    this.props.handlers.pingCounterService(this.streamPingUrl);
  };

  render() {
    return null;
  }
}

export default withHandlersAndState(LiveVideoCounter);
