import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import decorate from '../../../../utils/decorate';
import { playVideo } from '../../../../redux/actions/player/change-playback-status';
import { selectVideo } from '../../../../redux/actions/select-video';

import InFrameActions from './in-frame';

const mapStateToProps = null;

const mapDispatchToProps = {
  playVideo,
  selectVideo,
};

export default decorate(InFrameActions)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class InFrameMobileActionCallbacks extends React.Component {
      static propTypes = {
        children: PropTypes.element.isRequired,
        selectVideo: PropTypes.func.isRequired,
        playVideo: PropTypes.func.isRequired,
        onBeforeClick: PropTypes.func,
        onBeforePlayRequested: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        videoItem: PropTypes.object,
        isMainVideo: PropTypes.bool,
      };

      selectVideo = () => {
        const { selectVideo, videoId } = this.props;
        selectVideo(videoId);
      };

      playVideo = () => {
        const { playVideo, videoId } = this.props;
        this.props.onPlayRequestedBi();
        playVideo(videoId);
      };

      handlePlayRequest = () => {
        const { isMainVideo, onBeforePlayRequested, onBeforeClick } =
          this.props;
        const handler = isMainVideo
          ? _.flow(onBeforePlayRequested, this.playVideo)
          : _.flow(onBeforeClick, this.selectVideo);

        handler();
      };

      render() {
        const { children } = this.props;

        return React.cloneElement(children, {
          ...this.props,
          onPlayRequest: this.handlePlayRequest,
        });
      }
    },
  ),
);
