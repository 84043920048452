import React from 'react';
import { MessageWithPrice } from '../components/message-with-price/message-with-price';
import { DealInfo, DealInfoType } from '../redux/types';
import { useTranslation } from '@wix/yoshi-flow-editor';

const Trans: React.FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();
  return <>{t(id)}</>;
};

export default function formatMessageWithPrice(id: string, info: DealInfo) {
  const { currency, price, type } = info;
  if (type === DealInfoType.PLAN) {
    return <Trans id={id} />;
  }

  return <MessageWithPrice currency={currency} price={price} messageId={id} />;
}
