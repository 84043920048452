import { sides } from '../../constants/popout';

export default function invertSide(side) {
  return (
    {
      [sides.TOP]: sides.BOTTOM,
      [sides.LEFT]: sides.RIGHT,
      [sides.RIGHT]: sides.LEFT,
      [sides.BOTTOM]: sides.TOP,
    }[side] || sides.NONE
  );
}
