import { createSelector } from 'reselect';
import {
  getChannelForWidget,
  isSubscribedTo,
  isSubscriptionWasCancelled,
} from '@wix/wix-vod-shared/common';
import { isSubscriptionButtonVisible } from '../components/overlay-texts/channel/subscribe-button-text-utils';

export const canShowSubscription = createSelector(
  getChannelForWidget,
  (channel) => isSubscriptionButtonVisible({ channel }),
);

export const canShowCancelSubscription = createSelector(
  getChannelForWidget,
  (channel) => isSubscribedTo(channel) && !isSubscriptionWasCancelled(channel),
);
