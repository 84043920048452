import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { subscribeButtonText } from '../../ui-selectors/buttons';

const mapStateToProps = (state, props) => ({
  text: subscribeButtonText(state, props),
});

export default connect(mapStateToProps)(
  class HomeView extends React.Component {
    static propTypes = {
      text: PropTypes.element,
      videoItem: PropTypes.object,

      onClick: PropTypes.func.isRequired,

      className: PropTypes.string,
    };

    render() {
      const { text, className, onClick } = this.props;
      return text ? (
        <div
          data-hook="subscribe-button"
          className={className}
          onClick={onClick}
        >
          {text}
        </div>
      ) : null;
    }
  },
);
