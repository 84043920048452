import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';

import { canShowFullInfo } from '../selectors/app-settings';
import { canShareVideo } from '../selectors/video-share';

const mapStateToProps = (state) => ({
  canShowVideoInfoButton: canShowFullInfo(state),
  canShowShareButton: canShareVideo(state),
});

export default connect(mapStateToProps)(
  class PlayerBlocksVisibility extends React.Component {
    static propTypes = {
      canShowVideoInfoButton: PropTypes.bool.isRequired,
      canShowShareButton: PropTypes.bool.isRequired,

      children: PropTypes.func.isRequired,
    };

    render() {
      const { canShowVideoInfoButton, canShowShareButton, children } =
        this.props;

      return children({
        canShowVideoInfoButton,
        canShowShareButton,
      });
    }
  },
);
