import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import styles from './slider-empty-state.scss';

export const SliderEmptyState: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.sliderEmptyState}>
      {t('widget.this-channel-is-coming-soon')}
    </div>
  );
};
