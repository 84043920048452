import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { getVideosGroupedByIds } from '../../../selectors/videos';
import { getPublicVideo } from '../channel/video/get';
import { createAsyncProxy } from '../../../worker/lib';

export const ensureVideoLoaded = createAsyncProxy(
  'ensureVideoLoaded',
  (videoId) => (dispatch, getState) => {
    const state = getState();
    const videoByIds = getVideosGroupedByIds(state);
    const channel = getChannelForWidget(state);

    return videoByIds[videoId]
      ? Promise.resolve()
      : dispatch(getPublicVideo(channel.id, videoId));
  },
);
