import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestPlayVideoOnload } from '../../redux/actions/request-play-video-onload';
import { getMainVideoId } from '@wix/wix-vod-shared/widget';
import { getAutoplay } from '../../selectors/app-settings';
import { getMainVideo } from '../../selectors/get-video';
import { getVideoIdFromUrl } from '../../selectors/query-params';
import * as viewModeSelectors from '../../selectors/view-mode';
import { Video } from '../../redux/types';

type Props = {
  isMobile?: boolean;
  onAutoPlayRequested: (playedVideo: Video) => void;
};

export const AutoPlayVideo = ({
  isMobile = false,
  onAutoPlayRequested,
}: Props) => {
  const dispatch = useDispatch();

  const isAutoPlay = useSelector(getAutoplay);
  const mainVideo = useSelector(getMainVideo);
  const mainVideoId = useSelector(getMainVideoId);
  const videoIdFromUrl = useSelector(getVideoIdFromUrl);
  const isSite = useSelector(viewModeSelectors.isSiteMode);
  const isStandalone = useSelector(viewModeSelectors.isStandalone);

  useEffect(() => {
    const startVideo = () => {
      if (isAutoPlay && !videoIdFromUrl && (isSite || isStandalone)) {
        if (onAutoPlayRequested) {
          onAutoPlayRequested(mainVideo);
        }
        dispatch(requestPlayVideoOnload(mainVideoId, isMobile));
      }
    };

    startVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
