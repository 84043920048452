import _ from 'lodash';
import { createSelector } from 'reselect';
import { getMainItemOverlayOpacity } from '../../../../../selectors/app-settings';

export const getOpacityStyles = createSelector(
  getMainItemOverlayOpacity,
  (mainItemOverlayOpacity) => {
    const opacity = _.round(mainItemOverlayOpacity / 100, 2);

    return {
      backgroundColor: `rgba(0,0,0,${opacity})`,
    };
  },
);
