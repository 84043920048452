export const MIN_INLINE_SHARE_THUMBNAIL_WIDTH = 399;
export const MOBILE_WIDGET_WIDTH = 320;
export const DEFAULT_VIDEO_WIDTH = 320;
export const DEFAULT_VIDEO_HEIGHT = 180;
export const MAX_COVER_IMAGE_WIDTH = 1280;
export const MAX_COVER_IMAGE_HEIGHT = 720;
export const VERTICAL_LAYOUT_HEADER_HEIGHT = 40;
export const VERTICAL_LAYOUT_VIDEO_HEIGHT = 220;
export const VERTICAL_LAYOUT_VIDEO_MARGIN_BOTTOM = 2;
export const VERTICAL_LAYOUT_LOAD_MORE_BUTTON_HEIGHT = 66;
