import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import decorate from '../../../../utils/decorate';
import { notForPreview } from '../../../../utils/not-for-preview';

import { selectVideo } from '../../../../redux/actions/select-video';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from '../../../../redux/actions/full-screen-modal';

import InFrameActions from './in-frame';

const mapStateToProps = null;

const mapDispatchToProps = {
  selectVideo,
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
};

export default decorate(InFrameActions)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class InOverlayActionCallbacks extends React.Component {
      static propTypes = {
        children: PropTypes.element.isRequired,
        channelId: PropTypes.string,
        videoId: PropTypes.string,
        selectVideo: PropTypes.func,
        onSelectOrPlay: PropTypes.func,
        onPlayRequestedBi: PropTypes.func,
        openFullScreenVideoOverlay: PropTypes.func,
        closeFullScreenVideoOverlay: PropTypes.func,
        videoItem: PropTypes.object,
      };

      static defaultProps = {
        onPlayRequestedBi: _.noop,
      };

      showVideoAtOverlay = notForPreview(() => {
        const {
          channelId,
          videoId,
          openFullScreenVideoOverlay,
          closeFullScreenVideoOverlay,
        } = this.props;
        openFullScreenVideoOverlay(
          channelId,
          videoId,
          null,
          closeFullScreenVideoOverlay,
        );
      });

      playVideoAtOverlay = notForPreview(() => {
        const {
          channelId,
          videoId,
          openFullScreenVideoOverlay,
          closeFullScreenVideoOverlay,
        } = this.props;
        this.props.onPlayRequestedBi();
        openFullScreenVideoOverlay(
          channelId,
          videoId,
          true,
          closeFullScreenVideoOverlay,
        );
      });

      render() {
        return React.cloneElement(this.props.children, {
          ...this.props,
          onPlayRequest: this.playVideoAtOverlay,
          onPlayWithoutPreviewRequest: this.showVideoAtOverlay,
        });
      }
    },
  ),
);
