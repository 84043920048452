import React, { FC } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

type Props = {
  messageId: string;
  currency: string;
  price: number;
};

export const MessageWithPrice: FC<Props> = ({ messageId, currency, price }) => {
  const { t, i18n } = useTranslation();

  const formattedPrice = new Intl.NumberFormat(i18n.language, {
    style: 'currency',
    currency,
    minimumFractionDigits: price % 1 > 0 ? 2 : 0,
    minimumIntegerDigits: 1,
  }).format(price);

  return t(messageId, {
    price: formattedPrice,
  });
};
