import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import WixEvents from '../constants/wix-sdk';
import { withPubSubEvents, consumePubSubEvent } from './pub-sub-events';

import { setHydratedData } from '../redux/hydrated-data/hydrated-data';
import * as viewModeSelectors from '../selectors/view-mode';

const mapStateToProps = (state) => ({
  viewMode: viewModeSelectors.getViewMode(state),
});

const mapDispatchToProps = { setHydratedData };

export const ViewModeObserver = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withPubSubEvents(
    class ViewModeObserver extends React.Component {
      static propTypes = {
        setHydratedData: PropTypes.func.isRequired,
        onChange: PropTypes.func,
        viewMode: PropTypes.string,
      };

      static defaultProps = {
        onChange: _.noop,
      };

      componentDidUpdate(prevProps) {
        consumePubSubEvent(
          WixEvents.EDIT_MODE_CHANGE,
          this.handleEditModeChange,
          {
            prevProps,
            nextProps: this.props,
          },
        );
      }

      handleEditModeChange = ({ editMode }) => {
        const { viewMode, setHydratedData, onChange } = this.props;

        onChange({ prevViewMode: viewMode, viewMode: editMode });
        setHydratedData({ viewMode: editMode });
      };

      render() {
        return null;
      }
    },
  ),
);
