import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  getResizedImageUrl,
  ImageParams,
  getNormalizedCachedDimensions,
  MODES,
} from '@wix/wix-vod-shared/common';
import classnames from 'classnames';
import styles from './resized-image.scss';

interface ResizedImageProps {
  src?: string;
  width: number;
  height: number;
  params?: ImageParams;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactChild;
  dataHook?: string;
  withFallbackColor?: boolean;
  backgroundFit?: boolean;
  isMobile?: boolean;
}

export default class ResizedImage extends React.Component<ResizedImageProps> {
  static propTypes = {
    src: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    params: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node,
    dataHook: PropTypes.string,
    withFallbackColor: PropTypes.bool,
    backgroundFit: PropTypes.bool,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    params: {},
    style: {},
  };

  getResizedImageUrl(src: string) {
    const { width, height, params, backgroundFit } = this.props;
    const dimensions = getNormalizedCachedDimensions(src, {
      containerWidth: width,
      containerHeight: height,
    });
    const mode = backgroundFit ? MODES.fit : undefined;
    const devicePixelRatio = window?.devicePixelRatio ?? 1;
    const pixelRatios = Array.from(new Set([1, 2, devicePixelRatio]));
    const urls = Array.from(
      pixelRatios.reduce(
        (map, ratio) =>
          map.set(
            getResizedImageUrl({
              url: src,
              width: dimensions[0] * ratio,
              height: dimensions[1] * ratio,
              params,
              mode,
            }),
            ratio,
          ),
        new Map(),
      ),
    );

    if (urls.length === 1) {
      return `url(${urls[0][0]})`;
    }
    return `-webkit-image-set(${urls
      .map(([url, ratio]) => `url(${url})  ${ratio}x`)
      .join(',')})`;
  }

  render() {
    const {
      className,
      width,
      height,
      style,
      children,
      dataHook,
      withFallbackColor,
      backgroundFit,
      isMobile,
      src,
    } = this.props;

    const inlineStyle: React.CSSProperties = {
      width: typeof width === 'number' ? width && _.round(width) : width,
      height: typeof height === 'number' ? height && _.round(height) : height,
      ...style,
    };

    if (src) {
      inlineStyle.backgroundImage = this.getResizedImageUrl(src);
    }

    const classNames = classnames(className, styles['resized-image'], {
      [styles['with-fallback-color']]: withFallbackColor && !src,
      [styles['background-contain']]: backgroundFit,
      [styles.mobile]: isMobile,
    });

    return (
      <div
        className={classNames}
        style={inlineStyle}
        data-hook={dataHook}
        role="presentation"
      >
        {children}
      </div>
    );
  }
}
