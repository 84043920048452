import { createAction } from 'redux-actions';
import { INITIATORS } from '../../../utils/initiators';

export const OPEN_SUBSCRIBE_OVERLAY = 'OPEN.SUBSCRIBE-OVERLAY';
const OPEN_SUBSCRIBE_OVERLAY_ACTION = createAction(OPEN_SUBSCRIBE_OVERLAY);

export const OPEN_BUY_VIDEO_OVERLAY = 'OPEN.BUY-VIDEO-OVERLAY';
const OPEN_BUY_VIDEO_OVERLAY_ACTION = createAction(OPEN_BUY_VIDEO_OVERLAY);

export const openSubscribeOverlayFromWidget = (openMobileOverlay) =>
  openSubscribeOverlay(openMobileOverlay, INITIATORS.WIDGET);

export const openBuyVideoOverlayFromWidget = (openMobileOverlay, videoId) =>
  openBuyVideoOverlay(openMobileOverlay, videoId, INITIATORS.WIDGET);

export const openSubscribeOverlay = (openMobileOverlay, initiator = '') => {
  return function (dispatch) {
    dispatch(OPEN_SUBSCRIBE_OVERLAY_ACTION());

    openMobileOverlay(`/channel/subscribe/${initiator}`);
  };
};

export const openBuyVideoOverlay = (
  openMobileOverlay,
  videoId,
  initiator = '',
) => {
  return function (dispatch) {
    dispatch(OPEN_BUY_VIDEO_OVERLAY_ACTION());

    openMobileOverlay(`/video/${videoId}/purchase/${initiator}`);
  };
};
