import { TEXT_LABELS as labels } from 'playable/dist/src/constants';
import { t } from './translation';

export default () => ({
  [labels.LOGO_LABEL]: t('widget.player.watch-on-site-label'),
  [labels.LOGO_TOOLTIP]: t('widget.player.watch-on-site-tooltip'),
  [labels.PAUSE_CONTROL_LABEL]: t('widget.player.pause-control-label'),
  [labels.PLAY_CONTROL_LABEL]: t('widget.player.play-control-label'),
  [labels.PROGRESS_CONTROL_LABEL]: t('widget.player.progress-control-label'),
  [labels.PROGRESS_CONTROL_VALUE]: ({ percent = 0 }) =>
    t('widget.player.progress-control-value', { percent }),
  [labels.MUTE_CONTROL_LABEL]: t('widget.player.mute-control-label'),
  [labels.MUTE_CONTROL_TOOLTIP]: t('widget.player.mute-control-tooltip'),
  [labels.UNMUTE_CONTROL_LABEL]: t('widget.player.unmute-control-label'),
  [labels.UNMUTE_CONTROL_TOOLTIP]: t('widget.player.unmute-control-tooltip'),
  [labels.VOLUME_CONTROL_LABEL]: t('widget.player.volume-control-label'),
  [labels.VOLUME_CONTROL_VALUE]: ({ volume = 0 }) =>
    t('widget.player.volume-control-value', { percent: volume }),
  [labels.ENTER_FULL_SCREEN_LABEL]: t('widget.player.enter-full-screen-label'),
  [labels.ENTER_FULL_SCREEN_TOOLTIP]: t(
    'widget.player.enter-full-screen-tooltip',
  ),
  [labels.EXIT_FULL_SCREEN_LABEL]: t('widget.player.exit-full-screen-label'),
  [labels.EXIT_FULL_SCREEN_TOOLTIP]: t(
    'widget.player.exit-full-screen-tooltip',
  ),
  [labels.LIVE_INDICATOR_TEXT]: ({ isEnded }) =>
    isEnded
      ? t('widget.player.live-ended-indicator')
      : t('widget.player.live-indicator'),
  [labels.LIVE_SYNC_LABEL]: t('widget.player.live-sync-label'),
  [labels.LIVE_SYNC_TOOLTIP]: t('widget.player.live-sync-tooltip'),
  [labels.ENTER_PICTURE_IN_PICTURE_TOOLTIP]: t(
    'widget.player.enter_picture_in_picture_tooltip',
  ),
  [labels.ENTER_PICTURE_IN_PICTURE_LABEL]: t(
    'widget.player.enter_picture_in_picture_label',
  ),
  [labels.EXIT_PICTURE_IN_PICTURE_LABEL]: t(
    'widget.player.exit_picture_in_picture_label',
  ),
  [labels.EXIT_PICTURE_IN_PICTURE_TOOLTIP]: t(
    'widget.player.exit_picture_in_picture_tooltip',
  ),
});
