import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { REFRESH_EVENT, storeForReload } from '../../utils/reload';
import { connect } from 'react-redux';
import { getCompId } from '../../redux/hydrated-data/hydrated-data';
import { withPubSubEvents } from '../../containers/pub-sub-events';

const RELOAD_STORAGE_READY = 'reloadStorageReady';

const mapStateToProps = (state) => ({ compId: getCompId(state) });
const mapDispatchToProps = { storeForReload };

export const PersistentEvents = withPubSubEvents(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class PersistentEvents extends React.Component {
      static propTypes = {
        onEvent: PropTypes.func,
      };

      static defaultProps = {
        onEvent: _.noop,
      };

      componentDidMount() {
        this.props.storeForReload(RELOAD_STORAGE_READY);
      }

      componentDidUpdate() {
        if (this.props.pubSubEvents) {
          this.props.pubSubEvents[REFRESH_EVENT].forEach(this.saveEventData);
        }
      }

      componentWillUnmount() {
        this.unsubscribe();
      }

      lastEventData = {};
      lastHandledEventData = {};

      unsubscribe() {
        this.props.storeForReload(null);
      }

      saveEventData = ({ data }) => {
        if (!data || data.stored !== RELOAD_STORAGE_READY) {
          this.lastEventData = data || {};
        }

        this.retrieveDataAfterReload();
      };

      retrieveDataAfterReload = _.debounce(() => {
        const { stored, rootCompId } = this.lastEventData;

        if (
          rootCompId === this.props.compId &&
          stored &&
          !_.isEqual(this.lastHandledEventData, this.lastEventData)
        ) {
          this.props.onEvent(stored);
          this.lastHandledEventData = this.lastEventData;
        }

        this.lastEventData = {};
      }, 300);

      render() {
        return null;
      }
    },
  ),
);
