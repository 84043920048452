import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  isScheduledLive,
  isPendingLiveStream,
  isReadyLiveStream,
} from '@wix/wix-vod-shared/common';
import { getLocale, isRTL } from '../../selectors/app-settings';
import { LiveLabel } from '@wix/wix-vod-shared/components';
import { LIVE_VIDEO_STATUS } from '@wix/wix-vod-constants/common';

import { withTranslation } from '@wix/yoshi-flow-editor';

import ReminderIcon from './bell.svg';

const mapStateToProps = (state, { itemType, startTime, liveVideoStatus }) => ({
  startTime,
  isScheduled: isScheduledLive({ itemType }) && Boolean(startTime),
  isPendingLiveStream: isPendingLiveStream({ liveVideoStatus }),
  isReadyLiveStream: isReadyLiveStream({ liveVideoStatus }),
  locale: getLocale(state),
  isRTL: isRTL(state),
});

export default withTranslation()(
  connect(mapStateToProps)(
    class LiveLabelContainer extends React.Component {
      static propTypes = {
        isPendingLiveStream: PropTypes.bool,
        isReadyLiveStream: PropTypes.bool,
        locale: PropTypes.string,
        startTime: PropTypes.number,
        videoTitle: PropTypes.string,
        isRTL: PropTypes.bool,
        liveVideoStatus: PropTypes.oneOf(LIVE_VIDEO_STATUS),
      };

      render() {
        return (
          <LiveLabel
            {...this.props}
            liveText={this.props.t('shared.live')}
            pendingLiveText={this.props.t('shared.pending-live')}
            scheduledSoonAriaLabel={this.props.t(
              'widget.accessibility.scheduled-soon-label',
            )}
            scheduledAriaLabel={this.props.t(
              'widget.accessibility.scheduled-label',
            )}
            startsInAriaLabel={this.props.t('widget.accessibility.starts-in')}
            reminderAriaLabel={this.props.t('widget.accessibility.reminder')}
            reminderIcon={<ReminderIcon />}
          />
        );
      }
    },
  ),
);
