import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { isVODChannel } from '../utils/channel-helpers';
import {
  canShowChannelCategories,
  canShowSearch,
  canShowChannelTitle,
  getCanShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
} from './app-settings';
import { isSubscriptionButtonVisible } from '../components/overlay-texts/channel/subscribe-button-text-utils';

const isWidgetChannelVODChannel = createSelector(
  getChannelForWidget,
  (channel) => isVODChannel(channel),
);

export const isCategoriesDropdownVisible = createSelector(
  isWidgetChannelVODChannel,
  canShowChannelCategories,
  (isVod, canShow) => isVod && canShow,
);

export const isSearchInputVisible = createSelector(
  isWidgetChannelVODChannel,
  canShowSearch,
  (isVod, canShow) => isVod && canShow,
);

export const isClassicLayoutSearchBarVisible = createSelector(
  isCategoriesDropdownVisible,
  isSearchInputVisible,
  (isCategories, isSearch) => isCategories || isSearch,
);

const getCanShowSubscriptionButton = createSelector(
  getChannelForWidget,
  (channel) => isSubscriptionButtonVisible({ channel }),
);

export const isClassicLayoutHeaderVisible = createSelector(
  canShowChannelTitle,
  getCanShowChannelShare,
  getCanShowChannelInfo,
  getCanShowSignIn,
  getCanShowSubscriptionButton,
  (isTitle, isChannelShare, isChannelInfo, isSignIn, isSubscription) =>
    isTitle || isChannelShare || isChannelInfo || isSignIn || isSubscription,
);
