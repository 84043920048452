import React from 'react';
import classNames from 'classnames';

import { getVideoCoverUrl } from '@wix/wix-vod-shared/common';
import { Picture } from '@wix/wix-vod-shared/components';
import { Video } from '../../redux/types';

import styles from './video-cover.scss';

const VideoCover: React.FC<{
  videoItem: Video;
  breakpoints: [];
  className: string;
}> = (props) => {
  const { videoItem, className, breakpoints } = props;

  return (
    <div className={classNames(styles.cover, className)}>
      <Picture
        alt={videoItem.title}
        src={getVideoCoverUrl(videoItem) ?? ''}
        breakpoints={breakpoints}
      />
    </div>
  );
};

export default VideoCover;
