import _ from 'lodash';
import { createSelector } from 'reselect';
import { MediaStreamType as MEDIA_STREAM_TYPES } from 'playable/dist/src/constants';

const getVideoUrls = (props) => props.videoUrls;

function toVidiTypes(url, type) {
  if (/mp4/i.test(type)) {
    return {
      url,
      type: MEDIA_STREAM_TYPES.MP4,
      name: /[0-9]+p/i.exec(type)[0],
    };
  }
  if (/mpd/i.test(type)) {
    return { url, type: MEDIA_STREAM_TYPES.DASH };
  }
  if (/m3u8/i.test(type)) {
    return { url, type: MEDIA_STREAM_TYPES.HLS };
  }
  if (/webm/i.test(type)) {
    return { url, type: MEDIA_STREAM_TYPES.WEBM };
  }
  return null;
}

export const getDownloadUrl = createSelector(
  [getVideoUrls],
  (videoUrls) => videoUrls && videoUrls.downloadUrl,
);

const getVidiUrls = createSelector([getVideoUrls], (videoUrls) =>
  _.map(videoUrls, toVidiTypes),
);

export default getVidiUrls;
