import React from 'react';
import { withTranslation } from '@wix/yoshi-flow-editor';
import { closeModal } from '../../utils/modal';
import { PrimaryButton } from '../buttons/buttons';
import { MODAL_CONTENT_TYPES } from '../modal/constants';
import styles from './preview-prompt.scss';

const PreviewModePrompt = withTranslation()(({ t }) => (
  <div className={styles['prompt-wrapper']} data-hook="preview-mode-prompt">
    <div>{t('widget.preview-prompt.you-in-preview.line1')}</div>

    <div className={styles['second-message']}>
      {t('widget.preview-prompt.you-in-preview.line2')}
    </div>

    <PrimaryButton
      dataHook="close-preview-prompt"
      className={styles['close-button']}
      onClick={closeModal}
    >
      {t('widget.preview-prompt.got-it')}
    </PrimaryButton>
  </div>
));

export const previewPrompt = {
  [MODAL_CONTENT_TYPES.PREVIEW_PROMPT]: <PreviewModePrompt />,
};
