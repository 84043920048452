import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from '@wix/yoshi-flow-editor';
import styles from './no-results.scss';

const NoResults = ({ className, dataHook, message, onButtonClick, t }) => (
  <section
    data-hook={dataHook}
    tabIndex="0"
    className={classnames(styles.container, className)}
  >
    <div>
      <div className={styles.title}>{message}</div>
      <button
        data-hook="reset-search-button"
        className={styles.button}
        onClick={onButtonClick}
      >
        {t('widget.search.clear-search')}
      </button>
    </div>
  </section>
);

NoResults.propTypes = {
  className: PropTypes.string,
  dataHook: PropTypes.string,
  message: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default withTranslation()(NoResults);
