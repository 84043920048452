import { createSelector } from 'reselect';

import { getSearchQuery } from './search';
import {
  getVideoIdsByPageNumber,
  getIsFetching,
} from '../redux/lazy-channel-videos/selectors';

export const getIsSearchResultEmpty = createSelector(
  [getIsFetching, getSearchQuery, getVideoIdsByPageNumber],
  (isVideosFetching, searchQuery, videoIdsByPageNumber) =>
    Boolean(!isVideosFetching && searchQuery && !videoIdsByPageNumber.length),
);
