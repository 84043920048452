import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import IconButton from '../../../../components/buttons/icon/icon';

import styles from './header-mobile.scss';

export default class HeaderMobile extends React.Component {
  static propTypes = {
    title: PropTypes.any,
    closeButtonLabel: PropTypes.string,
    onButtonClick: PropTypes.func,
  };

  static defaultProps = {
    onButtonClick: _.noop,
  };

  get title() {
    const { title } = this.props;

    if (!title) {
      return null;
    }

    return (
      <h1 data-hook="modal-title" className={styles.title}>
        {title}
      </h1>
    );
  }

  render() {
    const { onButtonClick, closeButtonLabel } = this.props;

    return (
      <header data-hook="modal-header" className={styles.header}>
        {this.title}
        <IconButton
          dataHook="header-close-button"
          className={styles['close-button']}
          onClick={onButtonClick}
          ariaLabel={closeButtonLabel}
          name="close"
        />
      </header>
    );
  }
}
