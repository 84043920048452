import _ from 'lodash';

const topics = {};

export default {
  subscribe(topic, listener) {
    if (!topics[topic]) {
      topics[topic] = [];
    }

    const index = topics[topic].push(listener) - 1;

    return function () {
      topics[topic].splice(index, 1);
    };
  },

  publish(topic, ...data) {
    if (!topics[topic]) {
      return;
    }

    _.forEach(topics[topic], function (item) {
      item(...data);
    });
  },
};
