import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSubscriptionButtonVisible } from '../overlay-texts/channel/subscribe-button-text-utils';
import ChannelSubscriptionLabel from '../channel-subscription-label/channel-subscription-label';
import classnames from 'classnames';
import { LinkButton } from '../buttons/buttons';
import { getDealInfo } from '@wix/wix-vod-shared/common';
import styles from './channel-actions.scss';

const mapStateToProps = (state, ownProps) => ({
  dealInfo: getDealInfo(ownProps),
  isVisible: isSubscriptionButtonVisible({ channel: ownProps.channelData }),
});

export default connect(mapStateToProps)(
  class ChannelSubscriptionButton extends React.Component {
    static propTypes = {
      channelData: PropTypes.object,
      dealInfo: PropTypes.array,
      className: PropTypes.string,
      onClick: PropTypes.func,
      isVisible: PropTypes.bool,
      isFocusable: PropTypes.bool,
    };

    render() {
      const {
        isVisible,
        dealInfo,
        className,
        onClick,
        isFocusable,
        channelData,
      } = this.props;

      if (!isVisible) {
        return null;
      }

      return (
        <LinkButton
          className={classnames(className, styles.action)}
          dataHook="channel-subscription-button"
          isFocusable={isFocusable}
          onClick={onClick}
        >
          <ChannelSubscriptionLabel
            dealInfo={dealInfo}
            isVisible={isVisible}
            channel={channelData}
          />
        </LinkButton>
      );
    }
  },
);
