import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  isLoadMoreDisplayedAsLink,
  areElementsRounded,
  canShowVideoListItemContentBelow,
} from '../../../../selectors/app-settings';

import styles from './load-more-button.scss';

const mapStateToProps = (state) => ({
  isRounded: areElementsRounded(state),
  isLink: isLoadMoreDisplayedAsLink(state),
  isThumbnailsHaveContentBelow: canShowVideoListItemContentBelow(state),
});

export default connect(mapStateToProps)(
  class LoadMoreButton extends React.Component {
    static propTypes = {
      isRounded: PropTypes.bool.isRequired,
      isThumbnailsHaveContentBelow: PropTypes.bool.isRequired,
      isLink: PropTypes.bool,
      ariaLabel: PropTypes.string,
      dataHook: PropTypes.string,
      children: PropTypes.node,
      onClick: PropTypes.func,
      isLoading: PropTypes.bool,
    };

    static defaultProps = {
      isLink: false,
    };

    getClassName() {
      const { isLink, isRounded } = this.props;

      if (isLink) {
        return styles.link;
      }

      if (isRounded) {
        return styles.rounded;
      }

      return null;
    }

    render() {
      const {
        isThumbnailsHaveContentBelow,
        isLoading,
        children,
        onClick,
        ariaLabel,
        dataHook,
      } = this.props;

      const className = classnames(styles.button, this.getClassName(), {
        [styles.loading]: isLoading,
      });

      return (
        <div
          className={classnames(styles.container, {
            [styles.responsive]: isThumbnailsHaveContentBelow,
          })}
        >
          <button
            aria-label={ariaLabel}
            data-hook={dataHook}
            tabIndex="0"
            onClick={onClick}
            className={className}
          >
            {children}
          </button>
        </div>
      );
    }
  },
);
