import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import styles from './buttons.scss';

export class GenericButton extends React.Component {
  static propTypes = {
    label: PropTypes.any,
    ariaLabel: PropTypes.string,
    children: PropTypes.any,
    roundedRect: PropTypes.bool,
    renderAsLink: PropTypes.bool,
    onClick: PropTypes.func,
    getRef: PropTypes.func,
    dataHook: PropTypes.string,
    isFocusable: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    isFocusable: true,
    roundedRect: false,
    renderAsLink: false,
    onClick: _.noop,
    getRef: _.noop,
  };

  handleLinkClick = (event) => {
    event.preventDefault();
    this.props.onClick(event);
  };

  render() {
    const {
      label,
      ariaLabel,
      children,
      roundedRect,
      getRef,
      renderAsLink,
      dataHook,
      isFocusable,
      className,
    } = this.props;

    const styleNames = classnames(className, styles.button, {
      [styles['rounded-rect']]: roundedRect,
    });

    const props = _({})
      .assign(this.props)
      .assign({
        children: label || children,
        className: styleNames,
        ref: getRef,
        'aria-label': ariaLabel,
        tabIndex: isFocusable ? 0 : -1,
        'data-hook': dataHook,
      })
      .omit([
        'ariaLabel',
        'styles',
        'label',
        'roundedRect',
        'renderAsLink',
        'getRef',
        'intl',
        'settings',
        'biEvent',
        'biError',
        'dataHook',
        'isFocusable',
      ])
      .value();

    if (!renderAsLink) {
      return <button {...props} />;
    }

    return <a href="" {...props} onClick={this.handleLinkClick} />;
  }
}

function generateButtonVariant(styleName) {
  const className = styleName.split(' ').map((name) => styles[name]);

  class ButtonVariant extends React.PureComponent {
    static displayName = _.flow(_.camelCase, _.upperFirst)(styleName);

    render() {
      const props = _.omit(this.props, 'styles');

      return (
        <GenericButton
          {...props}
          className={classnames(props.className, className)}
        />
      );
    }
  }

  return ButtonVariant;
}

export const LinkButton = generateButtonVariant('link-button');
export const PrimaryButton = generateButtonVariant('primary-button');
export const SecondaryButton = generateButtonVariant('secondary-button');
