import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useLayoutEffect } from 'react';

export function useLoadMadeFor() {
  const { isSSR } = useEnvironment();

  useLayoutEffect(() => {
    if (isSSR) {
      return;
    }

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href =
      'https://static.parastorage.com/unpkg/@wix/wix-fonts@1.14.0/madefor.min.css';
    document.head.appendChild(link);
  }, [isSSR]);
}
