import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getWixSDK } from '../../utils/wix-sdk';
import { getVodSettings } from '../../api/private/get-vod-settings';
import { isUnsavedTemplateSite } from '../../redux/hydrated-data/hydrated-data';

const mapStateToProps = (state) => ({
  isUnsavedTemplateSite: isUnsavedTemplateSite(state),
});

export default connect(mapStateToProps)(
  class PremiumIntentUpdater extends React.Component {
    static propTypes = {
      isUnsavedTemplateSite: PropTypes.bool.isRequired,
    };

    async componentDidMount() {
      const { isUnsavedTemplateSite } = this.props;

      if (isUnsavedTemplateSite) {
        return;
      }

      const vodSettings = await getVodSettings();

      const WixSDK = getWixSDK();

      WixSDK.Settings.appEngaged(
        vodSettings.biUserUploadedFiles
          ? WixSDK.Settings.PremiumIntent.PAID
          : WixSDK.Settings.PremiumIntent.FREE,
      );
    }

    render() {
      return null;
    }
  },
);
