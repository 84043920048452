import React from 'react';
import PropTypes from 'prop-types';
import styles from './no-videos-overlay.scss';
import { withTranslation } from '@wix/yoshi-flow-editor';

export default withTranslation()(
  class NoVideosOverlay extends React.Component {
    static propTypes = {
      title: PropTypes.string,
      dataHook: PropTypes.string,
    };

    render() {
      const { title } = this.props;

      return (
        <div className={styles.overlay} data-hook="no-videos-overlay">
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.comingSoon}>
              {this.props.t('widget.overlay-buttons.coming-soon')}
            </div>
          </div>
        </div>
      );
    }
  },
);
