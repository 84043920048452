import _ from 'lodash';
import { createSelector } from 'reselect';
import { getAllSettings } from '../../../../selectors/app-settings';
import { MAIN_ITEM_TYPE_VALUES } from '@wix/wix-vod-constants/app-settings';

export const isMainItemChannelCover = createSelector(
  [getAllSettings],
  (appSettings) =>
    _.get(appSettings, 'numbers.mainItemType') ===
    MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER,
);
