import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getPublicChannel } from '../../redux/actions/channel/get-public';
import { getPaymentInfo } from '../../redux/actions/channel/payment';
import { resetSearch } from '../../redux/actions/search';
import { resetSelectedVideo } from '../../redux/actions/select-video';
import { pauseVideo } from '../../redux/actions/player/change-playback-status';
import { closeModal } from '../../utils/modal';
import { ViewModeObserver } from '../../containers/view-mode-observer';

import { loadMoreVideoPages } from '../../redux/lazy-channel-videos/actions';
import { getAllSettings } from '../../selectors/app-settings';
import { isUnsavedTemplateSite } from '../../redux/hydrated-data/hydrated-data';
import events from '../../constants/events';
import * as viewModeSelectors from '../../selectors/view-mode';
import {
  consumePubSubEvent,
  withPubSubEvents,
} from '../../containers/pub-sub-events';
import WixEvents from '../../constants/wix-sdk';
import { getCurrentChannelId } from '../../selectors/channels';
import { getCurrentVideoId } from '../../selectors/video';

const IGNORED_KEYS = ['previewType'];

const getChangedKeys = (target, source) => {
  const keys = _.union(_.keys(target), _.keys(source));
  return _.filter(
    keys,
    (key) => !_.includes(IGNORED_KEYS, key) && target[key] !== source[key],
  );
};

const getAppSettingsDifference = (props, nextProps) => {
  const { booleans: nextBooleans, numbers: nextNumbers } =
    nextProps.appSettings;
  const { booleans: thisBooleans, numbers: thisNumbers } = props.appSettings;

  return {
    booleans: getChangedKeys(nextBooleans, thisBooleans),
    numbers: getChangedKeys(nextNumbers, thisNumbers),
  };
};

const hasChangesInAppSettings = (props, nextProps) => {
  const { booleans, numbers } = getAppSettingsDifference(props, nextProps);
  return booleans.length || numbers.length;
};

const mapStateToProps = (state) => ({
  currentChannelId: getCurrentChannelId(state),
  currentVideoId: getCurrentVideoId(state),
  appSettings: getAllSettings(state),
  isEditor: viewModeSelectors.isEditorMode(state),
  isUnsavedTemplateSite: isUnsavedTemplateSite(state),
});

const mapDispatchToProps = {
  getPublicChannel,
  getPaymentInfo,
  resetSearch,
  resetSelectedVideo,
  pauseVideo,
  fetchVideos: loadMoreVideoPages,
};

export const EditorObserver = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withPubSubEvents(
    class EditorObserver extends React.Component {
      static propTypes = {
        currentChannelId: PropTypes.string.isRequired,
        currentVideoId: PropTypes.string,
        appSettings: PropTypes.object.isRequired,
        fetchVideos: PropTypes.func.isRequired,
        getPublicChannel: PropTypes.func.isRequired,
        getPaymentInfo: PropTypes.func.isRequired,
        resetSearch: PropTypes.func.isRequired,
        resetSelectedVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        isEditor: PropTypes.bool.isRequired,
        isUnsavedTemplateSite: PropTypes.bool.isRequired,
      };

      componentDidUpdate(prevProps) {
        consumePubSubEvent(
          WixEvents.SETTINGS_UPDATED,
          this.reloadOnDashboardClosing,
          {
            prevProps,
            nextProps: this.props,
          },
        );
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (
          nextProps.isEditor &&
          hasChangesInAppSettings(this.props, nextProps)
        ) {
          this.reset();
        }
      }

      handleEditModeChange = () => {
        this.reset();
        closeModal();
      };

      reloadOnDashboardClosing = (event) => {
        if (event === events.DASHBOARD.CLOSED) {
          this.reloadData();
          this.reset();
        }
      };

      reset = () => {
        const { resetSearch, resetSelectedVideo, pauseVideo } = this.props;

        pauseVideo();
        resetSearch();
        resetSelectedVideo();
      };

      reloadData() {
        const { currentChannelId } = this.props;
        const { getPublicChannel, getPaymentInfo, fetchVideos } = this.props;

        getPublicChannel(currentChannelId);
        getPaymentInfo(currentChannelId);
        fetchVideos();
      }

      render() {
        return <ViewModeObserver onChange={this.handleEditModeChange} />;
      }
    },
  ),
);
