import _ from 'lodash';
import { createSelector } from 'reselect';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { canShowChannelCategories as isCategoriesSettingEnabled } from './app-settings';
import { isYouTubeFeed } from '../utils/channel-helpers';

export const getChannelCategories = createSelector(
  getChannelForWidget,
  (channel) => _.get(channel, 'statsInfo.categories', []),
);

export const getChannelCategoryOptions = createSelector(
  getChannelCategories,
  (categories) => _.map(categories, ({ value }) => ({ value, label: value })),
);

export const canShowChannelCategories = createSelector(
  isCategoriesSettingEnabled,
  getChannelForWidget,
  (isEnabled, channel) => isEnabled && !isYouTubeFeed(channel),
);
