import {
  openFullScreenPurchaseOverlay as openPurchaseOverlay,
  openFullScreenMemberOnlyOverlay as openMemberOnlyOverlay,
  openFullScreenSubscribeOverlay as openSubscribeOverlay,
  openFullScreenRentOverlay as openRentOverlay,
} from './open-overlay-base';
import { createProxy } from '../worker/lib';
import { isPricingPlanEnabled } from '@wix/wix-vod-shared/common';
import { FedopsInteractionsNames } from '../constants/fedops-interaction-names';

export const openFullScreenPurchaseOverlay = createProxy(
  'openFullScreenPurchaseOverlay',
  (fedopsLogger, ...args) =>
    (dispatch) => {
      fedopsLogger.interactionStarted(
        FedopsInteractionsNames.CASHIER_MODAL_LOAD,
      );
      dispatch(openPurchaseOverlay(...args));
    },
);

export const openFullScreenMemberOnlyOverlay = createProxy(
  'openFullScreenMemberOnlyOverlay',
  (fedopsLogger, ...args) =>
    (dispatch) => {
      fedopsLogger.interactionStarted(
        FedopsInteractionsNames.CASHIER_MODAL_LOAD,
      );
      dispatch(openMemberOnlyOverlay(...args));
    },
);

export const openFullScreenSubscribeOverlay = createProxy(
  'openFullScreenSubscribeOverlay',
  (fedopsLogger, channelId, callback) => (dispatch, getState) => {
    const {
      channelInfo: { info },
    } = getState();

    const isPlan = isPricingPlanEnabled(info);

    fedopsLogger.interactionStarted(FedopsInteractionsNames.CASHIER_MODAL_LOAD);
    dispatch(openSubscribeOverlay(channelId, callback, isPlan));
  },
);

export const openFullScreenRentOverlay = createProxy(
  'openFullScreenRentOverlay',
  (fedopsLogger, ...args) =>
    (dispatch) => {
      fedopsLogger.interactionStarted(
        FedopsInteractionsNames.CASHIER_MODAL_LOAD,
      );
      dispatch(openRentOverlay(...args));
    },
);
