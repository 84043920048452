import { useMemo } from 'react';

export function useIsWixStudio() {
  const search = location.search;

  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('origin') === 'studio';
  }, [search]);
}
