import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const isActive = (spec) => {
  let index;
  if (spec.rtl) {
    index = spec.slideCount - 1 - spec.index;
  } else {
    index = spec.index;
  }

  let centerOffset;
  let slickActive;
  if (spec.centerMode) {
    centerOffset = Math.floor(spec.slidesToShow / 2);
    if (
      index > spec.currentSlide - centerOffset - 1 &&
      index <= spec.currentSlide + centerOffset
    ) {
      slickActive = true;
    }
  } else {
    slickActive =
      spec.currentSlide <= index &&
      index < spec.currentSlide + spec.slidesToShow;
  }
  return slickActive;
};

const getSlideStyle = (spec) => {
  const style = {
    width: spec.slideWidth,
    flexShrink: 0,
  };

  return style;
};

const getKey = (child, fallbackKey) =>
  // key could be a zero
  child.key === null || _.isUndefined(child.key) ? fallbackKey : child.key;

const renderSlides = (spec) => {
  const slides = [];
  const preCloneSlides = [];
  const postCloneSlides = [];

  React.Children.forEach(spec.children, (child, index) => {
    const childStyle = getSlideStyle(_.assign({}, spec, { index }));
    const isActiveSlide = isActive({ index, ...spec });

    slides.push(
      React.cloneElement(child, {
        key: `original${getKey(child, index)}`,
        'data-index': index,
        ...(isActiveSlide && { 'data-active': 'true' }),
        className: child.props.className,
        style: _.assign({}, child.props.style || {}, childStyle),
      }),
    );
  });

  return preCloneSlides.concat(slides, postCloneSlides);
};

export default class Track extends React.Component {
  static propTypes = {
    trackStyle: PropTypes.object,
    getRef: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    getRef: _.noop,
  };

  render() {
    const slides = renderSlides(this.props);
    const { trackStyle, getRef, className } = this.props;

    return (
      <div
        ref={getRef}
        className={className}
        style={{
          display: 'flex',
          ...trackStyle,
        }}
      >
        {slides}
      </div>
    );
  }
}
