import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  getRentPriceInfo,
  getSalePriceInfo,
  getSubscriptionPriceInfo,
  getPricingPlanInfo,
  getChannelForWidget,
} from '@wix/wix-vod-shared/common';

import { getVideoById } from './videos';

export function isPayed(entity) {
  return Boolean(_.get(entity, 'dgsInfo'));
}

export const getChannelDealInfo = createSelector(
  getChannelForWidget,
  _.property('dealInfo'),
);

export const getSubscribeInfo = createSelector(
  getChannelDealInfo,
  (dealInfo) =>
    getSubscriptionPriceInfo(dealInfo) || getPricingPlanInfo(dealInfo),
);

export const getVideoDealInfo = createSelector(
  getVideoById,
  _.property('dealInfo'),
);

export const getRentInfo = createSelector(getVideoDealInfo, getRentPriceInfo);

export const getSaleInfo = createSelector(getVideoDealInfo, getSalePriceInfo);
