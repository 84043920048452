import React from 'react';
import _ from 'lodash';
import {
  getScreenSizeByUserAgent,
  isLandscape,
} from '../../utils/device/screen';
import { withWidgetProps } from '../../containers/widget-props';
import { withEnvironment } from '@wix/yoshi-flow-editor';

async function isMobileWidgetVisibleInViewPort(container) {
  let rect;

  if (!container) {
    return false;
  }

  if (global.Wix) {
    rect = await new Promise((resolve) =>
      global.Wix.getBoundingRectAndOffsets((data) => resolve(data.rect)),
    );
  } else {
    rect = container.getBoundingClientRect();
  }

  const { top, bottom } = rect;
  const { width, height } = getScreenSizeByUserAgent();
  const screenHeight = isLandscape() ? width : height;

  return (
    (top <= 0 && bottom >= screenHeight) || (top >= 0 && bottom <= screenHeight)
  );
}

export const visibleInViewPort = (WrappedComponent) => {
  const VisibleInViewPort = withEnvironment(
    withWidgetProps((widgetProps) => ({
      registerToScroll: widgetProps.host.registerToScroll,
    }))(
      class VisibleInViewPort extends React.Component {
        state = {
          isVisible: this.props.environment.isSSR,
        };

        componentWillUnmount() {
          if (global.Wix) {
            global.Wix.removeEventListener(
              global.Wix.Events.SCROLL,
              this.check,
            );
          }

          this.check.cancel();
        }

        check = _.debounce(() => {
          isMobileWidgetVisibleInViewPort(this.containerRef).then(
            this.updateVisibility,
          );
        }, 400);

        updateVisibility = (newIsVisible) => {
          if (newIsVisible !== this.state.isVisible) {
            this.setState({
              isVisible: newIsVisible,
            });
          }
        };

        saveRef = (ref) => {
          this.containerRef = ref;

          if (this.props.environment.isSSR) {
            return;
          }

          this.check();
          this.props.registerToScroll(this.check);
        };

        render() {
          return (
            <div role="presentation" ref={this.saveRef}>
              <WrappedComponent
                {...this.props}
                isVisibleInViewport={this.state.isVisible}
              />
            </div>
          );
        }
      },
    ),
  );

  VisibleInViewPort.WixWrappedComponent = WrappedComponent;

  return VisibleInViewPort;
};
