import React from 'react';
import classnames from 'classnames';

import { MILLISECONDS, buildUrl, openPopup } from '@wix/wix-vod-shared/common';
import {
  LiveIcon,
  ScheduledBigIcon,
  ScheduledSmallIcon,
} from '@wix/wix-vod-shared/icons';
import { InlineIcon, Countdown } from '@wix/wix-vod-shared/components';

import styles from './live-label.scss';

const defaultDateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

const toShortISOWithoutDelimiters = (datetime: number) =>
  new Date(datetime).toISOString().replace(/-|:|\.\d+/g, '');

interface Props {
  videoTitle: string;
  startTime: number;

  isStreaming?: boolean;
  isScheduled?: boolean;
  isPendingLiveStream?: boolean;
  isFocusable?: boolean;
  isRTL?: boolean;

  dateFormat: Intl.DateTimeFormatOptions;
  locale?: string;

  thumbnailWidth: number;
  className?: string;

  liveText?: string;
  pendingLiveText?: string;
  addReminderText?: string;

  scheduledSoonAriaLabel?: string;
  scheduledAriaLabel?: string;
  startsInAriaLabel?: string;
  reminderAriaLabel?: string;
}

export { Props as LiveLabelProps };

export class ThumbnailLiveLabel extends React.Component<Props> {
  static defaultProps = {
    dateFormat: defaultDateFormat,
  };

  openGoogleCalendarEvent = (event: React.MouseEvent) => {
    const { startTime, liveText, videoTitle } = this.props;
    const startTimeShortISO = toShortISOWithoutDelimiters(startTime);
    const url = buildUrl('https://www.google.com/calendar/event', {
      action: 'TEMPLATE',
      dates: `${startTimeShortISO}/${startTimeShortISO}`,
      text: videoTitle,
      location: '',
      details: liveText,
    });
    openPopup(url);
    event.stopPropagation();
  };

  renderLiveLabel() {
    const { liveText, pendingLiveText, isPendingLiveStream, className, isRTL } =
      this.props;

    const label = isPendingLiveStream ? pendingLiveText : liveText;

    const liveStreamLabelClassNames = classnames(styles.live, className, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const liveIconClassNames = classnames(styles['live-icon'], {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const liveTextClassNames = classnames(styles['live-text'], {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return (
      <div
        data-hook="live-stream-label"
        className={liveStreamLabelClassNames}
        role="note"
        aria-label={label}
      >
        <InlineIcon icon={<LiveIcon />} className={liveIconClassNames} />
        <span className={liveTextClassNames}>{label}</span>
      </div>
    );
  }

  renderScheduledLabel() {
    const {
      className,
      liveText,
      addReminderText,
      reminderAriaLabel,
      thumbnailWidth,
      isFocusable,
      isRTL,
    } = this.props;

    const ScheduledIcon =
      thumbnailWidth < 500 ? ScheduledSmallIcon : ScheduledBigIcon;

    const scheduledLabelClassNames = classnames(styles.scheduled, className, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const scheduledIconClassNames = classnames(styles['scheduled-icon'], {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const scheduledTextClassNames = classnames(styles['scheduled-text'], {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const reminderClassNames = classnames(styles.reminder, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return (
      <div
        data-hook="scheduled-label"
        className={scheduledLabelClassNames}
        aria-label={this.getScheduledAriaLabel()}
        role="region"
        tabIndex={isFocusable ? 0 : -1}
      >
        <InlineIcon
          icon={<ScheduledIcon />}
          className={scheduledIconClassNames}
        />
        <span className={scheduledTextClassNames}>
          {`${liveText}: `}
          {this.renderScheduledTime()}
        </span>
        <span
          onClick={this.openGoogleCalendarEvent}
          tabIndex={isFocusable ? 0 : -1}
          role="link"
          className={reminderClassNames}
          aria-label={reminderAriaLabel}
        >
          {addReminderText}
        </span>
      </div>
    );
  }

  getScheduledAriaLabel() {
    const { startTime, scheduledSoonAriaLabel, scheduledAriaLabel } =
      this.props;
    return startTime - Date.now() < MILLISECONDS.ONE_DAY
      ? scheduledSoonAriaLabel
      : scheduledAriaLabel;
  }

  renderScheduledTime() {
    const { startTime, startsInAriaLabel, locale, dateFormat, isFocusable } =
      this.props;

    if (startTime - Date.now() > MILLISECONDS.ONE_DAY) {
      return new Date(startTime).toLocaleString(locale, dateFormat);
    }

    return (
      <Countdown
        until={startTime}
        titlePrefix={startsInAriaLabel}
        isFocusable={isFocusable}
      />
    );
  }

  render() {
    const { isStreaming, isScheduled } = this.props;

    if (isStreaming) {
      return this.renderLiveLabel();
    }

    if (isScheduled) {
      return this.renderScheduledLabel();
    }

    return null;
  }
}
