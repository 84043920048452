import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import ShareIcon from './share-icon.svg';
import styles from './styles.scss';

export default class ShareButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.func,
    iconClassName: PropTypes.string,
    onClick: PropTypes.func,
    tabIndex: PropTypes.number,
    ariaLabel: PropTypes.string,
    children: PropTypes.node,
    canShowChannelShare: PropTypes.bool,
  };

  static defaultProps = {
    tabIndex: 0,
  };

  render() {
    const {
      className,
      iconClassName,
      onClick,
      tabIndex,
      ariaLabel,
      children,
      icon,
    } = this.props;

    const Icon = icon || ShareIcon;

    return (
      <button
        className={classnames(styles['share-button'], className)}
        tabIndex={tabIndex}
        data-hook="share-overlay-button"
        onClick={onClick}
        aria-label={ariaLabel}
      >
        <Icon className={classnames(styles['share-icon'], iconClassName)} />
        {children}
      </button>
    );
  }
}
