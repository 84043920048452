import {
  getAvailableVideoType,
  getFullAccessActionName,
  canPlayFullOrPreview,
} from '@wix/wix-vod-shared/widget';

import getTranslationData from './translation-data';
import getCallbackFromProps from './callback';

export default function getPlayButtonConfig(props) {
  const { channelData, videoItem, currentSiteUser } = props;

  const availableVideoType = getAvailableVideoType(
    channelData,
    videoItem,
    currentSiteUser,
  );
  const fullAccessAction = getFullAccessActionName(
    channelData,
    videoItem,
    currentSiteUser,
  );

  const callback = getCallbackFromProps(
    props,
    canPlayFullOrPreview(channelData, videoItem, currentSiteUser),
    fullAccessAction,
  );
  const translationData = getTranslationData(
    availableVideoType,
    fullAccessAction,
  );

  return {
    callback,
    translationData,
  };
}
