import { createSelector } from 'reselect';
import { getCurrentSiteUser } from '../../../../selectors/current-site-user';
import {
  getMainVideoId,
  canPlayFullOrPreview,
} from '@wix/wix-vod-shared/widget';
import {
  getChannelForWidget,
  isVideoFromComputer,
  isVideoOriginallyLive,
} from '@wix/wix-vod-shared/common';

const getVideos = (state) => state.entities.videos;

/**
 * @param state
 * @param listType
 * @returns main video item
 */
const getMainVideo = createSelector(
  [getVideos, getMainVideoId],
  (videos, mainVideoId) => mainVideoId && videos[mainVideoId],
);

/**
 * @param state
 * @param listType
 * @returns {Boolean}
 */
export const canPlayVideoInFrame = createSelector(
  [getChannelForWidget, getMainVideo, getCurrentSiteUser],
  (channel, mainVideo, currentSiteUser) =>
    Boolean(
      mainVideo &&
        (isVideoFromComputer(mainVideo) || isVideoOriginallyLive(mainVideo)) &&
        canPlayFullOrPreview(channel, mainVideo, currentSiteUser),
    ),
);
