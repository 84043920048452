import React from 'react';
import PropTypes from 'prop-types';

import { memoizedPartial } from '@wix/wix-vod-shared/common';
import { callOnEnterPressExactOnElement } from '../../../../../utils/call-on-keyboard-press';

import ThumbnailOverlayStrip from './_thumbnail-overlay/thumbnail-overlay';
import { ResponsiveSimpleVideoThumbnail } from '../../../../../components/thumbnail/simple';

import styles from './video-list-item.scss';

export default class VideoListItem extends React.Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    channelData: PropTypes.object.isRequired,
    videoItem: PropTypes.object.isRequired,
    currentSiteUser: PropTypes.object,

    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,

    isFocusable: PropTypes.bool.isRequired,

    onPlayRequest: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  render() {
    const {
      channelData,
      videoItem,
      width,
      height,
      appSettings,
      isFocusable,
      onClick,
      onPlayRequest,
      currentSiteUser,
    } = this.props;

    const onClickWithVideo = memoizedPartial(onClick, videoItem);
    return (
      <section
        className={styles.container}
        onClick={onClickWithVideo}
        onKeyDown={memoizedPartial(
          callOnEnterPressExactOnElement,
          onClickWithVideo,
        )}
        data-hook="video-list-thumb-wrapper"
        aria-hidden={!isFocusable}
        tabIndex={isFocusable ? 0 : -1}
      >
        <div className={styles.thumbnailRatioContainer}>
          <ResponsiveSimpleVideoThumbnail
            video={videoItem}
            width={width}
            height={height}
          />
          <ThumbnailOverlayStrip
            channelData={channelData}
            videoItem={videoItem}
            width={width}
            showChannelCover={false}
            appSettings={appSettings}
            onPlaySelectedVideo={memoizedPartial(onPlayRequest, videoItem)}
            isContentFocusable={isFocusable}
            currentSiteUser={currentSiteUser}
          />
        </div>
      </section>
    );
  }
}
