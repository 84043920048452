import _ from 'lodash';
import classnames from 'classnames';
import React, { ReactNode } from 'react';
import { getSlideWidth, getTrackLeft } from './sizes';
import Track from './track';
import styles from './mobile-carousel.scss';

/*
 Mobile carousel is inspired by https://github.com/akiran/react-slick/tree/master/src
*/
interface TrackSpec {
  slideCount: number;
  slidesToShow: number;
  slideWidth: number;
  speed: number;
  cssEase: string;

  left?: number;
  right?: number;
}

function getTrackCSS(spec: TrackSpec): React.CSSProperties {
  const { slideCount, slidesToShow, slideWidth } = spec;
  const trackWidth = (slideCount + 2 * slidesToShow) * slideWidth;

  const value = _.isNumber(spec.left) ? spec.left : spec.right;

  return {
    opacity: 1,
    width: trackWidth,
    WebkitTransform: `translateX(${value}px)`,
    transform: `translateX(${value}px)`,
    transition: '',
    WebkitTransition: '',
    msTransform: `translateX(${value}px)`,
  };
}

function getTrackAnimateCSS(spec: TrackSpec) {
  const style = getTrackCSS(spec);
  // useCSS is true by default so it can be undefined
  style.WebkitTransition = `-webkit-transform '${spec.speed}ms ${spec.cssEase}`;
  style.transition = `transform ${spec.speed}ms ${spec.cssEase}`;
  return style;
}

function calcSwipeDirection(touchObject: TouchObject) {
  const xDist = touchObject.startX - touchObject.curX;
  const yDist = touchObject.startY - touchObject.curY;
  const r = Math.atan2(yDist, xDist);

  let swipeAngle = Math.round((r * 180) / Math.PI);
  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle);
  }
  if (
    (swipeAngle <= 45 && swipeAngle >= 0) ||
    (swipeAngle <= 360 && swipeAngle >= 315)
  ) {
    return 'left';
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return 'right';
  }

  return 'vertical';
}

interface TouchObject {
  startX: number;
  startY: number;
  curX: number;
  curY: number;
  swipeLength?: number;
}
const defaultTouchObject: TouchObject = {
  startX: 0,
  startY: 0,
  curX: 0,
  curY: 0,
};

interface StateProps {
  listWidth: number;
  trackWidth: number;
  slideWidth: number;
  trackStyle?: React.CSSProperties;
}

type OwnProps = {
  width: number;
  totalCount: number;
  slidesToShow: number;
  className: string;
  touchThreshold: number;
  slidesToScroll: number;
  initialSlide: number;
  slidesPreviewWidth: number;
  cssEase: string;
  edgeFriction: number;
  speed: number;
  waitForAnimate: boolean;
  trackClassName: string;
  isRTL: boolean;
  children: React.ReactElement;
  getHeight: (elem: ReactNode, width: number) => number;
  afterChange: (newIndex: number) => void;
  beforeChange: (oldIndex: number, newIndex: number) => void;
  onUserStartedTracking: VoidFunction;
};

type Props = StateProps & OwnProps;
interface Position {
  posX: number;
  posY: number;
}
type PositionHandler = (pos: Position) => void;

export default class MobileCarousel extends React.Component<
  OwnProps,
  StateProps
> {
  static defaultProps = {
    className: '',
    cssEase: 'ease',
    easing: 'linear',
    edgeFriction: 0.35,
    initialSlide: 0,
    slidesToScroll: 1,
    speed: 500,
    getHeight: _.constant(200),
    slidesToShow: 3,
    touchThreshold: 5,
    useCSS: true,
    waitForAnimate: true,
    afterChange: _.noop,
    beforeChange: _.noop,
    onUserStartedTracking: _.noop,
    slidesPreviewWidth: 0,
  };

  private _currentSlide: number;
  private _touchObject: TouchObject;
  private _dragging?: boolean;
  private _animating?: boolean;
  private track: HTMLElement | null = null;
  private wrapper: HTMLElement | null = null;
  private list: HTMLElement | null = null; // delete?

  constructor(props: Props) {
    super(props);

    this._currentSlide = props.initialSlide;
    this._touchObject = { ...defaultTouchObject };

    this.state = this.getMeasurements();
  }

  componentDidMount() {
    this.update();
    window.addEventListener('resize', this.resizeHandler);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (
      nextProps.initialSlide !== this.props.initialSlide ||
      nextProps.width !== this.props.width
    ) {
      this._currentSlide = nextProps.initialSlide;
      this.update({ nextWidth: nextProps.width });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  getMeasurements(nextWidth?: number) {
    const { slidesToShow, slidesPreviewWidth } = this.props;
    const width = nextWidth ?? this.props.width;
    return {
      listWidth: width,
      trackWidth: width,
      slideWidth: getSlideWidth(width, slidesToShow, {
        slidesPreviewWidth,
      }),
    };
  }

  resizeHandler = () => this.update();

  update = (arg?: { nextWidth: number }) => {
    if (!this.wrapper) {
      return;
    }

    const { slidesPreviewWidth, isRTL } = this.props;
    const measurements = this.getMeasurements(arg?.nextWidth);

    this.setState(measurements, () => {
      const targetLeft = this.getTrackLeft(
        this._currentSlide,
        measurements.slideWidth,
        slidesPreviewWidth,
      );

      const direction: 'right' | 'left' = isRTL ? 'right' : 'left';

      // getCSS function needs previously set state
      const trackStyle = getTrackCSS(
        _.assign(
          {
            direction,
            [direction]: targetLeft,
            slideCount: this.getSlidesCount(),
          },
          this.props,
          this.state,
        ),
      );

      this.setTrackStyle(trackStyle);
    });
  };

  getTrackLeft(
    slideIndex: number,
    slideWidth: number,
    slidesPreviewWidth: number,
  ) {
    const { isRTL } = this.props;

    const trackLeft = getTrackLeft(
      this.getSlidesCount(),
      this.props.slidesToShow,
      slideIndex,
      slideWidth,
      slidesPreviewWidth,
    );

    return isRTL ? -trackLeft : trackLeft;
  }

  setTrackStyle(style: React.CSSProperties) {
    if (this.track) {
      _.assign(this.track.style, style);
    }
  }
  touchPositionHandler =
    (handler: PositionHandler) => (event: React.TouchEvent) =>
      handler({
        posX: event.touches[0].pageX,
        posY: event.touches[0].pageY,
      });

  mousePositionHandler =
    (handler: PositionHandler) => (event: React.MouseEvent) =>
      handler({ posX: event.clientX, posY: event.clientY });

  swipeStart: PositionHandler = ({ posX, posY }) => {
    this._dragging = true;
    this._animating = false;

    this._touchObject = {
      startX: posX,
      startY: posY,
      curX: posX,
      curY: posY,
    };
  };

  getSlidesCount = () => React.Children.count(this.props.children);

  swipeMove: PositionHandler = ({ posX, posY }) => {
    if (!this._dragging) {
      return;
    }
    if (this._animating) {
      return;
    }

    const touchObject = _.clone(this._touchObject);
    const { slideWidth } = this.state;
    const {
      slidesToShow,
      slidesPreviewWidth,
      onUserStartedTracking,
      edgeFriction,
      isRTL,
    } = this.props;

    const currentSlide = this._currentSlide;

    const curLeft = this.getTrackLeft(
      currentSlide,
      slideWidth,
      slidesPreviewWidth,
    );
    touchObject.curX = posX;
    touchObject.curY = posY;
    touchObject.swipeLength = Math.round(
      Math.sqrt(Math.pow(touchObject.curX - touchObject.startX, 2)),
    );

    const positionOffset = touchObject.curX > touchObject.startX ? 1 : -1;

    const slidesCount = this.getSlidesCount();
    const swipeDirection = calcSwipeDirection.call(this, touchObject);
    let touchSwipeLength = touchObject.swipeLength;

    if (
      (currentSlide === 0 && swipeDirection === 'right') ||
      (currentSlide >= slidesCount - slidesToShow && swipeDirection === 'left')
    ) {
      touchSwipeLength = touchObject.swipeLength * edgeFriction;
    }

    const swipeLeft = curLeft + touchSwipeLength * positionOffset;
    this._touchObject = touchObject;

    const direction: 'right' | 'left' = isRTL ? 'right' : 'left';

    const trackStyle = getTrackCSS(
      _.assign(
        {
          [direction]: swipeLeft,
          slideCount: this.getSlidesCount(),
        },
        this.props,
        this.state,
      ),
    );

    this.setTrackStyle(trackStyle);

    if (touchObject.swipeLength > 4) {
      onUserStartedTracking();
    }
  };

  calcSlidesToScroll(swipeLength: number) {
    const { slideWidth, listWidth } = this.state;
    const { touchThreshold } = this.props;
    const minSwipe = listWidth / touchThreshold;
    let slidesCount = Math.floor(swipeLength / slideWidth);
    const lastSlideLength = swipeLength % slideWidth;
    if (lastSlideLength > minSwipe) {
      slidesCount += 1;
    }
    return slidesCount;
  }

  swipeEnd = (event: React.SyntheticEvent) => {
    if (!this._dragging) {
      return;
    }

    const { touchThreshold, isRTL, slidesPreviewWidth } = this.props;

    const touchObject = this._touchObject;
    const minSwipe = this.state.listWidth / touchThreshold;
    const swipeDirection = calcSwipeDirection.call(this, touchObject);
    const currentSlide = this._currentSlide;

    // reset the state of touch related state variables.
    this._dragging = false;
    this._touchObject = { ...defaultTouchObject };
    // Fix for #13
    if (!touchObject.swipeLength) {
      return;
    }

    const needSwipeDirection = isRTL ? 'right' : 'left';
    const oppositeSwipeDirection = isRTL ? 'left' : 'right';

    if (touchObject.swipeLength > minSwipe) {
      const slidesToScroll = this.calcSlidesToScroll(touchObject.swipeLength);
      event.preventDefault();
      if (swipeDirection === needSwipeDirection) {
        this.slideHandler(currentSlide + slidesToScroll);
      } else if (swipeDirection === oppositeSwipeDirection) {
        this.slideHandler(currentSlide - slidesToScroll);
      } else {
        this.slideHandler(currentSlide);
      }
    } else {
      // Adjust the track back to it's original position.
      const currentLeft = this.getTrackLeft(
        currentSlide,
        this.state.slideWidth,
        slidesPreviewWidth,
      );

      this.setTrackStyle(
        getTrackAnimateCSS(
          _.assign(
            {
              [needSwipeDirection]: currentLeft,
              slideCount: this.getSlidesCount(),
            },
            this.props,
            this.state,
          ),
        ),
      );
    }
  };

  slideHandler(index: number) {
    // Functionality of animateSlide and postSlide is merged into this function
    const {
      waitForAnimate,
      slidesToShow,
      slidesPreviewWidth,
      beforeChange,
      isRTL,
    } = this.props;

    if (waitForAnimate && this._animating) {
      return;
    }

    let currentSlide: number;
    const slideCount = this.getSlidesCount();
    const targetSlide = index;

    if (targetSlide < 0) {
      currentSlide = 0;
    } else if (targetSlide > slideCount - slidesToShow) {
      currentSlide = slideCount - slidesToShow;
    } else {
      currentSlide = targetSlide;
    }

    const targetLeft = this.getTrackLeft(
      currentSlide,
      this.state.slideWidth,
      slidesPreviewWidth,
    );

    if (beforeChange) {
      beforeChange(this._currentSlide, currentSlide);
    }

    // Slide Transition happens here.
    // animated transition happens to target Slide and
    // non - animated transition happens to current Slide

    const direction = isRTL ? 'right' : 'left';

    const nextStateChanges = {
      trackStyle: getTrackCSS({
        [direction]: targetLeft,
        slideCount: this.getSlidesCount(),
        ...this.props,
        ...this.state,
      }),
      swipeLeft: null,
    };

    const callback = () => {
      this._animating = false;
      this.setTrackStyle(nextStateChanges.trackStyle);
      this.props.afterChange(currentSlide);
      this.track?.removeEventListener('transitionend', callback);
    };

    this._animating = true;
    const trackStyle = getTrackAnimateCSS(
      _.assign(
        {
          [direction]: targetLeft,
          slideCount: this.getSlidesCount(),
        },
        this.props,
        this.state,
      ),
    );
    this.setTrackStyle(trackStyle);

    this._currentSlide = currentSlide;
    this.track?.addEventListener('transitionend', callback);
  }

  changeSlide(
    changeArg:
      | { action: 'previous' | 'next' }
      | { action: 'index'; slideIndex: number },
  ) {
    const currentSlide = this._currentSlide;
    let targetSlide = currentSlide;
    let slideOffset;
    const slideCount = this.getSlidesCount();
    const slidesToScroll = this.props.slidesToScroll;
    const unevenOffset = slideCount % slidesToScroll !== 0;
    const indexOffset = unevenOffset
      ? 0
      : (slideCount - currentSlide) % slidesToScroll;

    if (changeArg.action === 'previous') {
      slideOffset =
        indexOffset === 0
          ? slidesToScroll
          : this.props.slidesToShow - indexOffset;
      targetSlide = currentSlide - slideOffset;
    } else if (changeArg.action === 'next') {
      slideOffset = indexOffset === 0 ? slidesToScroll : indexOffset;
      targetSlide = currentSlide + slideOffset;
    } else if (changeArg.action === 'index') {
      targetSlide = changeArg.slideIndex;
      if (targetSlide === currentSlide) {
        return;
      }
    }

    this.slideHandler(targetSlide);
  }

  handlePrevButtonClick = () => {
    this.changeSlide({ action: 'previous' });
  };

  handleNextButtonClick = () => {
    this.changeSlide({ action: 'next' });
  };

  renderChildren() {
    const { slideWidth } = this.state;
    const { getHeight, children } = this.props;

    if (!slideWidth) {
      return null;
    }

    return React.Children.map<React.ReactElement, React.ReactElement>(
      children,
      (elem) =>
        React.cloneElement(elem, {
          width: slideWidth,
          height: getHeight(elem, slideWidth),
        }),
    );
  }

  saveTrackRef = (node: HTMLElement | null) => {
    this.track = node;
  };

  saveWrapperRef = (node: HTMLElement | null) => {
    this.wrapper = node;
    this.update();
  };

  saveListRef = (node: HTMLElement | null) => {
    this.list = node;
  };

  render() {
    const trackProps = {
      cssEase: this.props.cssEase,
      speed: this.props.speed,
      currentSlide: this._currentSlide,
      slideWidth: this.state.slideWidth,
      slidesToShow: this.props.slidesToShow,
      slideCount: this.getSlidesCount(),
      trackStyle: this.state.trackStyle,
    };

    const { className, trackClassName } = this.props;

    return (
      <div
        ref={this.saveWrapperRef}
        className={classnames(styles.container, className)}
      >
        <div
          ref={this.saveListRef}
          onMouseDown={this.mousePositionHandler(this.swipeStart)}
          onMouseMove={this.mousePositionHandler(this.swipeMove)}
          onMouseUp={this.swipeEnd}
          onMouseLeave={this.swipeEnd}
          onTouchStart={this.touchPositionHandler(this.swipeStart)}
          onTouchMove={this.touchPositionHandler(this.swipeMove)}
          onTouchEnd={this.swipeEnd}
          onTouchCancel={this.swipeEnd}
        >
          <Track
            getRef={this.saveTrackRef}
            className={trackClassName}
            {...trackProps}
          >
            {this.renderChildren()}
          </Track>
        </div>
      </div>
    );
  }
}
