import React from 'react';
import classnames from 'classnames';
import { PlayIcon } from '@wix/wix-vod-shared/icons';
import { InlineIcon, CurrencySign } from '@wix/wix-vod-shared/components';
import { Currencies } from '@wix/wix-vod-constants/common';

import styles from './buttons.scss';

interface Props {
  buyButtonText: React.ReactChild;
  playButtonText: string;
  buyAriaLabel?: string;
  className?: string;
  currency: Currencies;

  showPlayButton?: boolean;
  showBuyButton?: boolean;
  showButtonsText?: boolean;
  isFocusable?: boolean;
  isRTL?: boolean;

  onPlayClick: () => void;
  onBuyClick: () => void;
}
export { Props as ButtonsProps };

export class Buttons extends React.Component<Props> {
  static propTypes = {};

  getButtonClassNames() {
    const { isRTL } = this.props;

    const buttonClassNames = classnames(styles.button, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const iconClassNames = classnames(styles.icon, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    const textClassNames = classnames(styles.text, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return {
      buttonClassNames,
      iconClassNames,
      textClassNames,
    };
  }

  renderPlayButton() {
    const { showPlayButton, playButtonText, onPlayClick, isFocusable } =
      this.props;

    if (!showPlayButton) {
      return null;
    }

    const { buttonClassNames, iconClassNames, textClassNames } =
      this.getButtonClassNames();

    return (
      <button
        className={buttonClassNames}
        onClick={onPlayClick}
        tabIndex={isFocusable ? 0 : -1}
        aria-hidden={!isFocusable}
        aria-label={playButtonText}
        data-hook="overlay-play-button"
      >
        <InlineIcon icon={<PlayIcon />} className={iconClassNames} />

        <div className={textClassNames}>{playButtonText}</div>

        {this.renderTooltip(playButtonText)}
      </button>
    );
  }

  renderBuyButton() {
    const {
      showBuyButton,
      buyButtonText,
      onBuyClick,
      isFocusable,
      buyAriaLabel,
      currency,
    } = this.props;

    if (!showBuyButton) {
      return null;
    }

    const { buttonClassNames, iconClassNames, textClassNames } =
      this.getButtonClassNames();

    return (
      <button
        className={buttonClassNames}
        onClick={onBuyClick}
        tabIndex={isFocusable ? 0 : -1}
        aria-hidden={!isFocusable}
        aria-label={buyAriaLabel}
        data-hook="paid-access-button"
      >
        <div className={classnames(styles.buyIcon, iconClassNames)}>
          <CurrencySign currency={currency} />
        </div>
        <div className={textClassNames}>{buyButtonText}</div>
        {this.renderTooltip(buyButtonText)}
      </button>
    );
  }

  renderTooltip(text: React.ReactChild) {
    const { isRTL } = this.props;

    const classNames = classnames(styles.tooltip, {
      [styles.rtl]: isRTL,
      [styles.ltr]: !isRTL,
    });

    return <div className={classNames}>{text}</div>;
  }

  render() {
    const { showButtonsText, className, isRTL } = this.props;

    const buttonsClassName = classnames(
      styles.buttons,
      {
        [styles['without-text']]: !showButtonsText,
        [styles.rtl]: isRTL,
        [styles.ltr]: !isRTL,
      },
      className,
    );

    return (
      <div className={buttonsClassName} data-hook="thumbnail-buttons">
        {this.renderPlayButton()}
        {this.renderBuyButton()}
      </div>
    );
  }
}
